import { Paths } from 'constants/paths';
import { tabGenerator } from 'domains/report/ui';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clsxm } from 'utils/clsxm';

import { Tab, Tabs } from '@mui/material';

type Props = {
  currentTab: string;
  classes?: {
    root?: string;
  };
};

function ReportTabs({ currentTab, classes }: Props) {
  const history = useHistory();

  const { role: roles, permissions } = useSelector(({ auth }: any) => auth.user);

  const filteredTabs = tabGenerator(roles, permissions);
  const currentTabValue = filteredTabs.find((t: { tab: string }) => t.tab === currentTab)?.value;

  const handleChangeTab = useCallback(
    (__: React.ChangeEvent<{}>, newValue: number) => {
      const newTab = filteredTabs.find((t: { value: number }) => t.value === newValue)?.tab;
      switch (newTab) {
        case 'lists':
          history.push(`${Paths.history}/lists`);
          break;

        case 'report':
          history.push(`${Paths.history}/report`);
          break;

        case 'performances':
          history.push(`${Paths.history}/performances`);
          break;

        default:
          break;
      }
    },
    [filteredTabs, history],
  );
  return (
    <Tabs
      value={currentTabValue}
      indicatorColor="secondary"
      textColor="primary"
      scrollButtons="auto"
      classes={{ root: clsxm('w-full h-48 relative', classes?.root) }}
      onChange={handleChangeTab}
    >
      {filteredTabs.map(tab => (
        <Tab
          key={tab.value}
          label={tab.label}
          className="h-48 normal-case font-400"
        />
      ))}
    </Tabs>
  );
}

export default ReportTabs;
