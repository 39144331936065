import { TResponsePagination, TRole } from 'models';

import { useGetList } from './request';

export const roleKeys = {
  all: ['roles'] as const,
  lists: () => [...roleKeys.all, 'list'] as const,
  list: (filters: any) => [...roleKeys.lists(), { filters }] as const,
  details: () => [...roleKeys.all, 'detail'] as const,
  detail: (id: number) => [...roleKeys.details(), id] as const,
};

const roleURL = '/api/admin/org_roles';

export function useGetRoles(filters: any, options?: any) {
  return useGetList<TResponsePagination<TRole>>(
    [...roleKeys.list(filters)],
    `${roleURL}`,
    filters,
    {
      refetchOnMount: true,
      ...options,
    },
    true,
  );
}
