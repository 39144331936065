import { SingleSelect } from 'app/components/cores/select';
import { CONDITIONAL_DEFAULT_OPTIONS, ITEM_TYPES_MAP } from 'constants/index';
import {
  getMultipleChoiceConditionalOptions,
  getRatingConditionalOptions,
  getYesNoConditionalOptions,
} from 'domains/item.domain';
import _ from 'lodash';
import { TItem, TItemType } from 'models';
import { useMemo } from 'react';
import { Props as ReactSelectProps } from 'react-select';

type Props = {
  selectedConditionalItem: TItem;
} & ReactSelectProps;

export function LogicSelect({ selectedConditionalItem, value, ...reactSelectProps }: Props) {
  const taskItemType = selectedConditionalItem?.type;
  const hasCustomOption = ([ITEM_TYPES_MAP.AREA, ITEM_TYPES_MAP.EMPLOYEE] as TItemType[]).includes(taskItemType);

  const defaultOptions = useMemo(() => {
    const nextDefaultOptions = _.cloneDeep(CONDITIONAL_DEFAULT_OPTIONS) as any;
    nextDefaultOptions.shift();
    return nextDefaultOptions;
  }, []);

  const options = useMemo(() => {
    if (taskItemType === ITEM_TYPES_MAP.YES_NO) {
      return getYesNoConditionalOptions(defaultOptions);
    }

    if (taskItemType === ITEM_TYPES_MAP.MULTIPLE_CHOICE) {
      return getMultipleChoiceConditionalOptions(defaultOptions, selectedConditionalItem?.multipleChoiceOptions);
    }

    if (taskItemType === ITEM_TYPES_MAP.RATING) {
      return getRatingConditionalOptions(defaultOptions, selectedConditionalItem?.maxNumber);
    }

    return defaultOptions;
  }, [selectedConditionalItem, taskItemType, defaultOptions]);

  const getValue = (selectedOption: any, _options: any) => {
    const nextOption = _.cloneDeep(selectedOption);

    if (!nextOption?.logic) return undefined;

    if (hasCustomOption && nextOption?.logic === 'eq' && nextOption?.value && nextOption?.name) {
      nextOption.conditionalLogic = 'eq';
      nextOption.conditionalLabel = 'Custom...';
      return nextOption;
    }

    if (!nextOption?.conditionalLogic) {
      const nextConditionLogic = nextOption?.value ? `${nextOption?.logic}::${nextOption?.value}` : nextOption?.logic;
      nextOption.conditionalLogic = nextConditionLogic;
      nextOption.conditionalLabel =
        _.find(_options, (item: any) => item.conditionalLogic === nextConditionLogic)?.conditionalLabel || '';
    }

    return nextOption;
  };

  const styles = {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: 140,
    }),
    control: (baseStyles: any) => ({
      ...baseStyles,
      border: 0,
      backgroundColor: '#F5D02F',
      borderRadius: 16,
      height: 24,
      minHeight: 24,
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    valueContainer: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
      paddingLeft: 8,
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: 11,
      },
    }),
    input: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      width: 140,
      borderRadius: 16,
      overflow: 'hidden',
    }),
    menuList: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
    }),
    option: (baseStyles: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: 11,
        fontWeight: '600',
        color: '#1F242F',
      },
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: 11,
        lineHeight: 2,
      },
    }),
  };

  const generateTheme = (baseTheme: any) => ({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary50: '#eee',
      primary25: '#eee',
      primary: '#F5D02F',
    },
  });

  return (
    <SingleSelect
      options={options}
      menuPlacement="top"
      getOptionLabel={(option: any) => option.conditionalLabel}
      getOptionValue={(option: any) => option.conditionalLogic}
      styles={styles}
      theme={generateTheme}
      isSearchable={false}
      value={getValue(value, options)}
      {...reactSelectProps}
    />
  );
}
