import EmptyConfig from 'app/pages/empty/config';
import ErrorConfig from 'app/pages/error/config';
import FacilityTasksConfig from 'app/pages/facility-tasks/config';
import FullScreenConfig from 'app/pages/fullscreen/config';
import HistoryConfig from 'app/pages/histories/config';
import ListMonitorConfig from 'app/pages/list-monitor/config';
import ListConfig from 'app/pages/lists/config';
import LocationConfig from 'app/pages/locations/config';
import LoginConfig from 'app/pages/login/loginConfig';
import LogoutConfig from 'app/pages/logout/LogoutConfig';
import CalendarPagesConfig from 'app/pages/my-tasks/calendar/config';
import MyTasksConfig from 'app/pages/my-tasks/my-tasks/config';
import PerformanceConfig from 'app/pages/performance/config';
import TagConfig from 'app/pages/tags/config';
import TemplateConfig from 'app/pages/templates/config';
import { isDevelopment } from 'utils/env';

import FuseUtils from '@fuse/utils';

const routeConfigs = [
  LocationConfig,
  HistoryConfig,
  ListMonitorConfig,
  ListConfig,
  TemplateConfig,
  TagConfig,
  CalendarPagesConfig,
  FacilityTasksConfig,
  MyTasksConfig,
  PerformanceConfig,
  FullScreenConfig,
  ErrorConfig,
  LogoutConfig,
  EmptyConfig,
];

if (isDevelopment) {
  routeConfigs.unshift(LoginConfig);
}

const routes = [...FuseUtils.generateRoutesFromConfigs(routeConfigs)];

export default routes;
