import { DateInput, TimeInput } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { DateInput as EditableDateInput, TimeInput as EditableTimeInput } from 'app/components/cores/tasks/components';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import {
  generateTaskAnswerPayload,
  getDateTimeType,
  getDefaultDateTimeValue,
  toAnswerDate,
  toAnswerDateTime,
  toAnswerTime,
} from 'domains/employee/task.domain';
import { TDateTimeItem, TQuestionLayoutView } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Stack } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type Props = {
  data: TDateTimeItem;
  name: string;
  listId: number;
  view: TQuestionLayoutView;
  readOnly?: boolean;
};

const DateTime = ({ data, name, listId, view, readOnly }: Props) => {
  const { resetField, setValue } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const { mutateAsync: answerTask, isLoading } = useEditHistoryTask({ taskId: listId, responseId: value?.id });
  const { showError, showSuccess } = useShowMessage();
  const [payloadData, setPayloadData] = useState<any>({});

  const originalAnswer = value?.answer;
  const dateTimeType = data?.dateTimeType;

  const { isDateTimeType, isDateType, isTimeType } = useMemo(() => getDateTimeType(dateTimeType), [dateTimeType]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const isShowDate = isDateTimeType || isDateType;
  const isShowTime = isDateTimeType || isTimeType;

  // effect to set selected date and time
  useEffect(() => {
    if (!originalAnswer || selectedDate || selectedTime) {
      return;
    }

    const { date, time } = getDefaultDateTimeValue(dateTimeType, originalAnswer);
    setSelectedDate(date);
    setSelectedTime(time);
  }, [dateTimeType, originalAnswer, selectedDate, selectedTime]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);

    // type datetime
    if (isDateTimeType && selectedTime) {
      const formattedDate = toAnswerDate(date);
      const formattedTime = toAnswerTime(selectedTime);
      const formattedDateTime = `${formattedDate} ${formattedTime}`;
      updateStateAndAnswer(formattedDateTime);
    }

    // type date
    if (!isDateTimeType) {
      const formattedDateTime = toAnswerDateTime(date, true);
      updateStateAndAnswer(formattedDateTime);
    }
  };

  const handleTimeChange = (time: Date | null) => {
    setSelectedTime(time);

    // type datetime
    if (isDateTimeType && selectedDate) {
      const formattedDate = toAnswerDate(selectedDate);
      const formattedTime = toAnswerTime(time);
      const formattedDateTime = `${formattedDate} ${formattedTime}`;
      updateStateAndAnswer(formattedDateTime);
    }

    // type time
    if (!isDateTimeType) {
      const formattedDateTime = toAnswerDateTime(time);
      updateStateAndAnswer(formattedDateTime);
    }
  };

  const updateStateAndAnswer = (nextAnswer: string) => {
    onChange({ ...value, answer: nextAnswer });
    const answerPayload = generateTaskAnswerPayload(nextAnswer);
    setPayloadData(answerPayload);
  };

  const answer = async (payload: any) => {
    try {
      const res = await answerTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  const handleSave = () => {
    answer(payloadData);
  };

  const handleCancel = () => {
    const { date, time } = getDefaultDateTimeValue(dateTimeType, value?.answer);
    setSelectedDate(date);
    setSelectedTime(time);
  };

  useEffect(() => {
    const { date, time } = getDefaultDateTimeValue(dateTimeType, value?.answer);
    setSelectedDate(date);
    setSelectedTime(time);
  }, [value?.answer, dateTimeType]);

  return (
    <QuestionLayoutMapper
      question={data}
      preview
      task={value}
      name={name}
      onSaveEdit={handleSave}
      view={view}
      onCancelEdit={handleCancel}
      isLoading={isLoading}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {readOnly ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            {isDateType && (
              <DateInput
                disabled={readOnly}
                value={selectedDate}
              />
            )}
            {isTimeType && (
              <TimeInput
                disabled={readOnly}
                value={selectedTime}
              />
            )}
            {isDateTimeType && (
              <>
                <DateInput
                  disabled={readOnly}
                  value={selectedDate}
                />
                <TimeInput
                  disabled={readOnly}
                  value={selectedTime}
                />
              </>
            )}
          </Stack>
        ) : (
          <div className="flex items-center space-x-8">
            {isShowDate && (
              <EditableDateInput
                disabled={readOnly}
                value={selectedDate}
                onChange={handleDateChange}
              />
            )}
            {isShowTime && (
              <EditableTimeInput
                disabled={readOnly}
                value={selectedTime}
                onChange={handleTimeChange}
              />
            )}
          </div>
        )}
      </LocalizationProvider>
    </QuestionLayoutMapper>
  );
};
export default DateTime;
