import DataSelect from 'app/components/cores/data-select';
import { ControlledTextInput } from 'app/components/cores/inputs/text';
import { Control, useController } from 'react-hook-form';

import { Typography } from '@mui/material';

type Props = {
  control?: Control;
  name: string;
};

const RatingOptions = ({ name, control }: Props) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: `${name}`,
  });

  const RATING_OPTIONS = [
    { label: '5', value: '5' },
    { label: '8', value: '8' },
    { label: '10', value: '10' },
  ];

  const handleChange = (maxNumber: string) => {
    onChange({ ...value, maxNumber: Number(maxNumber), minNumber: 1 });
  };

  return (
    <div className="flex flex-col pb-16 space-y-8">
      <Typography className="text-11 font-600">Rating Options</Typography>
      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-600">Rating Type</Typography>
        <div className="w-80">
          <DataSelect
            options={RATING_OPTIONS}
            onChange={handleChange}
            value={(value.maxNumber || 5).toString()}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-13 text-secondaryLight font-400">Minimum Label</Typography>
        <div className="w-80">
          <ControlledTextInput
            name={`${name}.minLabel`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-11',
              },
            }}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-13 text-secondaryLight font-400">Maximum Label</Typography>
        <div className="w-80">
          <ControlledTextInput
            name={`${name}.maxLabel`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-11',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RatingOptions;
