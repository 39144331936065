import { UploadMedia } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import AlbumMedia from 'app/components/cores/icons/album-media';
import { UploadMedia as EditUploadMedia } from 'app/components/cores/tasks/components';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import { useUploadMedia } from 'app/hooks/api/media';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TItem, TQuestionLayoutView } from 'models';
import { useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { Stack, Typography } from '@mui/material';

type Props = {
  data: TItem;
  name: string;
  listId: number;
  readOnly?: boolean;
  view: TQuestionLayoutView;
};

const Media = ({ data, name, listId, readOnly, view }: Props) => {
  const { resetField, setValue, setError, clearErrors } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const uploadMediaRef = useRef<any>();
  const { mutate: uploadMedia, isLoading: isUploadingMedia } = useUploadMedia({ isPublic: false });
  const { mutateAsync: answerTask, isLoading } = useEditHistoryTask({ taskId: listId, responseId: value?.id });
  const [uploadProgress, setUploadProgress] = useState(0);
  const isRequired = value?.item?.required;
  const answer = value?.answer?.files?.[0];
  const validAnswer = value?.answer?.id && answer;
  const { showSuccess, showError } = useShowMessage();

  const updateFormAndAnswer = (nextAnswer: any) => {
    onChange({ ...value, answer: nextAnswer });
  };

  useEffect(() => {
    if (isRequired && validAnswer) clearErrors(name);
  }, [validAnswer, isRequired, clearErrors, name]);

  const checkAnswerIsValid = () => {
    if (isRequired && !validAnswer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const handleAnswerTask = async () => {
    try {
      if (!checkAnswerIsValid()) return;

      const additionalPayload = { comment: value?.comment ?? '' };
      const answerPayload = generateTaskAnswerPayload({ mediaAttributes: value?.answer }, additionalPayload);
      const res = await answerTask(answerPayload as any);
      resetField(name, { defaultValue: { ...value, ...res } });
      setValue(`${name}-isEditing`, false);
      showSuccess('The answer has been edited successfully');
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  const handleBrowserFile = (files: File[]) => {
    if (!files.length) return;
    const file = files[0];
    upload(file, updateFormAndAnswer);
  };

  const upload = (file: File, onSuccess?: (nextAnswer: any) => void) => {
    setUploadProgress(0);
    uploadMedia(
      {
        file,
        onUploadProgress(progressEvent: any) {
          const { loaded, total } = progressEvent;
          const progress = loaded / total;
          setUploadProgress(progress);
        },
      },
      {
        onSuccess: (_response: any) => {
          const nextAnswer = { id: _response.id, files: _response.objectFiles };
          onSuccess?.(nextAnswer);
        },
      },
    );
  };

  const handleReset = () => {
    const nextAnswer = { id: null, files: [] };
    updateFormAndAnswer(nextAnswer);
    uploadMediaRef.current?.clear?.();
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview={true}
      task={value}
      canReset={!readOnly && validAnswer}
      name={name}
      onSaveEdit={handleAnswerTask}
      view={view}
      onReset={handleReset}
      isLoading={isLoading}
    >
      {!readOnly ? (
        <EditUploadMedia
          uploadMediaRef={uploadMediaRef}
          value={value?.answer}
          onChange={handleBrowserFile}
          uploadProgress={uploadProgress}
          isLoading={isUploadingMedia}
        />
      ) : (
        <>
          <UploadMedia value={answer} />
          <Stack
            direction="row"
            justifyContent="space-around"
            className="px-8 mt-16"
          >
            <button
              type="button"
              className="flex items-center space-x-8"
            >
              <AlbumMedia className="text-20" />
              <Typography className="text-secondaryMain font-600">Album</Typography>
            </button>
            <button
              type="button"
              className="flex items-center space-x-8"
            >
              <CameraAltOutlinedIcon className="text-20" />
              <Typography className="text-secondaryMain font-600">Take Picture</Typography>
            </button>
          </Stack>
        </>
      )}
    </QuestionLayoutMapper>
  );
};

export default Media;
