import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll';
import { QuestionLayout } from 'app/components/cores/tasks/components';
import { useGetAreas } from 'app/hooks/api/areas';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import useTaskFilter from 'app/hooks/use-task-filter';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import _ from 'lodash';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useController, useFormContext } from 'react-hook-form';

import { useTheme } from '@mui/material';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  responseId: number;
  listStatus?: TTaskStatus;
  response?: TEmployeeTaskResponse;
};

function AreaResponse({ name, readOnly, listId, responseId, listStatus, response }: Props) {
  const theme = useTheme();
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const { location } = useTaskFilter();
  const { data: areaData, isLoading: isGettingAreas } = useGetAreas({
    'q[location_salt_id_eq]': location?.saltId,
  });
  const areaOptions = areaData?.records || [];

  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value?.comment ?? '',
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const answer = (payload: any) => {
    answerTask(payload)
      .then(res => {
        onChange({ ...value, ...res });
        showSuccess('Your answer has been submitted successfully');
      })
      .catch(() => {
        showError('Could not submit your answer');
      });
  };

  const handleChange = (values: any) => {
    const nextAnswer = _.isEmpty(values) ? [] : values;
    onChange({ ...value, answer: nextAnswer });

    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(nextAnswer, additionalPayload);
    answer(answerPayload);
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly ?? false}
      task={value}
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
    >
      <SelectInfiniteScroll
        isDisabled={readOnly}
        value={value?.answer || []}
        onChange={handleChange}
        isMulti={true}
        isLoading={isGettingAreas}
        isClearable={true}
        control={control}
        defaultOptions={true}
        className="w-full"
        name={name}
        placeholder="Select area"
        styles={{
          indicatorsContainer: (_props: any) => ({
            ..._props,
            marginTop: 7,
          }),
          multiValue: (_props: any) => ({
            ..._props,
            backgroundColor:
              value?.status === 'overdue' || (listStatus === 'overdue' && isLoading)
                ? '#FF9F5D'
                : theme.palette.primary.main,
            padding: 4,
          }),
        }}
        options={areaOptions}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
      />
    </QuestionLayout>
  );
}

export default AreaResponse;
