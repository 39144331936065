import ReactSelect, { components as ReactSelectComponents, Props as ReactSelectProps } from 'react-select';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const IndicatorSeparator = () => null;

const DropdownIndicator = (props: any) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <ReactSelectComponents.DropdownIndicator {...props}>
      {menuIsOpen ? (
        <ArrowDropUpIcon className="text-20 text-secondaryMain" />
      ) : (
        <ArrowDropDownIcon className="text-20 text-secondaryMain" />
      )}
    </ReactSelectComponents.DropdownIndicator>
  );
};

const SingleValue = (props: any) => {
  return (
    <ReactSelectComponents.SingleValue {...props}>
      <Typography className="text-13 font-600">{props.children}</Typography>
    </ReactSelectComponents.SingleValue>
  );
};

const Option = (props: any) => {
  return (
    <ReactSelectComponents.Option {...props}>
      <Typography className="text-13 font-700">{props.children}</Typography>
    </ReactSelectComponents.Option>
  );
};

const Placeholder = (props: any) => {
  return (
    <ReactSelectComponents.Placeholder {...props}>
      <Typography className="inline-block text-13 font-400 text-secondaryLight">{props.children}</Typography>
    </ReactSelectComponents.Placeholder>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <ReactSelectComponents.ClearIndicator {...props}>
      <CloseIcon className="text-16" />
    </ReactSelectComponents.ClearIndicator>
  );
};

type Props = ReactSelectProps & {
  selectRef?: any;
};

function SingleSelect({ selectRef, ...props }: Props) {
  const theme = useTheme();

  const components = {
    IndicatorSeparator,
    DropdownIndicator,
    SingleValue,
    Option,
    Placeholder,
    ClearIndicator,
    ...props.components,
  };

  const styles = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      border: 0,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      height: 32,
      minHeight: 32,
      boxShadow: 'none',
      cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
      pointerEvents: 'auto',
    }),
    dropdownIndicator: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
      paddingRight: 4,
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      borderRadius: 8,
      paddingTop: 4,
      paddingBottom: 4,
      zIndex: 11,
    }),
    option: (baseStyles: any) => ({
      ...baseStyles,
      borderBottom: '1px solid #eee',
    }),
    ...props.styles,
  };

  const generateTheme = (baseTheme: any) => ({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary50: theme.palette.primary.light,
      primary25: '#eee',
      primary: theme.palette.primary.main,
    },
  });

  return (
    <ReactSelect
      {...props}
      ref={selectRef}
      components={components}
      styles={styles}
      theme={props.theme || generateTheme}
    />
  );
}

export default SingleSelect;
