import ErrorMessage from 'app/components/cores/form/error-message';
import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll';
import { useAdminGetEmployees } from 'app/hooks/api/employees';
import useSelect from 'app/hooks/use-select';
import { generateFieldName } from 'domains/task.domain';
import { useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  control: {
    minHeight: '48px !important',
  },
  text: {
    fontSize: '1.3rem !important',
    color: theme.palette.secondary.dark,
  },
}));

type Props = {
  name?: string;
  control?: Control;
  menuPlacement?: string;
};

function SendNotificationToUser({ name, control, menuPlacement }: Props) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);

  const { data, isLoading } = useAdminGetEmployees({
    page: pageIndex,
    per_page: pageSize,
    'q[full_name_cont]': searchText,
  });

  const {
    field: { onChange: onRoleIdsChange },
  } = useController({
    name: generateFieldName('sendToUsers', name),
    control,
  });

  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name: generateFieldName('sendToUsersDetail', name),
    control,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const formattedOptions = useMemo(
    () => options?.map((option: any) => ({ name: option.name, id: option.id })),
    [options],
  );

  const handleChange = (newValues: any) => {
    onChange(newValues);
    onRoleIdsChange(newValues?.map((v: any) => v.id));
  };

  const presentValue = useMemo(() => {
    return !value?.length ? null : value;
  }, [value]);

  return (
    <div className="p-4">
      <SelectInfiniteScroll
        value={presentValue}
        onChange={handleChange}
        onBlur={onBlur}
        isMulti={true}
        isLoading={isLoading}
        isClearable={true}
        control={control}
        defaultOptions={true}
        className="w-full"
        name={generateFieldName('sendToUsersDetail', name)}
        placeholder="Assign Report To User"
        menuPlacement={menuPlacement || 'auto'}
        SelectProps={{
          classes: {
            control: classes.control,
            text: classes.text,
            placeholder: classes.text,
          },
        }}
        styles={{
          indicatorsContainer: (_props: any) => ({
            ..._props,
            marginTop: 7,
          }),
        }}
        options={formattedOptions || []}
        onInputChange={handleSearch}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
        onMenuScrollToBottom={handleLoadMore}
      />
      <ErrorMessage message={error?.message} />
    </div>
  );
}

export default SendNotificationToUser;
