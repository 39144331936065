import _ from 'lodash';
import moment from 'moment';

export function generateQueryObject(filters: { id: string; value: string }[]): { [key: string]: string } {
  return filters.reduce((queries: { [key: string]: string }, filter: { id: string; value: string }) => {
    let query = '';
    switch (filter.id) {
      case 'role':
      case 'status':
      case 'q[status_eq]':
        query = filter.id;
        break;

      case 'validated':
      case 'kind':
      case 'global_assignment_individual':
      case 'global_assignment_list_type':
      case 'locations_external_id':
      case 'role_id':
      case 'assignment_status':
      case 'location_id':
      case 'task_root_tags_id':
      case 'location_external_id':
      case 'submit_status':
      case 'assignments_org_roles_external_id':
      case 'tags_id':
      case 'org_role_id':
        query = `q[${filter.id}_eq]`;
        break;
      case 'org_role_id_in':
        query = `q[org_role_id_in]`;
        break;
      case 'location_opening_hours_id_null':
        query = `q[location_opening_hours_id_null]`;
        break;

      default:
        query = `q[${filter.id}_cont]`;
    }

    const value = filter.value === '_all' ? '' : filter.value;
    return { ...queries, [query]: value };
  }, {});
}

export function convertDateStringToString(dateString?: string, dateFormat?: string): string {
  if (!dateString || dateString === '') return '';

  const date = moment(new Date(dateString));
  if (!date.isValid()) return '';

  return date.format(dateFormat || 'DD/MM/YYYY');
}

/**
 *
 * Capitalize a first character
 * @param {string} str
 * @return {string}
 */
export function capitalizeFirstCharacter(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertToAbbreviations(text: string) {
  const words = text.split(' ');
  const firstLetter = _.first(words[0]);
  const lastLetter = _.first(words[1]);

  return _.upperCase(`${firstLetter}${lastLetter}`);
}
