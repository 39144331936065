import DataSelect from 'app/components/cores/data-select';
import DateRange from 'app/components/cores/date-range';
import ExportPDFIcon from 'app/components/cores/icons/export-pdf';
import { SingleSelect } from 'app/components/cores/select';
import { PERIOD_OPTIONS } from 'constants/performance';
import _ from 'lodash';
import { TUserLocation } from 'models';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import SyncIcon from '@mui/icons-material/Sync';
import { IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = {
  title: string;
  location: TUserLocation;
  onSelectLocation?: (nextLocation: TUserLocation) => void;
  period: any;
  setPeriod: any;
  tmpPeriod: any;
  setCustomPeriod: any;
  printRef: any;
  isFetchingPerformance: boolean;
};

function TaskHeader({
  tmpPeriod,
  setCustomPeriod,
  period,
  setPeriod,
  title,
  location,
  printRef,
  isFetchingPerformance,
  onSelectLocation,
}: Props) {
  const theme = useTheme();
  const locations = useSelector(({ auth }: any) => auth.user.locations);
  const locationOptions = useMemo(() => _.orderBy(locations, 'name'), [locations]);

  // effect to set first location by default
  useEffect(() => {
    if (_.isEmpty(locationOptions) || !!location) return;
    const [firstLocation] = locationOptions;
    onSelectLocation?.(firstLocation);
  }, [locationOptions, location, onSelectLocation]);

  const handleChange = (item: any) => {
    onSelectLocation?.(item);
  };

  const handlePrint = useReactToPrint({
    content: () => (printRef as any).current,
    documentTitle: `${location?.name} Performance Report`,
  });

  const handleExportPDF = async (event: React.MouseEvent) => {
    event.preventDefault();
    handlePrint();
  };

  return (
    <div className="flex items-center justify-between h-64 px-16 bg-white shadow-md md:px-24">
      <div className="flex flex-row items-center">
        <Typography
          variant="h6"
          className="uppercase text-18 text-secondaryMain"
        >
          {title}
        </Typography>
        <IconButton
          color="primary"
          className="h-40 ml-8"
          onClick={handleExportPDF}
          disabled={isFetchingPerformance}
        >
          {isFetchingPerformance ? <SyncIcon className="animate-spin" /> : <ExportPDFIcon />}
        </IconButton>
      </div>
      <div className="flex items-center justify-end gap-16">
        <div className="flex justify-end w-full">
          <DataSelect
            options={PERIOD_OPTIONS}
            value={period}
            onChange={setPeriod}
            styleFormControl="max-w-136 min-w-136 rounded-8 mr-4 h-40"
            sx={{ height: '40px' }}
          />
          {period === 'custom' && (
            <div className="flex items-center space-x-16">
              <DateRange
                value={tmpPeriod}
                onChange={setCustomPeriod}
                noAllTime={true}
              />
            </div>
          )}
        </div>
        <div className="md:min-w-232 min-w-192">
          <SingleSelect
            value={location}
            options={locationOptions}
            maxMenuHeight={1000}
            menuPlacement="auto"
            noOptionsMessage={() => 'No locations'}
            placeholder="Select a location"
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.externalId}
            onChange={handleChange}
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                border: 0,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 8,
                height: 40,
                minHeight: 40,
                boxShadow: 'none',
                cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
                pointerEvents: 'auto',
              }),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TaskHeader;
