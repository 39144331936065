import clsx from 'clsx';
import { Control, useFieldArray } from 'react-hook-form';

import { Button, Typography } from '@mui/material';

import MultipleChoiceItem from './multiple-choice-item';

type Props = {
  control?: Control;
  name: string;
};

const MultipleChoiceOptions = ({ name, control }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.multipleChoiceOptions`,
  });

  const handleAddOption = () => {
    append({ value: '', label: '' });
  };

  const handleRemoveOption = (index: number) => {
    remove(index);
  };

  return (
    <div className="space-y-8">
      <Typography className="text-11 font-600">Multiple Choice Options</Typography>
      {fields.map((item: any, index: number) => {
        return (
          <MultipleChoiceItem
            key={item.id}
            name={`${name}.multipleChoiceOptions.${index}`}
            control={control}
            index={index}
            onRemove={() => handleRemoveOption(index)}
          />
        );
      })}
      <Button
        className={clsx(
          ' normal-case p-0 bg-transparent shadow-0 text-11 font-400 text-primaryLight',
          'hover:text-primaryLight hover:shadow-0 hover:font-600 hover:bg-transparent',
        )}
        variant="contained"
        onClick={handleAddOption}
      >
        Add Answer Choice
      </Button>
    </div>
  );
};

export default MultipleChoiceOptions;
