import { SingleSelect } from 'app/components/cores/select';
import {
  CONDITIONAL_DATE_TIME_OPTIONS,
  CONDITIONAL_DEFAULT_OPTIONS,
  IN_THE_PAST_VALUE,
  WITHIN_VALUE,
} from 'constants/index';
import _ from 'lodash';
import { TDateTimeIconCondition, TItem } from 'models';
import { useMemo } from 'react';
import { CSSObjectWithLabel, Props as ReactSelectProps } from 'react-select';

type Props = {
  selectedConditionalItem: TItem;
  isCustomize?: boolean;
  conditionalComparison?: TDateTimeIconCondition[];
  dateTimeType?: string;
} & ReactSelectProps;

export function LogicSelects({
  selectedConditionalItem,
  isCustomize,
  value,
  conditionalComparison,
  dateTimeType,
  ...reactSelectProps
}: Props) {
  const options = useMemo(() => {
    if (!isCustomize) {
      return CONDITIONAL_DEFAULT_OPTIONS;
    }

    let result = _.map(CONDITIONAL_DATE_TIME_OPTIONS, (option: TDateTimeIconCondition) => {
      const isDisabled = !!_.find(conditionalComparison, { conditionalLogic: option.conditionalLogic });
      return { ...option, isDisabled };
    });

    if (dateTimeType === 'time') {
      // can only select within and in_the_past
      result = _.filter(result, (option: TDateTimeIconCondition) =>
        [IN_THE_PAST_VALUE, WITHIN_VALUE].includes(option?.conditionalLogic),
      ) as any;
    }

    return result;
  }, [conditionalComparison, isCustomize, dateTimeType]);

  const getValue = (selectionOption: any, _options: any) => {
    const nextOption = _.cloneDeep(selectionOption);

    // for default logic
    if (!isCustomize || (nextOption?.conditionalLogic && nextOption?.conditionalLabel)) {
      return nextOption;
    }

    if (!nextOption?.logic) {
      return undefined;
    }

    // TODO set value for customize logic here

    return _.find(_options, { conditionalLogic: nextOption.conditionalLogic }) || nextOption;
  };

  const styles = {
    container: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      width: 120,
    }),
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      border: 0,
      backgroundColor: '#F5D02F',
      borderRadius: 16,
      height: 24,
      minHeight: 24,
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    valueContainer: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: 0,
      paddingLeft: 8,
    }),
    singleValue: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      '& *': {
        fontSize: 11,
      },
    }),
    input: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: 0,
    }),
    menu: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      width: 120,
      borderRadius: 16,
      overflow: 'hidden',
    }),
    menuList: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: 0,
    }),
    option: (baseStyles: CSSObjectWithLabel, state: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: 11,
        fontWeight: '600',
        color: state.isDisabled ? '#AAA' : '#1F242F',
      },
    }),
    placeholder: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      '& *': {
        fontSize: 11,
        lineHeight: 2,
      },
    }),
  };

  const generateTheme = (baseTheme: any) => ({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary50: '#eee',
      primary25: '#eee',
      primary: '#F5D02F',
    },
  });

  return (
    <SingleSelect
      options={options}
      menuPlacement="top"
      getOptionLabel={(option: any) => option.conditionalLabel}
      getOptionValue={(option: any) => option.conditionalLogic}
      styles={styles}
      theme={generateTheme}
      isSearchable={false}
      value={getValue(value, options)}
      {...reactSelectProps}
    />
  );
}
