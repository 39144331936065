export type TTab = {
  label: string;
  value: number;
};

export const TASK_TABS = {
  INFO: { label: 'Info', value: 0, tab: 'info' },
  LOCATION: { label: 'Location Settings', value: 1, tab: 'location-setting' },
  GLOBAL_SETTING: { label: 'Global Settings', value: 2, tab: 'global-setting' },
  VARIATION: { label: 'Variations', value: 3, tab: 'variation' },
  QUESTION: { label: 'List Tasks', value: 4, tab: 'task' },
  PREVIEW: { label: 'Preview', value: 5, tab: 'preview' },
} as const;

export const TABS = Object.values(TASK_TABS);

export const LOCATION_TABS_CONFIG = {
  OVERVIEW: { label: 'Overview', value: 0, tab: 'overview' },
  LISTS: { label: 'Lists', value: 1, tab: 'lists' },
  PERFORMANCE: { label: 'Performance', value: 2, tab: 'performance' },
} as const;

export const LOCATION_MANAGER_TABS_CONFIG = {
  OVERVIEW: { label: 'Overview', value: 0, tab: 'overview' },
  PERFORMANCE: { label: 'Performance', value: 1, tab: 'performance' },
} as const;

export const LOCATION_TABS = Object.values(LOCATION_TABS_CONFIG);

export const LOCATION_MANAGER_TABS = Object.values(LOCATION_MANAGER_TABS_CONFIG);

export const REPORT_TABS_CONFIG = {
  LISTS: { label: 'Employee Submissions', value: 0, tab: 'lists' },
  REPORT: { label: 'Report Export', value: 1, tab: 'report' },
  PERFORMANCE: { label: 'Performance Score', value: 2, tab: 'performances' },
} as const;

export const REPORT_TABS = Object.values(REPORT_TABS_CONFIG);
