import Header from 'app/components/Layout/headers/blank-header-v2';
import ExportCSVIcon from 'app/components/cores/icons/export-csv';
import GeneratingModal from 'app/components/cores/modals/generating';
import PerformanceReportFilter from 'app/components/reports/filters/performance-filter';
import PerformanceReportTable from 'app/components/reports/performances';
import ReportTabs from 'app/components/reports/tabs';
import { useExportReport, useGetPerformanceReports } from 'app/hooks/api/admin/performance';
import useExport from 'app/hooks/use-export';
import { useFilter } from 'app/hooks/use-filter';
import useScreen from 'app/hooks/use-screen';
import { REPORT_TABS } from 'constants/tabs';
import { generateDefaultDateRange } from 'domains/report/performance.domain';
import { clsxm } from 'utils/clsxm';

import BluefitPage from '@fuse/core/BluefitPage';
import _ from '@lodash';
import SyncIcon from '@mui/icons-material/Sync';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const DEFAULT_PAGINATION = { page: 1, per_page: 9999 };
const { startDate, endDate } = generateDefaultDateRange();

function Performance() {
  const classes = useStyles();
  const { is1440 } = useScreen();

  const {
    isModalOpen: isExportingCSV,
    handleCloseModal: hideExportingCSVModal,
    doExport,
  } = useExport({
    useQueryHook: useExportReport,
  });

  const [reportFilters, setReportFilters] = useFilter<any>(
    {
      ...DEFAULT_PAGINATION,
      'q[kind_eq]': 'weekly',
      'q[from_date_eq]': startDate,
      'q[to_date_eq]': endDate,
    },
    true,
  );

  const { data: performanceReports, isFetching } = useGetPerformanceReports(reportFilters, {
    enabled: !!hasSelectedLocation(),
  });

  const canExport = !isFetching && performanceReports?.performances?.length;

  function hasSelectedLocation() {
    const defaultLocationFilterValue = reportFilters?.['q[location_id_in][]'];
    return Array.isArray(defaultLocationFilterValue)
      ? !!defaultLocationFilterValue?.length
      : !!defaultLocationFilterValue;
  }

  const handleFilterChange = (newFilters: any) => {
    setReportFilters((prevFilters: any) => {
      const nextFilters = _.cloneDeep(prevFilters);
      return { ...nextFilters, ...newFilters };
    });
  };

  const handleExport = async () => {
    doExport({ filters: reportFilters });
  };

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        toolbar: clsxm('-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24', !is1440 && 'h-96 -mt-96 mx-0'),
        header: clsxm(
          'min-h-header h-header sm:h-header sm:min-h-header',
          !is1440 && 'min-h-168 sm:min-h-168 sm:h-168',
        ),
        wrapper: 'min-h-0',
        contentWrapper: 'border-t-1',
      }}
      header={
        <Header
          leftHeader={
            <Typography
              variant="h6"
              className="flex uppercase text-18 text-secondaryMain"
            >
              Reports
            </Typography>
          }
        />
      }
      contentToolbar={
        <div className={clsxm('flex items-center justify-between w-full', !is1440 && 'flex-col h-full')}>
          <ReportTabs
            currentTab={REPORT_TABS[2].tab}
            classes={{
              root: clsxm(!is1440 && 'px-24 border-b-1'),
            }}
          />
          <div className={clsxm('flex items-center', !is1440 && 'w-full pl-40 pb-4')}>
            <PerformanceReportFilter
              defaultFilter={reportFilters}
              onChange={handleFilterChange}
            />
            <Tooltip title={isFetching ? '' : 'Export CSV'}>
              <span>
                <IconButton
                  disabled={!canExport}
                  color="primary"
                  className="h-40 ml-8"
                  onClick={handleExport}
                >
                  {isFetching ? <SyncIcon className="animate-spin" /> : <ExportCSVIcon />}
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>
      }
      content={
        <div className={clsxm('flex flex-col w-full h-full pb-64', classes.reportTable)}>
          <GeneratingModal
            isOpen={isExportingCSV}
            onClose={hideExportingCSVModal}
          />
          {hasSelectedLocation() ? (
            <PerformanceReportTable reports={performanceReports} />
          ) : (
            <div className="absolute top-0 left-0 right-0 text-center pt-128">
              <Typography className="text-center text-13 font-400">Please select a location</Typography>
            </div>
          )}
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
      isLoading={isFetching}
    />
  );
}

export default Performance;

const useStyles = makeStyles(() => ({
  reportTable: {
    '& thead th': {
      backgroundColor: 'white',
      position: 'sticky',
      borderTopWidth: '0 !important',
    },
    '& thead tr:nth-child(1) th': {
      top: 0,
    },
  },
}));
