/**
 * This component is a clone of ./FuseNavVerticalCollapse
 * with changes of UI presentation and styles
 */
import { useGetNotificationStatus } from 'app/hooks/api/notifications';
import { useFetchServices } from 'app/hooks/api/services';
import clsx from 'clsx';
import { getBadgeNumberStyle } from 'domains/navbar.domain';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseUtils from '@fuse/utils';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import FuseNavBadge from '../FuseNavBadge';
import FuseNavItem from '../FuseNavItem';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  item: props => ({
    height: 40,
    width: 'calc(100% - 0px)',
    borderRadius: '0 24px 24px 0',
    borderColor: 'white',
    paddingRight: 16,
    paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    color: theme.palette.text.primary,
    '&.active > .list-item-text > span': {
      fontWeight: 600,
    },
    '& .list-item-icon': {
      marginRight: 16,
    },
  }),
  login: props => ({
    height: 48,
    margin: '0px 8px 4px 0px',
    borderColor: 'white',
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    color: theme.palette.text.primary,
    '&.active > .login-item-text > span': {
      fontWeight: 600,
    },
    '& .login-item-icon': {
      marginRight: 16,
    },
  }),
  service: {
    width: 'calc(100% - 8px)',
    borderWidth: '1px 1px 1px 0',
    borderColor: theme.palette.primary.light,
    borderRadius: '0px 24px 24px 0px',
  },
}));

function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname);
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    const childUrl = parent.children[i].url;

    if (childUrl === url || (childUrl !== '/' && url.includes(childUrl))) {
      return true;
    }
  }

  return false;
}

function FuseNavVerticalLogin(props) {
  const user = useSelector(({ auth }) => auth.user);
  const {
    role: userRole,
    data: { displayName },
  } = user;
  const [open, setOpen] = useState(() => needsToBeOpened(props.location, props.item));
  const { item, nestedLevel } = props;
  const classes = useStyles({
    itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24,
  });

  const { data: dataServices, isLoading } = useFetchServices();
  const services = useMemo(() => dataServices?.services || [], [dataServices]);

  const { data: notification } = useGetNotificationStatus();
  const unreadNotificationCount = notification?.unreadCount || 0;

  const showSubMenu = !isLoading && item.children;

  const subMenuItems = useMemo(() => {
    return item?.children?.map(child => ({
      ...child,
      badge: child.id === 'sso_my_messages' ? unreadNotificationCount : null,
    }));
  }, [item, unreadNotificationCount]);

  const serviceName = useMemo(() => {
    if (!services) return 'My Tasks';
    const service = services?.find(s => s.kind === 'task');
    return service?.name || 'My Tasks';
  }, [services]);

  function handleClick() {
    setOpen(!open);
  }

  const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);

  if (!hasPermission) {
    return null;
  }

  return (
    <ul className={clsx(classes.root, open && 'open')}>
      {showSubMenu && (
        <Collapse
          in={open}
          className="collapse-children"
        >
          {subMenuItems?.map((child, index) => (
            <div key={index}>
              {child.id === 'profile' && <Divider className="bg-secondaryMain" />}
              <FuseNavItem
                key={child.id}
                type={`vertical-${child.type}`}
                item={child}
                nestedLevel={nestedLevel + 1}
                loginLevel={1}
              />
            </div>
          ))}
        </Collapse>
      )}

      {!open && !!serviceName && (
        <div className={clsx('my-4 py-10 pl-56', classes.service)}>
          <Typography className="text-white text-13 font-600">{serviceName}</Typography>
        </div>
      )}

      <ListItem
        className={clsx(classes.login, 'login-item')}
        onClick={handleClick}
        component={item.url ? NavLinkAdapter : 'li'}
        to={item.url}
        role="button"
      >
        <Icon className="flex-shrink-0 text-white list-item-icon text-20">person_outline</Icon>
        <ListItemText
          className="flex-1 mx-12 overflow-hidden list-item-text"
          primary="Logged in as"
          secondary={displayName}
          classes={{ primary: 'text-11 font-light text-white', secondary: 'text-13 text-white max-w-168 truncate' }}
        />
        {!!unreadNotificationCount && !open && (
          <FuseNavBadge
            className={clsx('mx-4 justify-center items-center p-0', getBadgeNumberStyle(unreadNotificationCount))}
            badge={{ title: unreadNotificationCount, bg: '#E0115F', fg: '#FFFFFF' }}
          />
        )}
        <IconButton
          disableRipple
          className="w-40 h-40 p-0 mr-4 -mx-12 text-white hover:bg-transparent"
          onClick={ev => ev.preventDefault()}
        >
          <Icon
            className="text-20 arrow-icon"
            color="inherit"
          >
            {open ? 'expand_less' : 'expand_more'}
          </Icon>
        </IconButton>
      </ListItem>
    </ul>
  );
}

FuseNavVerticalLogin.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};
FuseNavVerticalLogin.defaultProps = {};

const NavVerticalCollapse = withRouter(React.memo(FuseNavVerticalLogin));

export default NavVerticalCollapse;
