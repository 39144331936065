import { RatingButtons } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TQuestionLayoutView, TRatingItem } from 'models';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  data: TRatingItem;
  readOnly?: boolean;
  name: string;
  listId: number;
  view: TQuestionLayoutView;
};

const Rating = ({ data, readOnly, name, listId, view }: Props) => {
  const { minNumber, maxNumber, minLabel, maxLabel } = data;
  const { resetField, setValue, setError, clearErrors } = useFormContext();

  const {
    field: { value, onChange },
  } = useController({ name });
  const { mutateAsync: answerTask, isLoading } = useEditHistoryTask({ taskId: listId, responseId: value?.id });
  const { showError, showSuccess } = useShowMessage();
  const isRequired = value?.item?.required;
  const validAnswer = value?.answer;

  useEffect(() => {
    if (isRequired && validAnswer) clearErrors(name);
  }, [validAnswer, isRequired, clearErrors, name]);

  const answer = async (payload: any) => {
    try {
      const res = await answerTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  const handleAnswerChange = (nextAnswer: number | null) => {
    onChange({ ...value, answer: nextAnswer });
  };

  const checkAnswerIsValid = () => {
    if (isRequired && !validAnswer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const handleEdit = () => {
    if (!checkAnswerIsValid()) return;

    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview={true}
      task={value}
      name={name}
      onSaveEdit={handleEdit}
      key={value?.id}
      view={view}
      isLoading={isLoading}
    >
      <RatingButtons
        minNumber={minNumber}
        maxNumber={maxNumber}
        minLabel={minLabel}
        maxLabel={maxLabel}
        disabled={readOnly}
        value={Number(value?.answer)}
        status={value?.status}
        onChange={handleAnswerChange}
      />
    </QuestionLayoutMapper>
  );
};

export default Rating;
