import bluefitSSOService from 'app/services/bluefitSSOService';
import { showMessage } from 'app/store/fuse/messageSlice';
import { setDefaultSettings, setInitialSettings } from 'app/store/fuse/settingsSlice';
import { DEFAULT_MAIN_COLOR, Roles } from 'constants/index';
import { getManagerLocations } from 'utils/location';

import history from '@history';
import _ from '@lodash';
import { createSlice } from '@reduxjs/toolkit';

export const setUserData = user => async (dispatch, getState) => {
  let userRoles = ['employee'];
  if (user?.accessRoles?.length > 0) {
    const mappedRoles = user.accessRoles.map(role => Roles[role]?.toLowerCase());
    userRoles = [...mappedRoles, ...userRoles];
  }

  const brandPrimaryColor = user.brandPrimaryColor ? user.brandPrimaryColor : DEFAULT_MAIN_COLOR;

  const tmpUser = {
    taskFilter: getDefaultTaskFilter(),
    data: {
      accessRoles: user.accessRoles,
      serviceAccessRoles: user.serviceAccessRoles,
      email: user.email,
      displayName: user.fullName,
      settings: {
        theme: {
          main: 'default',
        },
      },
    },
    brandPrimaryColor,
    brandLogo: user.brandLogo,
    brandBrandInitial: user.brandBrandInitial,
    brandLogoSymbol: user.brandLogoSymbol,
    role: userRoles,
    redirectUrl: '/',
    locations: user.locations || [],
    managerLocationIds: user.managerLocationIds || [],
    managerLocations: getManagerLocations(user.locations, user.managerLocationIds),
    roles: user.roles || [],
    permissions: user.permissions,
    userId: user.id,
  };

  history.location.state = {
    redirectUrl: tmpUser.redirectUrl,
  };

  dispatch(setDefaultSettings(tmpUser.data.settings));
  dispatch(setUser(tmpUser));
};

export const updateUserSettings = settings => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
  const { user } = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  dispatch(updateUserData(user));

  return dispatch(setUserData(newUser));
};

export const logoutUser = () => async dispatch => {
  bluefitSSOService.logout();

  dispatch(setInitialSettings());

  dispatch(userLoggedOut());
  return null;
};

export const updateUserData = user => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  switch (user.from) {
    default: {
      bluefitSSOService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch(error => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
  }
};

const getDefaultTaskFilter = () => {
  try {
    const taskFilter = localStorage.getItem('taskFilter');
    if (!taskFilter) return {};

    const nextTaskFilter = JSON.parse(taskFilter);
    return nextTaskFilter || {};
  } catch (error) {
    return {};
  }
};

const initialState = {
  role: [], // guest
  data: {},
  taskFilter: getDefaultTaskFilter(),
  brandPrimaryColor: DEFAULT_MAIN_COLOR,
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
    setTaskFilterLocation: (state, action) => {
      const nextLocation = action.payload;
      if (!state.taskFilter) {
        state.taskFilter = {};
      }
      state.taskFilter.location = nextLocation;
      const nextTaskFilter = {
        ...state.taskFilter,
        location: nextLocation,
      };
      localStorage.setItem('taskFilter', JSON.stringify(nextTaskFilter));
    },
    setTaskFilterRoles: (state, action) => {
      const nextRoles = action.payload;
      if (!state.taskFilter) {
        state.taskFilter = {};
      }
      state.taskFilter.roles = nextRoles;
      const nextTaskFilter = {
        ...state.taskFilter,
        roles: nextRoles,
      };
      localStorage.setItem('taskFilter', JSON.stringify(nextTaskFilter));
    },
    setTaskSearchValue: (state, action) => {
      const nextSearchValue = action.payload;
      if (!state.taskFilter) {
        state.taskFilter = {};
      }
      state.taskFilter.searchValue = nextSearchValue;
      const nextTaskFilter = {
        ...state.taskFilter,
        searchValue: nextSearchValue,
      };
      localStorage.setItem('taskFilter', JSON.stringify(nextTaskFilter));
    },
    clearTaskFilter: state => {
      state.taskFilter = {};
      localStorage.removeItem('taskFilter');
    },
  },
  extraReducers: {},
});

export const {
  setUser,
  userLoggedOut,
  setTaskFilterLocation,
  setTaskFilterRoles,
  setTaskSearchValue,
  clearTaskFilter,
} = userSlice.actions;

export default userSlice.reducer;
