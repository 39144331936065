import { CheckMarkButton } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TItem, TQuestionLayoutView } from 'models';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  data: TItem;
  name: string;
  listId: number;
  readOnly?: boolean;
  view: TQuestionLayoutView;
};

const CheckMark = ({ data, name, listId, readOnly, view }: Props) => {
  const { resetField, setValue, setError, clearErrors } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const { showError, showSuccess } = useShowMessage();
  const isRequired = value?.item?.required;

  const { mutateAsync: answerTask, isLoading } = useEditHistoryTask({
    taskId: listId,
    responseId: value?.id,
  });

  const handleAnswerChange = (nextAnswer: boolean | null) => {
    const answerString = `${nextAnswer}`;
    onChange({ ...value, answer: answerString });
  };

  useEffect(() => {
    if (isRequired && (value?.answer || value?.answer === 'true')) clearErrors(name);
  }, [value?.answer, isRequired, clearErrors, name]);

  const checkAnswerIsValid = () => {
    if (isRequired && (!value?.answer || value?.answer === 'false')) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const answer = async (payload: any) => {
    try {
      const res = await answerTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  const handleEdit = () => {
    if (!checkAnswerIsValid()) return;

    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer === 'true' ? 'true' : null, additionalPayload);
    answer(answerPayload);
  };

  return (
    <QuestionLayoutMapper
      view={view}
      question={data}
      preview
      task={value}
      name={name}
      onSaveEdit={handleEdit}
      isLoading={isLoading}
    >
      <CheckMarkButton
        value={value?.answer === 'true'}
        status={value?.status}
        onChange={handleAnswerChange}
        disabled={readOnly}
      />
    </QuestionLayoutMapper>
  );
};
export default CheckMark;
