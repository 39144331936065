import { useCallback } from 'react';

import { useTheme } from '@mui/material/styles';

const useTask = () => {
  const theme: any = useTheme();

  const getListStatus = useCallback(
    (listStatus: string, isOnDemand?: boolean) => {
      const mapping = new Map();
      mapping.set('pending', {
        color: theme.palette.pending.main,
        name: 'Pending',
      });
      mapping.set('in_progress', {
        color: theme.palette.inProgress.main,
        name: 'In Progress',
      });
      mapping.set('overdue', {
        color: theme.palette.overdue.main,
        name: 'Overdue',
      });
      mapping.set('expired', {
        color: theme.palette.expired.main,
        name: 'Expired',
      });
      mapping.set('upcoming', {
        color: theme.palette.upcoming.main,
        name: 'Upcoming',
      });
      mapping.set('completed', {
        color: theme.palette.completed.main,
        name: 'Completed',
      });
      mapping.set('incomplete', {
        color: theme.palette.incomplete.main,
        name: 'Incomplete',
      });
      mapping.set('on_demand', {
        color: theme.palette.onDemand.light,
        name: 'On Demand',
      });

      if (isOnDemand) {
        return mapping.get('on_demand');
      }

      return (
        mapping.get(listStatus) || {
          color: theme.palette.secondary.main,
          name: 'Unknown',
        }
      );
    },
    [theme],
  );

  const getListStatusColor = useCallback(
    (listStatus: string, isOnDemand?: boolean) => getListStatus(listStatus, isOnDemand)?.color,
    [getListStatus],
  );

  const getListStatusName = useCallback((listStatus: string) => getListStatus(listStatus)?.name, [getListStatus]);

  const triggerSubmitTask = (element: any) => {
    const isSubmitTask = element?.id === 'submit-task';
    if (isSubmitTask) {
      setTimeout(() => {
        element?.click();
      }, 0);
    }
  };

  return {
    getListStatus,
    getListStatusColor,
    getListStatusName,
    triggerSubmitTask,
  };
};

export default useTask;
