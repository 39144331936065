import clsx from 'clsx';
import { TTaskStatusGroup } from 'models/employee/task.model';
import { useMemo } from 'react';
import { displayTime } from 'utils/date';

import GroupsIcon from '@mui/icons-material/Groups';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TaskProgress from './progress';

type Props = {
  item?: any; // TODO update type
  taskGroup?: TTaskStatusGroup;
  isActive?: boolean;
  onSelectTask?: () => void;
  isFacility?: boolean;
};

function TaskSidebarItem({ item, taskGroup, isActive, onSelectTask, isFacility = false }: Props) {
  const classes = useStyles();

  const displayTimeColor = useMemo(() => {
    return taskGroup === 'overdue' ? 'text-overdue' : 'text-secondaryLight';
  }, [taskGroup]);

  return (
    <div
      data-listid={item?.id}
      role="button"
      tabIndex={-1}
      className={clsx(
        'px-16 py-10 duration-300 cursor-pointer hover:bg-gray-100 border-t-1 border-gray-50 last:rounded-b-8 bg-white',
        { 'opacity-50': taskGroup === 'upcoming', [classes.active]: isActive },
      )}
      onClick={onSelectTask}
    >
      <div className="flex items-start justify-between">
        {/* task name + time */}
        <div className="flex-1 pr-8">
          <Typography className="flex-1 text-13 font-600">{item?.task?.name}</Typography>

          <div className="flex items-center justify-start">
            <Typography className={clsx('flex-initial text-10 font-500', displayTimeColor)}>
              {displayTime(item?.displayAt, item?.dueAt)}
            </Typography>

            {item?.task?.individual && isFacility && (
              <Typography className="flex-initial ml-4 text-10 font-500">{`- ${item?.user?.fullName}`}</Typography>
            )}
          </div>
        </div>

        <div className="flex items-center">
          {/* individual */}
          {!item?.task?.individual && (
            <div
              className={clsx(
                'flex items-center justify-center -mt-4  rounded-full border-3 w-36 h-36',
                isActive ? 'border-gray-300' : 'border-gray-200',
              )}
            >
              <GroupsIcon className="text-20 text-secondaryLight" />
            </div>
          )}

          {/* progress */}
          <TaskProgress
            value={item?.percentageCompleted ?? 0}
            className="ml-8"
            isActive={isActive}
          />
        </div>
      </div>

      {/* roles */}
      <div className="mt-4">
        {item?.roles?.map((role: any) => (
          <div
            key={role.externalId}
            className="inline-flex items-center justify-center px-8 py-4 mt-4 mr-4 bg-gray-200 rounded-16"
          >
            <Typography className="flex-1 text-12 font-500">{role.name}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TaskSidebarItem;

const useStyles: any = makeStyles((theme: any) => ({
  active: {
    backgroundColor: `${theme.palette.grey[200]} !important`,
  },
}));
