import { TTaskStatusGroup } from 'models/employee/task.model';

export const TASK_GROUP_MAPPING: { [key in TTaskStatusGroup]: string } = {
  overdue: 'Overdue',
  onDemand: 'On Demand',
  inProgress: 'In Progress',
  pending: 'Pending',
  upcoming: 'Upcoming',
};

export const FULL_TYPE_MAPPING = {
  overdue: 'Overdue',
  incomplete: 'Incomplete',
  onDemand: 'On Demand',
  inProgress: 'In Progress',
  pending: 'Pending',
  upcoming: 'Upcoming',
  expired: 'Expired',
  completed: 'Completed',
};

export const TASK_SIDEBAR_ORDER: { [key in TTaskStatusGroup]: number } = {
  onDemand: 0,
  overdue: 1,
  inProgress: 2,
  pending: 3,
  upcoming: 4,
};

export const TASK_SIDEBAR_ITEM_FORMAT = 'hh:mmA';

export const TASK_DATE_ITEM_FORMAT = 'DD-MM-YYYY';

export const TASK_REFETCH_TIME = 1000 * 60 * 5; // 5m
