import { TEmployeeTaskItem, TEmployeeTasks } from 'models/employee/task.model';

import { useGetList, useGetOne } from '../request';

const taskURL = '/api/manager/tasks';

export const facilityTaskKeys = {
  all: ['facilityTasks'] as const,
  lists: () => [...facilityTaskKeys.all, 'list'] as const,
  list: (filters: any) => [...facilityTaskKeys.lists(), { filters }] as const,
  details: () => [...facilityTaskKeys.all, 'detail'] as const,
  detail: (id: number) => [...facilityTaskKeys.details(), id] as const,
};

export function useGetFacilityTasks(filters: any, options?: any) {
  return useGetList<TEmployeeTasks>(
    [...facilityTaskKeys.list(filters)],
    `${taskURL}`,
    filters,
    {
      refetchOnMount: true,
      ...options,
    },
    true,
  );
}

export function useGetFacilityTask(taskId?: number, options?: any) {
  return useGetOne<TEmployeeTaskItem>(
    [...facilityTaskKeys.detail(Number(taskId))],
    `${taskURL}/${taskId}`,
    {},
    {
      enabled: !!taskId,
      cacheTime: 0,
      ...options,
    },
  );
}
