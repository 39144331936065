import { useSubmitTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { TASK_COLORS } from 'constants/task';
import { isCompletedRequiredTasks } from 'domains/employee/task.domain';
import _ from 'lodash';
import { TEmployeeTaskItem } from 'models/employee/task.model';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import ErrorIcon from '@mui/icons-material/Error';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

import TaskContentBody from './content/body';
import TaskContentHeader from './content/header';
import { DuplicateTaskContentBodySkeleton, TaskContentHeaderSkeleton } from './content/skeleton';

type Props = {
  list?: TEmployeeTaskItem;
  selectedList?: TEmployeeTaskItem | null;
  isLoading?: boolean;
  initialLoading?: boolean;
  readOnly?: boolean;
  onRefresh?: () => void;
  onSubmitSuccess?: (resetState?: boolean) => void;
  setSelectedList?: (newList: TEmployeeTaskItem | null) => void;
  isFacility?: boolean;
};

function TaskContent({
  list,
  selectedList,
  isLoading,
  initialLoading,
  readOnly,
  onRefresh,
  onSubmitSuccess,
  setSelectedList,
  isFacility = false,
}: Props) {
  const hasSelectedTask = !!selectedList?.id;
  const { reset, setError, getValues } = useFormContext();
  const { mutateAsync: submitTask, isLoading: isSubmitting } = useSubmitTask(Number(list?.id));
  const { showSuccess, showError } = useShowMessage();
  const isUpcoming = useMemo(() => list?.status === 'upcoming', [list?.status]);
  const isSweepList = !!list?.sweep;

  const message = {
    isUpcoming: {
      alert: 'This List will start soon. Until then, you cannot wok on this List.',
      error: 'Interacting is disabled until the list is started',
    },
    isFacility: 'You cannot interact with the List from Facility Tasks page',
  };

  useEffect(() => {
    if (isLoading || !list?.id) return;
    reset(list);
  }, [list, reset, isLoading]);

  const handleSubmitError = (responsesErrors?: any) => {
    if (!responsesErrors?.length) {
      showSuccess('Your list has been submitted successfully');

      if (isSweepList) {
        const values = getValues('responses');
        const isCompleted = isCompletedRequiredTasks(values);
        onSubmitSuccess?.(isCompleted);
      } else {
        onSubmitSuccess?.(true);
      }

      return;
    }

    showError('Could not submit your list');
    let firstErrorResponseIndex = -1;
    const responses = list?.responses;

    // loop error responses to show message
    _.forEach(responsesErrors, err => {
      const errorResponseIndex = _.findIndex(responses, { id: err.responseId });

      if (firstErrorResponseIndex < 0) {
        firstErrorResponseIndex = errorResponseIndex;
      }

      if (errorResponseIndex >= 0) {
        setError(`responses.${errorResponseIndex}.answer`, {
          type: 'required',
          message: 'This task item is required.',
        });
      }
    });

    // scroll to first error
    if (firstErrorResponseIndex >= 0) {
      const firstErrName = `responses.${firstErrorResponseIndex}.answer`;
      const firstErrInput = document.getElementsByName(firstErrName)?.[0];
      firstErrInput?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleSubmit = () => {
    submitTask(undefined)
      .then(res => handleSubmitError(res?.responsesErrors))
      .catch(() => {
        showError('Could not submit your list');
      });
  };

  const handleDisabledList = () => {
    if (!(isUpcoming || isFacility)) return;

    const showErrorMessage = () => {
      if (isUpcoming) {
        return message.isUpcoming.error;
      }

      if (isFacility) {
        return message.isFacility;
      }

      return '';
    };

    showError(showErrorMessage(), undefined, false, {
      action: [],
    });
  };

  const loadingContainer = () => (
    <div className="relative flex-1 h-full overflow-hidden bg-white border-gray-200 md:col-span-8 xl:col-span-9 border-l-1">
      <TaskContentHeaderSkeleton haveSubmit={!!onSubmitSuccess} />
      <DuplicateTaskContentBodySkeleton />
    </div>
  );

  const noListContainer = () => (
    <div className="relative flex-1 h-full overflow-hidden bg-white border-gray-200 md:col-span-8 xl:col-span-9 border-l-1">
      <div className="flex items-center justify-center h-full">
        <Typography className="text-13">Please select a List to begin</Typography>
      </div>
    </div>
  );

  const showTextAlert = useMemo(() => {
    if (isUpcoming) {
      return message.isUpcoming.alert;
    }

    if (isFacility) {
      return message.isFacility;
    }

    return null;
  }, [isFacility, isUpcoming, message.isUpcoming.alert, message.isFacility]);

  if (initialLoading) return loadingContainer();
  if (!hasSelectedTask) return noListContainer();
  if (isLoading) return loadingContainer();

  return (
    <div className="relative flex-1 h-full overflow-hidden bg-white border-gray-200 md:col-span-8 xl:col-span-9 border-l-1">
      <div className="flex flex-col h-full overflow-auto bg-white md:col-span-9">
        <TaskContentHeader
          list={list}
          readOnly={readOnly}
          onRefresh={onRefresh}
          onSubmit={handleSubmit}
          setSelectedList={setSelectedList}
        />

        {(isUpcoming || isFacility) && (
          <Alert
            severity="error"
            sx={{
              backgroundColor: TASK_COLORS.late,
              color: '#000',
              fontWeight: 500,
              fontSize: '13px',
              padding: '4px 24px',
            }}
            icon={
              <ErrorIcon
                sx={{
                  fontSize: '20px',
                }}
              />
            }
          >
            {showTextAlert}
          </Alert>
        )}

        <Box
          sx={{ opacity: isUpcoming || isFacility ? 0.5 : 1 }}
          onClick={handleDisabledList}
          className="flex-1 overflow-auto"
        >
          <TaskContentBody
            list={list}
            readOnly={readOnly}
          />
        </Box>
      </div>

      {isSubmitting && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default TaskContent;
