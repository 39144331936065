import { QuestionLayout, RatingButtons } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  responseId: number;
  listStatus?: TTaskStatus;
  response?: TEmployeeTaskResponse;
};

function RatingResponse({ name, readOnly, listId, responseId, listStatus, response }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();
  const { minNumber, maxNumber, minLabel, maxLabel } = value?.item || {};

  const handleAnswerChange = (nextAnswer: number | null) => {
    onChange({ ...value, answer: nextAnswer });

    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(nextAnswer, additionalPayload);
    answer(answerPayload);
  };

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value?.comment ?? '',
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const answer = (payload: any) => {
    answerTask(payload)
      .then(res => {
        onChange({ ...value, ...res });
        showSuccess('Your answer has been submitted successfully');
      })
      .catch(() => {
        showError('Could not submit your answer');
      });
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly ?? false}
      task={value}
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
    >
      <RatingButtons
        minNumber={minNumber}
        maxNumber={maxNumber}
        minLabel={minLabel}
        maxLabel={maxLabel}
        disabled={readOnly}
        value={Number(value?.answer)}
        status={value?.status}
        isLoading={isLoading}
        onChange={handleAnswerChange}
      />
    </QuestionLayout>
  );
}

export default RatingResponse;
