import ConfirmDialog from 'app/components/cores/confirm-dialog';
import TaskContentBody from 'app/components/cores/tasks/content/body';
import { useGetTask } from 'app/hooks/api/employee/tasks';
import { useDeleteOnDemandTask } from 'app/hooks/api/on-demand-tasks';
import useTaskUrl from 'app/hooks/use-task-url';
import useToggle from 'app/hooks/use-toggle';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import clsx from 'clsx';
import { getDisplayRoleOrUserName } from 'domains/employee/task.domain';
import { AnimatePresence, motion } from 'framer-motion';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import { TAdminCalendar } from 'models';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { formatDate, isSameDates } from 'utils/date';

import { ViewApi } from '@fullcalendar/core';
import { DeleteOutline } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 590,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.default',
  boxShadow: 24,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '16px',

  maxHeight: 'calc(100vh)',
};

const variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  close: {
    opacity: 0,
    height: 0,
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  task: TAdminCalendar & {
    startDate: string | Date;
    endDate: string | Date;
  };
  view: ViewApi;
  eventInfo: any;
};

const useStyles = makeStyles(() => ({
  closeBtn: {
    background: '#F6F6F6',
    borderRadius: 8,
    boxShadow: 'none',
    color: '#1F242F',
    fontSize: '14px',
    fontWeight: 400,
    '&:hover': {
      background: '#ECEDEE',
      boxShadow: 'none',
      color: '#1F242F',
    },
  },
  assignStatus: {
    background: '#F6F6F6',
    borderRadius: 8,
  },
  dateView: {
    background: '#F6F6F6',
    borderRadius: 8,
    padding: '6px 12px',
    width: 'fit-content',
  },
  expired: {
    color: '#FF0000',
  },
  upcoming: {
    color: '#7C808B',
  },
  completed: {
    color: '#80BFE4',
  },
  tasksContent: {
    maxHeight: '400px',
  },
}));

export const ModalReviewCalendar = ({ open, onClose, title, task, view, eventInfo }: Props) => {
  const classes = useStyles();
  const { goToEmployeeList } = useTaskUrl();
  const dispatch = useDispatch();

  const { state: isOpenInfo, setToggle: toggleInfo } = useToggle();
  const { data: taskDetails } = useGetTask(Number(eventInfo?.event?.id));
  const form = useForm();
  const {
    mutate: deleteOnDemandTask,
    isLoading: isDeletingOnDemandTask,
    canDeleteOnDemandTask,
  } = useDeleteOnDemandTask();
  const { onDemandList } = task || {};

  const startListDate = task?.startDate || eventInfo.event.startStr;
  const endListDate = task?.endDate || eventInfo.event.endStr;

  const isToday = moment(view.currentStart).isSame(moment(), 'day');
  const date = isToday ? 'Today' : moment(view.currentStart).format('ddd DD');
  const startTime = moment(startListDate).format('hh:mmA');
  const endTime = moment(endListDate).format('hh:mmA');

  const isGoToList = useMemo(() => ['overdue', 'in_progress', 'pending'].includes(task.status), [task.status]);
  const isUpcoming = task.status === 'upcoming';

  const assignTo = getDisplayRoleOrUserName(task);

  useEffect(
    function updateForm() {
      if (_isEmpty(taskDetails)) return;
      form.reset(taskDetails);
    },
    [taskDetails, form],
  );

  const handleGoToList = () => {
    if (isGoToList) {
      goToEmployeeList(eventInfo?.event?.id);
      onClose();
    }
  };

  const renderListDate = () => {
    const isSameListDate = isSameDates(startListDate, endListDate);
    const startDate = formatDate(startListDate, 'DD/MM');
    const endDate = formatDate(endListDate, 'DD/MM');

    if (isSameListDate) return <></>;
    return (
      <Typography className="ml-8 text-12 font-500 text-secondaryLight">
        {endListDate ? `(${startDate} - ${endDate})` : `(${startDate})`}
      </Typography>
    );
  };

  const handleCloseConfirmDialog = () => {
    dispatch(closeDialog({}));
  };

  const handleConfirm = () => {
    deleteOnDemandTask(Number(eventInfo?.event?.id), {
      onSuccess: () => {
        handleCloseConfirmDialog();
        onClose();
      },
    });
  };

  const handleClickDelete = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Delete On-Demand Task"
            message="Are you sure you want to delete this on-demand task?"
            onClose={handleCloseConfirmDialog}
            onConfirm={handleConfirm}
            isLoading={isDeletingOnDemandTask}
            confirmButtonLabel="Delete"
          />
        ),
      }),
    );
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-hidden"
    >
      <Box sx={style}>
        <div className="flex items-center justify-between pt-12 pb-8 pl-24 pr-12 mb-8 border-b-1">
          <div className="flex-1">
            <Typography className="text-15 font-600">{title}</Typography>
          </div>

          {onDemandList && canDeleteOnDemandTask && (
            <Tooltip
              title="Delete On-Demand Task"
              placement="top"
            >
              <IconButton
                onClick={handleClickDelete}
                className="mr-8"
              >
                <DeleteOutline
                  className="text-20"
                  color="primary"
                />
              </IconButton>
            </Tooltip>
          )}

          <Button
            variant="contained"
            className={classes.closeBtn}
            onClick={onClose}
          >
            Close
          </Button>
        </div>

        <div className="flex flex-col flex-1 h-auto pt-12 pb-8 pl-24 pr-12 bg-gray-950">
          <Typography className="pb-16 text-20">{task.name}</Typography>

          <div className="flex items-center mb-16 space-x-16">
            <Typography className={clsx('font-medium text-15 rounded-8', classes.dateView)}>{date}</Typography>
            <div className="flex items-center">
              <Typography
                className={clsx('font-medium text-15 rounded-8', {
                  'text-errorMain': task.status === 'expired',
                  'text-gray-900': task.status === 'upcoming',
                  'text-taskCompleted': task.status === 'completed',
                  'text-taskPending': task.status === 'pending',
                  'text-taskInProgress': task.status === 'in_progress',
                  'text-taskOverdue': task.status === 'overdue',
                })}
              >
                {endListDate ? `${startTime} : ${endTime}` : startTime}
              </Typography>
              {renderListDate()}
            </div>
          </div>

          <div className={clsx('p-12', classes.assignStatus)}>
            <div className="flex items-center space-x-4">
              <Typography className="text-13">Assign to: {assignTo}</Typography>
            </div>

            <div className="flex items-center space-x-4">
              <Typography className="text-13">Task status: {_startCase(task.status)}</Typography>
            </div>

            {!isUpcoming && (
              <div className="flex items-center space-x-4">
                <Typography className="text-13">Additional Info:</Typography>
                <Typography
                  className="underline cursor-pointer font-600 text-13"
                  component="button"
                  onClick={isGoToList ? handleGoToList : toggleInfo}
                >
                  {isGoToList ? 'Go to list' : `${isOpenInfo ? 'Close' : 'View'} Detail`}
                </Typography>
              </div>
            )}

            <div className="mt-8">
              <FormProvider {...form}>
                <AnimatePresence>
                  {isOpenInfo && (
                    <motion.div
                      variants={variants}
                      initial="close"
                      animate="open"
                      exit="close"
                      transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.2 }}
                      className={clsx('bg-white overflow-y-auto', classes.tasksContent)}
                    >
                      <TaskContentBody
                        list={taskDetails}
                        readOnly={true}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </FormProvider>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
