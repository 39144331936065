import { TIME_FORMAT } from 'constants/index';
import { Moment } from 'moment';
import { forwardRef } from 'react';

import { TimePicker as TimePickerMui, TimePickerProps } from '@mui/x-date-pickers/TimePicker';

const TimePicker = forwardRef((props: TimePickerProps<Moment>, ref: React.Ref<any>) => {
  return (
    <TimePickerMui
      ref={ref}
      ampm={false}
      format={TIME_FORMAT}
      timeSteps={{ minutes: 1 }}
      className="w-full"
      slotProps={{
        textField: {
          size: 'small',
          variant: 'filled',
          InputProps: {
            disableUnderline: true,
            className: 'bg-paper rounded-4 w-full',
            classes: {
              input: 'py-10 rounded-4 bg-paper',
            },
          },
        },
      }}
      {...props}
    />
  );
});

export default TimePicker;
