import { QuestionLayout, UploadMedia } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import { useUploadMedia } from 'app/hooks/api/media';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import LoopIcon from '@mui/icons-material/Loop';
import { IconButton, Tooltip } from '@mui/material';

type AnswerOption = {
  onSuccess?: () => void;
  onError?: () => void;
};

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  listStatus?: TTaskStatus;
  responseId: number;
  response?: TEmployeeTaskResponse;
};

function MediaResponse({ name, readOnly, listId, listStatus, responseId, response }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { mutate: uploadMedia, isLoading: isUploadingMedia } = useUploadMedia({ isPublic: false });

  const { showError, showSuccess } = useShowMessage();
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadMediaRef = useRef<any>(null);

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value?.comment ?? '',
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const updateFormAndAnswer = (nextAnswer: any, options?: AnswerOption) => {
    onChange({ ...value, answer: nextAnswer });

    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload({ mediaAttributes: nextAnswer }, additionalPayload);

    answer(answerPayload, options);
  };

  // remove answer
  // success: clear image preview
  // error: remove _destroy property
  const handleRemoveMedia = () => {
    const nextAnswer = { ...value.answer, _destroy: true };
    updateFormAndAnswer(nextAnswer, {
      onSuccess: () => {
        uploadMediaRef.current?.clear();
      },
      onError: () => {
        onChange({
          ...value,
          answer: { ...value.answer, _destroy: undefined },
        });
      },
    });
  };

  // answer
  // error: clear image preview and update form state
  const handleAnswerAfterUpload = (nextAnswer: any) => {
    updateFormAndAnswer(nextAnswer, {
      onError() {
        uploadMediaRef.current?.clear();
        onChange({
          ...value,
          answer: { ...value.answer, id: null, files: null },
        });
      },
    });
  };

  const handleBrowserFile = (files: File[]) => {
    if (!files?.length) return;
    const file = files?.[0];
    upload(file, handleAnswerAfterUpload);
  };

  const upload = (file: File, onSuccess?: (nextAnswer: any) => void) => {
    setUploadProgress(0);
    uploadMedia(
      {
        file,
        onUploadProgress(progressEvent: any) {
          const { loaded, total } = progressEvent;
          const progress = loaded / total;
          setUploadProgress(progress);
        },
      },
      {
        onSuccess: (_response: any) => {
          const nextAnswer = { id: _response.id, files: _response.objectFiles };
          onSuccess?.(nextAnswer);
        },
      },
    );
  };

  const answer = (payload: any, options?: AnswerOption) => {
    answerTask(payload)
      .then(res => {
        onChange({ ...value, ...res });
        showSuccess('Your answer has been submitted successfully');
        options?.onSuccess?.();
      })
      .catch(() => {
        showError('Could not submit your answer');
        options?.onError?.();
      });
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly ?? false}
      task={value}
      rightActions={
        !!value?.answer?.id && (
          /* TODO show confirm dialog */
          <Tooltip title="Remove">
            <IconButton onClick={handleRemoveMedia}>
              <LoopIcon className="text-20" />
            </IconButton>
          </Tooltip>
        )
      }
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
    >
      <UploadMedia
        disabled={readOnly}
        uploadMediaRef={uploadMediaRef}
        value={value?.answer}
        isLoading={isUploadingMedia}
        uploadProgress={uploadProgress}
        onChange={handleBrowserFile}
      />
    </QuestionLayout>
  );
}

export default MediaResponse;
