import { useCreateOnDemandTask, useOnDemandTasks } from 'app/hooks/api/on-demand-tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { TRole, TUserLocation } from 'models';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';

import ControlledDataSelectV2 from '../data-select/controlled-data-select-v2';
import { ControlledTextInput } from '../inputs/text';

const validationSchema = yup.object().shape({
  task: yup.string().required('This field is required'),
  role: yup.string().required('This field is required'),
  description: yup.string().optional(),
});

const initialValues = {
  role: '',
  task: '',
  description: '',
};

type FormValues = typeof initialValues;

type Props = {
  location: TUserLocation;
  isOpen: boolean;
  onClose: () => void;
};

const OnDemandModal = ({ location, isOpen, onClose }: Props) => {
  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { showSuccess, showError } = useShowMessage();
  const roles: TRole[] = useSelector((store: any) => store?.auth?.user?.roles);
  const roleOptions = roles?.map(role => ({
    label: role.name,
    value: role?.externalId,
  }));
  const selectedRole = +form.watch('role');
  const [taskKey, setTaskKey] = useState(Math.random());
  const { data, isFetching: isGettingOnDemandTasks } = useOnDemandTasks({
    orgRoleId: selectedRole,
    externalLocationId: location?.externalId,
  });

  const { mutate: createOnDemandTask, isLoading: isCreatingOnDemandTask } = useCreateOnDemandTask();

  const onDemandTaskOptions =
    data?.records?.map((task: any) => ({
      label: task?.name,
      value: task?.id,
    })) ?? [];

  const handleRoleChange = () => {
    form.setValue('task', '');
    setTaskKey(Math.random());
  };

  const handleSubmitForm = (formValues: FormValues) => {
    createOnDemandTask(
      {
        id: +formValues.task, // task id
        orgRoleId: +formValues.role,
        description: formValues.description,
        locationId: location?.externalId,
      },
      {
        onSuccess: () => {
          showSuccess('On-demand list has been created successfully');
          onClose();
        },
        onError: () => {
          showError('Could not create on-demand list');
        },
      },
    );
  };

  return (
    <Modal
      open={isOpen}
      className="flex items-center justify-center"
    >
      <div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmitForm)}>
            <Card
              sx={{ width: '500px' }}
              className="bg-white rounded-16"
            >
              <CardHeader
                title="Add On-demand List"
                action={
                  <IconButton onClick={onClose}>
                    <CloseIcon className="text-20" />
                  </IconButton>
                }
                classes={{ title: 'text-18 font-600 text-secondaryMain' }}
                className="px-24"
              />
              <Divider />
              <CardContent className="px-24 space-y-24">
                <div className="space-y-8">
                  <Typography className="text-11 font-500 text-secondaryLight">
                    This on-demand list belongs to the role
                  </Typography>
                  <ControlledDataSelectV2
                    name="role"
                    options={roleOptions}
                    onChange={handleRoleChange}
                    placeholder="Select a role"
                    isClearable
                    maxMenuHeight={160}
                  />
                </div>

                <div className="space-y-8">
                  <Typography className="text-11 font-500 text-secondaryLight">On-demand List</Typography>
                  <ControlledDataSelectV2
                    name="task"
                    options={onDemandTaskOptions}
                    key={taskKey}
                    placeholder="Select a list"
                    isClearable
                    isLoading={isGettingOnDemandTasks}
                    isDisabled={!selectedRole}
                    maxMenuHeight={160}
                  />
                </div>

                <div className="space-y-8">
                  <Typography className="text-11 font-500 text-secondaryLight">Note</Typography>
                  <ControlledTextInput
                    placeholder="Type your note here..."
                    name="description"
                    variant="filled"
                    className="h-full shadow"
                    multiline={true}
                    minRows={3}
                    InputProps={{
                      className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
                      disableUnderline: true,
                      classes: {
                        input: 'px-8 text-13',
                      },
                    }}
                  />
                </div>
              </CardContent>
              <Divider />
              <CardActions className="flex items-center justify-end px-24">
                <Button
                  variant="outlined"
                  className="w-80 text-13 font-400 text-secondaryLight"
                  sx={{
                    borderColor: 'secondary.light',
                    ':hover': { borderColor: 'secondary.light' },
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <LoadingButton
                  className="text-black text-13 font-400 w-80"
                  sx={{
                    bgcolor: '#F5D02F',
                    ':hover': {
                      bgcolor: '#F5D02F',
                    },
                  }}
                  type="submit"
                  disabled={isCreatingOnDemandTask}
                  loading={isCreatingOnDemandTask}
                >
                  Add
                </LoadingButton>
              </CardActions>
            </Card>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default OnDemandModal;
