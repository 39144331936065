import { StaticDatePicker } from 'app/components/cores/date-picker/static-date-picker';
import { StaticDateRangePicker } from 'app/components/cores/date-picker/static-date-range-picker';
import clsx from 'clsx';
import { TCalendarMode } from 'models';
import React, { useEffect, useRef, useState } from 'react';
import { delay } from 'utils/date';

import { EventContentArg } from '@fullcalendar/core';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CalendarHeader from './header';
import ListStatusLegend from './list-status-legend';
import { ListViewCalendarTaskCell } from './listview-task-cell';

type Props = {
  isListView?: boolean;
  isMyList?: boolean;
  isFilterMultipleDates?: boolean;
  hasLocation?: boolean;
  view: TCalendarMode;
  listViewEvents?: any;
  calendarHeight?: string | number;
  listViewCalendarRef?: React.RefObject<any>;
  loading?: boolean;
  dayRange: { startDate?: Date; endDate?: Date };
  dayView?: Date;
  onViewDateChange?: (newDate: Date) => void;
  onDayRangeChange?: ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => void;
  [key: string]: any;
};

function CoreCalendar(props: Props) {
  const {
    isListView,
    isMyList,
    isFilterMultipleDates,
    hasLocation,
    listViewEvents,
    calendarHeight,
    listViewCalendarRef,
    loading,
    view,
    dayRange,
    dayView,
    onViewDateChange,
    onDayRangeChange,
    ...restProps
  } = props;

  const classes = useStyles();
  const [isShowOnDemandList, setIsShowOnDemandList] = useState(false);
  const calendarRef = useRef<any>(null);
  const hasOnDemandList = !loading && isListView && !!listViewEvents?.onDemandEvents?.length;
  const hasNormalList = !loading && !!listViewEvents?.events?.length;
  const isEmptyList = hasOnDemandList && !hasNormalList;

  useEffect(() => {
    if (!isFilterMultipleDates || !dayRange?.startDate) return;
    const calendar = calendarRef.current?.getApi();

    (async () => {
      await delay(0);
      calendar?.gotoDate(dayRange?.startDate ? new Date(dayRange?.startDate) : new Date());
    })();
  }, [dayRange, isFilterMultipleDates]);

  useEffect(() => {
    if (isFilterMultipleDates || !dayView) return;
    const calendar = calendarRef.current?.getApi();

    (async () => {
      await delay(0);
      calendar?.gotoDate(dayView ? new Date(dayView) : new Date());
    })();
  }, [dayView, isFilterMultipleDates]);

  const renderEventContent = (eventInfo: EventContentArg) => {
    return (
      <ListViewCalendarTaskCell
        eventInfo={eventInfo}
        isMyList={isMyList}
      />
    );
  };

  const renderHeader = (headerInfo: any) => {
    const date = new Date(headerInfo?.date).toISOString();

    return (
      <CalendarHeader
        date={date}
        isToday={headerInfo?.isToday}
        isShowMonth={true}
      />
    );
  };
  const renderNoEventContent = () => {
    if (loading) return null;
    if (!hasLocation) {
      return <Typography className="text-13 font-400">Please select a location to see the lists</Typography>;
    }
    if (!listViewEvents?.events?.length) {
      return <Typography className="text-13 font-400">No lists to display</Typography>;
    }
    return null;
  };

  const handleToggleOnDemandList = () => {
    setIsShowOnDemandList(!isShowOnDemandList);
  };

  return (
    <div className={clsx('flex items-start justify-center w-100', classes.listView)}>
      <div className="flex-1">
        {loading && (
          <div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
            <CircularProgress />
          </div>
        )}

        {hasOnDemandList && (
          <div
            className={clsx(
              'mb-24',
              classes.onDemandViewContainer,
              isShowOnDemandList ? 'min-h-32' : 'h-32 overflow-hidden',
            )}
          >
            <div
              role="button"
              tabIndex={-1}
              className="sticky top-0 z-10 flex items-center justify-between h-32 pl-24 pr-16 bg-taskOnDemand"
              onClick={handleToggleOnDemandList}
            >
              <Typography className="text-13 font-600 text-secondaryMain">On Demand</Typography>
              <Typography className="text-12 font-500 text-secondaryMain">
                {isShowOnDemandList ? 'Hide' : 'Show'}
              </Typography>
            </div>

            <FullCalendar
              eventClassNames="list-event"
              ref={calendarRef}
              viewClassNames={clsx(classes.view, classes.onDemandView)}
              firstDay={1}
              height={calendarHeight}
              plugins={[listPlugin]}
              initialDate={new Date()}
              initialView="listYear"
              headerToolbar={{ left: '', center: '', right: '' }}
              events={listViewEvents?.onDemandEvents}
              displayEventTime={false}
              eventContent={renderEventContent}
              dayHeaderContent={() => null}
              nowIndicator={true}
              noEventsContent={() => null}
              {...restProps}
            />
          </div>
        )}

        <div className={clsx('relative', classes.currentViewContainer, isEmptyList && 'hidden')}>
          <FullCalendar
            eventClassNames="list-event"
            ref={calendarRef}
            viewClassNames={clsx(classes.view, isEmptyList && classes.normalView)}
            firstDay={1}
            height={calendarHeight}
            plugins={[listPlugin]}
            initialDate={new Date()}
            initialView="listYear"
            headerToolbar={{ left: '', center: '', right: '' }}
            events={listViewEvents?.events}
            displayEventTime={false}
            eventContent={renderEventContent}
            dayHeaderContent={renderHeader}
            nowIndicator={true}
            noEventsContent={renderNoEventContent}
            {...restProps}
          />
        </div>
      </div>

      <div className={clsx(isFilterMultipleDates && 'space-y-40')}>
        {isFilterMultipleDates ? (
          <StaticDateRangePicker
            value={dayRange}
            onChange={onDayRangeChange}
          />
        ) : (
          <StaticDatePicker
            dayView={dayView}
            onViewDateChange={onViewDateChange}
          />
        )}
        <ListStatusLegend />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: any) => ({
  listView: {
    '& .fc-toolbar': {
      display: 'none',
    },
    '& .list-event td': {
      padding: 0,
    },
    '& .list-event .list-event-cell': {
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  event: {
    '& .fc-event-time': {
      display: 'none',
    },
  },
  view: {
    '& tr.fc-list-day': {
      position: 'relative',
      top: 6,
    },
    '& th[scope=colgroup]': {
      backgroundColor: 'transparent !important',
      '& .fc-list-day-cushion': {
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: 'inherit',
      },
    },
    '& .fc-list-event-time': {
      paddingLeft: '136px !important',
    },

    '& *': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    '& td, & .fc-col-header-cell': {
      borderColor: '#F5F5F5 !important',
    },
    '& th, & .fc-col-header-cell': {
      border: '1px solid #ddd',
    },
    '& .fc-col-header-cell': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& > table, & > table > tbody > tr > td': {
      border: '0 !important',
    },
    '& > table > thead > tr > th': {
      border: 0,
    },
    // day week
    '& .fc-col-header-cell-cushion': {
      fontSize: 13,
      fontWeight: 500,
      color: `${theme.palette.secondary.light} !important`,
      textTransform: 'uppercase',
      textDecoration: 'none !important',
    },
    // day
    '& .fc-daygrid-day-number': {
      fontSize: 18,
      fontWeight: 600,
    },
    // day inner
    '& .fc-daygrid-day-top': {
      flexDirection: 'row',
      paddingTop: 4,
      paddingLeft: 4,
    },
    // today
    '& td.fc-day-today > .fc-daygrid-day-frame': {
      backgroundColor: '#F2FCFF',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    // event dot
    '& .fc-list-event-graphic': {
      display: 'none',
    },
    // event title
    '& .fc-event-title': {
      fontSize: 11,
      fontWeight: 500,
    },
  },
  monthView: {},
  onDemandView: {
    borderTop: '0 !important',
    borderBottom: '0 !important',
  },
  onDemandToggleButton: {
    position: 'absolute',
    zIndex: 10,
    paddingLeft: 18,
  },
  onDemandViewContainer: {
    position: 'relative',
    zIndex: 1,
    borderBottom: '1px solid  #ddd',
  },
  currentViewContainer: {
    zIndex: 2,
    '& .fc-media-screen': {
      backgroundColor: 'white',
    },
  },
  normalView: {
    '& .fc-list-empty': {
      display: 'none',
    },
  },
}));

export default CoreCalendar;
