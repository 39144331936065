import clsx from 'clsx';

import Typography from '@mui/material/Typography';

type Props = { name: string; value: string; containerClassName?: string; valueClassName?: string };

function InfoItem({ name, value, containerClassName, valueClassName }: Props) {
  return (
    <div className={clsx('flex flex-col items-start', containerClassName)}>
      <Typography className="text-13 font-400 text-secondaryLight">{name}</Typography>
      {value ? (
        <Typography className={clsx('text-13 font-600 text-secondaryMain mt-4', valueClassName)}>{value}</Typography>
      ) : (
        <div className="min-h-20" />
      )}
    </div>
  );
}

export default InfoItem;
