import { ControlledTextInput } from 'app/components/cores/inputs/text';
import useQRCode from 'app/hooks/use-qr-code';
import JsBarcode from 'jsbarcode';
import { useEffect, useRef } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';

import { Button, Typography } from '@mui/material';

type Props = {
  control?: Control;
  name: string;
};

const BarCodeOption = ({ name, control }: Props) => {
  const containerRef = useRef<any>();
  const code = useWatch({ control, name: `${name}.code` });

  const { printAreaRef, printCode, generateCode, downloadCode } = useQRCode({
    downloadElement: 'barcode',
    downloadFileName: code,
  });

  const {
    field: { onChange },
  } = useController({
    control,
    name: `${name}.code`,
  });

  useEffect(() => {
    if (code === '' || !containerRef.current) {
      return;
    }
    JsBarcode(containerRef.current, code, { displayValue: true });
    containerRef?.current?.setAttribute('id', 'barcode');
    containerRef?.current?.setAttribute('width', '356');
  }, [code]);

  const handleGenerateCode = () => {
    onChange?.(generateCode(14));
  };

  return (
    <div className="flex flex-col pb-16 space-y-8">
      <Typography className="font-600 text-11">Barcode Options</Typography>
      <div className="flex flex-col justify-between mt-8 space-y-8">
        <Typography className="text-11 text-secondaryMain font-500">Generate Code</Typography>
        <div className="flex items-start w-full space-x-8">
          <div className="flex-1">
            <ControlledTextInput
              name={`${name}.code`}
              control={control}
              variant="filled"
              className="h-full shadow"
              InputProps={{
                className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
                disableUnderline: true,
                classes: {
                  input: 'p-8 text-11',
                },
              }}
            />
          </div>
          <Button
            disableElevation={true}
            color="primary"
            variant="contained"
            className="h-24 px-12 mt-4 capitalize text-11 font-500 rounded-4"
            onClick={handleGenerateCode}
          >
            Generate Code
          </Button>
        </div>

        <div className="w-full">
          <Typography className="text-11 text-secondaryMain font-500">Manual Input Code</Typography>
          <ControlledTextInput
            name={`${name}.manualCode`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-11',
              },
            }}
          />
        </div>

        {!code ? (
          <Typography className="text-11 text-errorMain">Please input a valid code</Typography>
        ) : (
          <>
            <div
              ref={printAreaRef}
              className="flex items-center justify-center w-full pt-16"
            >
              <svg
                ref={containerRef}
                width="128"
                style={{ height: 'auto', maxWidth: '100%', width: '100%', margin: '0 auto' }}
                className="bg-transparent"
              />
            </div>

            <div className="flex items-center justify-end pt-16 space-x-8">
              <Button
                disableElevation={true}
                color="primary"
                variant="outlined"
                className="h-24 px-12 capitalize w-88 min-w-88 text-11 font-500 rounded-4"
                onClick={downloadCode}
              >
                Download
              </Button>
              <Button
                disableElevation={true}
                color="primary"
                variant="contained"
                className="h-24 px-12 capitalize w-88 min-w-88 text-11 font-500 rounded-4"
                onClick={printCode}
              >
                Print
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BarCodeOption;
