/* eslint-disable */
import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function Logo(props: Props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 82 70"
      fill="none"
    >
      <path
        d="M0 29.5C0 27.0147 2.01472 25 4.5 25C6.98528 25 9 27.0147 9 29.5V50.5C9 52.9853 6.98528 55 4.5 55C2.01472 55 0 52.9853 0 50.5V29.5Z"
        fill="currentColor"
      />
      <path
        d="M14 21.5C14 19.0147 16.0147 17 18.5 17C20.9853 17 23 19.0147 23 21.5V64.5C23 66.9853 20.9853 69 18.5 69C16.0147 69 14 66.9853 14 64.5V21.5Z"
        fill="currentColor"
      />
      <path
        d="M29 5.5C29 3.01472 31.0147 1 33.5 1C35.9853 1 38 3.01472 38 5.5V60.5C38 62.9853 35.9853 65 33.5 65C31.0147 65 29 62.9853 29 60.5V5.5Z"
        fill="currentColor"
      />
      <path
        d="M43 18.5C43 16.0147 45.0147 14 47.5 14C49.9853 14 52 16.0147 52 18.5V44.5C52 46.9853 49.9853 49 47.5 49C45.0147 49 43 46.9853 43 44.5V18.5Z"
        fill="currentColor"
      />
      <path
        d="M58 4.5C58 2.01472 60.0147 0 62.5 0C64.9853 0 67 2.01472 67 4.5V51.5C67 53.9853 64.9853 56 62.5 56C60.0147 56 58 53.9853 58 51.5V4.5Z"
        fill="currentColor"
      />
      <path
        d="M73 29.5C73 27.0147 75.0147 25 77.5 25C79.9853 25 82 27.0147 82 29.5V53.5C82 55.9853 79.9853 58 77.5 58C75.0147 58 73 55.9853 73 53.5V29.5Z"
        fill="currentColor"
      />
      <path
        d="M68 63C68 62.4477 68.4477 62 69 62H76C76.5523 62 77 62.4477 77 63V69C77 69.5523 76.5523 70 76 70H69C68.4477 70 68 69.5523 68 69V63Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(Logo);
