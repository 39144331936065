import { ControlledDataSelect } from 'app/components/cores/data-select';
import RoleSelection from 'app/components/lists/assignment/role-selection';
import AttributeContainer from 'app/components/lists/attribute-container';
import { useInfiniteQueryTasks } from 'app/hooks/api/tasks';
import clsx from 'clsx';
import { GROUP_OPTIONS, ITEM_TYPES_MAP } from 'constants/index';
import _ from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { TItemType, TListTrigger } from 'models';
import { useCallback, useEffect, useRef } from 'react';
import { Control, useController, useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CheckboxDefault from './checkbox-default';
import ControlledSelectList from './controlled-select-list';
import { DateTimeCondition } from './display/date-time-condition/conditions';
import { MultipleCondition } from './multiple-condition';
import { SingleCondition } from './single-condition';

type ConditionalTasks = {
  tasks: {
    id: number;
    listType: string;
    orgRoleIds: number[];
    default: boolean;
    orgRoles: { externalId: number; name: string }[];
  }[];
  comparisons: { logic: string }[];
}[];

type Props = {
  isDisabled?: boolean;
  control?: Control;
  name: string;
  isExpanded?: boolean;
  onPanelChange?: (nextExpandedPanel: 'conditional-task' | 'notify' | 'conditional-list' | undefined) => void;
};

function ConditionalLogicContainerList({ isDisabled, control, name, isExpanded, onPanelChange }: Props) {
  const classes = useStyles();
  const { setValue, clearErrors } = useFormContext();
  const { listId: listIdParam } = useParams<{ listId: string }>();

  const {
    data: lists,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQueryTasks({ per_page: 500, status: 'published' }, { enabled: !isDisabled && isExpanded });

  const {
    field: { value: currentItem, onChange },
  } = useController({ control, name });

  const mountedRef = useRef(false);

  const idName = `${name}.conditionalTasks[0].tasks[0].id`;
  const defaultName = `${name}.conditionalTasks[0].tasks[0].default`;
  const orgRolesName = `${name}.conditionalTasks[0].tasks[0].orgRoles`;
  const orgRoleIdsName = `${name}.conditionalTasks[0].tasks[0].orgRoleIds`;
  const listTypeName = `${name}.conditionalTasks[0].tasks[0].listType`;
  const comparisonsName = `${name}.conditionalTasks[0].comparisons`;

  const conditionalTasks: ConditionalTasks = currentItem?.conditionalTasks ?? [];
  const task = conditionalTasks?.[0]?.tasks?.[0];
  const comparisons = conditionalTasks?.[0]?.comparisons;

  const selectedListId = task?.id;
  const defaultCheckbox = task?.default;
  const orgRolesValue = task?.orgRoles as TListTrigger['orgRoles'];
  const isNumericConditions = ([ITEM_TYPES_MAP.NUMERIC] as TItemType[]).includes(currentItem?.type);
  const isDateTimeConditions = ([ITEM_TYPES_MAP.DATE_TIME] as TItemType[]).includes(currentItem?.type);
  const comparisonsWatch = useWatch({ control, name: comparisonsName });

  const hasComparisons = !!comparisonsWatch?.length;
  const hasLists = lists?.length > 0;

  const getSelectedList = useCallback(
    (listId: number): any | undefined =>
      lists?.length > 0 ? lists.find((list: any) => list?.id === listId) : undefined,
    [lists],
  );

  const selectedList = getSelectedList(selectedListId);

  // effect to trigger validate empty list
  useEffect(() => {
    if (selectedListId || !hasComparisons) return;
    setValue(idName, undefined);
  }, [idName, hasComparisons, selectedListId, setValue]);

  // effect to set default assign roles when turn on use default assign roles checkbox
  useEffect(() => {
    if (!selectedListId || !lists?.length || mountedRef.current) return;
    mountedRef.current = true;

    if (!task?.default) return;

    const listItem = _cloneDeep(getSelectedList(selectedListId));
    setValue(orgRolesName, listItem?.orgRoles);
    setValue(orgRoleIdsName, getOrgRoleIds(listItem?.orgRoles));
  }, [orgRolesName, orgRoleIdsName, selectedListId, lists, task, setValue, getSelectedList]);

  const getOrgRoleIds = (roles: { externalId: number; name: string }[]) => {
    if (!roles || roles?.length === 0) return [];
    return roles.map(role => role.externalId) ?? [];
  };

  const handleChangeList = (id: number | null) => {
    if (!id) {
      setValue(idName, null);
      setValue(defaultName, false);
      setValue(listTypeName, null);
      setValue(orgRolesName, []);
      setValue(orgRoleIdsName, []);
      return;
    }

    const listItem = _cloneDeep(getSelectedList(id));

    setValue(idName, listItem?.id);
    setValue(defaultName, true);
    setValue(listTypeName, listItem?.listType);
    setValue(orgRolesName, listItem?.orgRoles);
    setValue(orgRoleIdsName, getOrgRoleIds(listItem?.orgRoles));
  };

  const handleChangeRoles = (
    roles: { externalId: number; name: string; orgRoleName?: string; orgRoleId?: number }[],
  ) => {
    const orgRoles = roles.map(role => ({
      ...role,
      externalId: role?.orgRoleId ?? role.externalId,
      name: role?.orgRoleName ?? role.name,
    }));

    const clonedConditionalTasks = _cloneDeep(conditionalTasks);
    _set(clonedConditionalTasks, '[0].tasks[0].orgRoles', orgRoles);
    _set(clonedConditionalTasks, '[0].tasks[0].orgRoleIds', getOrgRoleIds(orgRoles));
    onChange({ ...currentItem, conditionalTasks: clonedConditionalTasks });
  };

  const setDefaultValues = () => {
    setValue(listTypeName, selectedList?.listType);
    setValue(orgRolesName, selectedList?.orgRoles);
    setValue(orgRoleIdsName, getOrgRoleIds(selectedList?.orgRoles));
  };

  const handleCheckboxChange = (checked: boolean) => {
    if (!checked) return;
    setDefaultValues();
  };

  const handleRemove = () => {
    setValue(`${name}.conditionalTasks`, []);
  };

  const handleLoreMoreTasks = () => {
    if (isFetchingNextPage) return;
    if (hasNextPage) fetchNextPage();
  };

  const handleFilterOption = (option: any, searchText: string) => {
    const canSearch = option?.label?.toLowerCase().includes(searchText?.toLowerCase());
    return canSearch && Number(option?.value) !== Number(listIdParam);
  };

  const handleRemoveAllLogic = () => {
    setValue(`${name}.conditionalTasks`, []);
  };

  useEffect(() => {
    if (!selectedListId) return;

    if (defaultCheckbox) setDefaultValues();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListId]);

  useEffect(() => {
    const existDestroy = orgRolesValue?.some(role => role?._destroy);

    if (existDestroy) {
      const filteredRoles = orgRolesValue.filter(role => !role._destroy);
      setValue(orgRolesName, filteredRoles);
      setValue(orgRoleIdsName, getOrgRoleIds(filteredRoles));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRolesValue]);

  useEffect(() => {
    const isEmptyComparisons = !comparisons || _.isEmpty(comparisons?.[0]) || !comparisons?.[0]?.logic;
    const isEmptyTasks = !task || _.isEmpty(task);

    if (isEmptyComparisons && isEmptyTasks) {
      setValue(`${name}.conditionalTasks`, []);
    }

    if (isEmptyComparisons) {
      clearErrors(idName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisons?.[0]?.logic, task]);

  const renderConditionComponent = () => {
    if (isNumericConditions) {
      return (
        <MultipleCondition
          control={control}
          name={comparisonsName}
          selectedConditionalItem={currentItem}
          key={comparisons?.[0]?.logic ?? 0}
          onRemoveAllLogic={handleRemoveAllLogic}
        />
      );
    }
    if (isDateTimeConditions) {
      return (
        <DateTimeCondition
          control={control}
          name={`${name}.conditionalTasks[0]`}
          selectedConditionalItem={currentItem}
          key={comparisonsWatch?.[0]?.logic ?? 0}
        />
      );
    }
    return (
      <SingleCondition
        control={control}
        name={comparisonsName}
        selectedConditionalItem={currentItem}
        instructionText="Trigger this list, if answer is"
        key={comparisons?.[0]?.logic ?? 0}
      />
    );
  };

  return (
    <Accordion
      className="bg-white shadow-0"
      classes={{
        disabled: classes.accordionRootDisabled,
      }}
      disableGutters={true}
      expanded={isExpanded}
      onChange={() => onPanelChange?.('conditional-list')}
      sx={{ '&:before': { display: 'none' } }}
    >
      <AccordionSummary
        classes={{
          root: clsx('min-h-40 px-24', isExpanded && classes.notifySummaryRoot),
          content: 'my-4',
          disabled: classes.accordionSummaryDisabled,
        }}
        expandIcon={
          isExpanded ? (
            <CloseIcon
              color="primary"
              fontSize="small"
            />
          ) : (
            <AddIcon
              color="primary"
              fontSize="small"
            />
          )
        }
      >
        <Typography
          color="primary"
          className="text-12 font-600"
        >
          List Trigger Logic (optional)
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: 'px-24 relative' }}>
        <Typography className="text-13 font-400 text-secondaryLight">
          Select the list that will be generated based on the configurations
        </Typography>

        <div className="space-y-8">
          <div className="pt-8">{renderConditionComponent()}</div>

          {hasComparisons && hasLists && (
            <>
              <ControlledSelectList
                isSearchable
                isClearable
                name={idName}
                control={control}
                placeholder="List Name"
                options={lists}
                menuPlacement="top"
                onChange={handleChangeList}
                onMenuScrollToBottom={handleLoreMoreTasks}
                filterOption={handleFilterOption}
                key={selectedListId ?? 0}
              />

              {selectedList && (
                <>
                  <CheckboxDefault
                    name={defaultName}
                    label="Use default Assign Role(s) and List Type"
                    onChange={handleCheckboxChange}
                    key={selectedListId ?? 0}
                  />

                  <AttributeContainer
                    label="Assign Role(s)"
                    labelClassName="text-11 font-400"
                  >
                    <RoleSelection
                      control={control}
                      name={orgRolesName}
                      placeholder="Assign Role to List"
                      menuPlacement="top"
                      onChange={handleChangeRoles}
                      disabled={defaultCheckbox}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.externalId}
                      key={selectedListId ?? 0}
                      enabled={isExpanded && !isDisabled}
                    />
                  </AttributeContainer>

                  <AttributeContainer
                    label="List Type"
                    labelClassName="text-11 font-400"
                  >
                    <div className="w-128">
                      <ControlledDataSelect
                        options={GROUP_OPTIONS}
                        name={listTypeName}
                        control={control}
                        key={selectedListId ?? 0}
                        disabled={defaultCheckbox}
                      />
                    </div>
                  </AttributeContainer>
                </>
              )}
            </>
          )}

          {hasComparisons && (
            <div className="flex items-center justify-end mt-8">
              <Button
                disableElevation={true}
                variant="outlined"
                color="error"
                className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
                onClick={handleRemove}
              >
                Remove
              </Button>
            </div>
          )}
        </div>

        {isDisabled && <div className="absolute inset-0" />}
      </AccordionDetails>
    </Accordion>
  );
}

export default ConditionalLogicContainerList;

const useStyles = makeStyles(() => {
  return {
    accordionRootDisabled: {
      backgroundColor: 'white !important',
      '& *': {
        color: '#C8CBD2',
      },
    },
    accordionSummaryDisabled: {
      opacity: '1 !important',
    },
    notifySummaryRoot: {
      boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.1) !important',
      borderRadius: '16px 16px 0px 0px !important',
    },
  };
});
