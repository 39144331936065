import { MultipleChoiceOptions } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TMultipleChoiceItem, TOption, TQuestionLayoutView } from 'models';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  data: TMultipleChoiceItem;
  name: string;
  listId: number;
  readOnly?: boolean;
  view: TQuestionLayoutView;
};

const MultipleChoice = ({ data, name, listId, readOnly, view }: Props) => {
  const { resetField, setValue, setError, clearErrors } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const { mutateAsync: answerTask, isLoading } = useEditHistoryTask({ taskId: listId, responseId: value?.id });
  const isRequired = value?.item?.required;
  const isValidAnswer = Array.isArray(value?.answer) && value?.answer.length > 0;

  const { showSuccess, showError } = useShowMessage();

  const answerMapping = (options: any) => {
    return options?.map((option: TOption) => option.value);
  };

  const handleAnswerChange = (nextAnswer: TOption[]) => {
    onChange({ ...value, answer: answerMapping(nextAnswer) });
  };

  useEffect(() => {
    if (isRequired && isValidAnswer) clearErrors(name);
  }, [isValidAnswer, isRequired, clearErrors, name]);

  const checkAnswerIsValid = () => {
    if (isRequired && !isValidAnswer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const answer = async (payload: any) => {
    try {
      const res = await answerTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  const handleSave = () => {
    if (!checkAnswerIsValid()) return;
    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview={true}
      task={value}
      name={name}
      onSaveEdit={handleSave}
      view={view}
      isLoading={isLoading}
    >
      <MultipleChoiceOptions
        options={data?.multipleChoiceOptions}
        disabled={readOnly}
        value={value?.answer}
        status={value?.status}
        onChange={handleAnswerChange}
        key={value?.answer}
      />
    </QuestionLayoutMapper>
  );
};

export default MultipleChoice;
