import { MultipleChoiceOptions, QuestionLayout } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { TOption } from 'models/option.model';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  responseId: number;
  listStatus?: TTaskStatus;
  response?: TEmployeeTaskResponse;
};

function MultipleChoiceResponse({ name, readOnly, listId, responseId, listStatus, response }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();
  const multipleChoiceOptions = value?.item?.multipleChoiceOptions || [];

  const answerMapping = (options: any) => {
    return options?.map((option: TOption) => option.value);
  };

  const handleAnswerChange = (nextAnswer: TOption[]) => {
    onChange({ ...value, answer: answerMapping(nextAnswer) });

    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(answerMapping(nextAnswer), additionalPayload);
    answer(answerPayload);
  };

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value?.comment ?? '',
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value.answer, additionalPayload);
    answer(answerPayload);
  };

  const answer = (payload: any) => {
    answerTask(payload)
      .then(res => {
        onChange({ ...value, ...res });
        showSuccess('Your answer has been submitted successfully');
      })
      .catch(() => {
        showError('Could not submit your answer');
      });
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly ?? false}
      task={value}
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
    >
      <MultipleChoiceOptions
        options={multipleChoiceOptions}
        disabled={readOnly}
        value={value?.answer}
        status={value?.status}
        onChange={handleAnswerChange}
        isLoading={isLoading}
        listStatus={listStatus}
      />
    </QuestionLayout>
  );
}

export default MultipleChoiceResponse;
