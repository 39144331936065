export const Paths = {
  root: '/',
  ssoLogin: '/sso_login',
  login: '/login',
  logout: '/sso_logout',
  template: '/templates',
  task: '/tasks', // admin
  list: '/lists', // admin
  listsMonitor: '/lists-monitor',
  tag: '/tags',
  location: '/locations',
  history: '/histories',
  calendar: '/calendar',
  facilityTask: '/facility-tasks',
  myRoleTask: '/my-role-tasks',
  performance: '/performance',
  myTask: '/my-tasks',
  myShift: '/my-shifts',
  ess: '/ess',
  myRoster: '/my-roster',
};

export const DEFAULT_ADMIN_PATH = Paths.myTask;

export const DEFAULT_MANAGER_PATH = Paths.myTask;

export const DEFAULT_EMPLOYEE_PATH = Paths.myTask;
