import AdminRoleSelect from 'app/components/cores/employee/selection/admin-role';
import EmployeeSelect from 'app/components/cores/employee/selection/employee';
import LocationSelect from 'app/components/cores/employee/selection/location';
import RoleSelect from 'app/components/cores/employee/selection/role';
import CalendarIcon from 'app/components/cores/icons/calendar';
import ListCheckIcon from 'app/components/cores/icons/list-check';
import UserIcon from 'app/components/cores/icons/user';
import clsx from 'clsx';
import { SHOW_CALENDAR_WEEK_VIEW } from 'constants/calendar';
import _ from 'lodash';
import { TCalendarMode, TIMEZONE_SELECTOR_TYPES, TSelectItem, TTimezoneSelectorType, TUserLocation } from 'models';
import { useEffect } from 'react';
import { getNextDate, getNextWeek, getPrevDate, getPrevWeek } from 'utils/date';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ComputerIcon from '@mui/icons-material/Computer';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, ButtonGroup, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import StatusSelect from './status-select';

type Props = {
  view: TCalendarMode;
  date: Date;
  calendarRef?: React.RefObject<any>;
  filter?: any;
  setFilter?: any;
  isListView?: boolean;
  isMyList?: boolean;
  canViewUserCalendar?: boolean;
  onView: (newView: TCalendarMode) => void;
  onViewDateChange?: (newDate: Date) => void;
  onReload: () => void;
  onListViewChange?: (isListView: boolean) => void;
  onMyListChange?: (isMyList: boolean) => void;
  onSelectLocation?: (nextLocation: TUserLocation) => void;
  timezoneSelector?: TTimezoneSelectorType;
  onTimezoneSelectorChange?: (newTimeType: TTimezoneSelectorType) => void;
};

function Toolbar({
  filter,
  setFilter,
  date,
  calendarRef,
  view,
  isListView,
  isMyList,
  canViewUserCalendar,
  onView,
  onReload,
  onViewDateChange,
  onListViewChange,
  onMyListChange,
  onSelectLocation,
  timezoneSelector,
  onTimezoneSelectorChange,
}: Props) {
  const classes = useStyles(isListView, isMyList)();
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });

  const onPrevious = () => {
    const calendar = calendarRef?.current?.getApi();
    calendar?.prev();

    const clonedDate = _.cloneDeep(date);

    const prevDate = view === 'timeGridDay' ? getPrevDate(clonedDate) : getPrevWeek(clonedDate);
    onViewDateChange?.(prevDate);
  };

  const onNext = () => {
    const calendar = calendarRef?.current?.getApi();
    calendar?.next();
    const clonedDate = _.cloneDeep(date);
    const nextDate = view === 'timeGridDay' ? getNextDate(clonedDate) : getNextWeek(clonedDate);
    onViewDateChange?.(nextDate);
  };

  const onViewDay = () => {
    onView?.('timeGridDay');
    onViewDateChange?.(new Date());
  };

  const onViewWeek = () => {
    onView?.('timeGridWeek');
    onViewDateChange?.(new Date());
  };

  const handleListViewChange = (_isListView: boolean) => () => {
    onListViewChange?.(_isListView);
  };

  const handleMyListChange = () => {
    if (!canViewUserCalendar) return;
    onMyListChange?.(!isMyList);
  };

  useEffect(() => {
    if (isMyList) onTimezoneSelectorChange?.(TIMEZONE_SELECTOR_TYPES.COMPUTER);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMyList]);

  const handleSelectLocation = (location: TSelectItem) => {
    onSelectLocation?.(location as TUserLocation);
  };

  const handleChangeTimeType = () => {
    onTimezoneSelectorChange?.(
      timezoneSelector === TIMEZONE_SELECTOR_TYPES.LOCATION
        ? TIMEZONE_SELECTOR_TYPES.COMPUTER
        : TIMEZONE_SELECTOR_TYPES.LOCATION,
    );
  };

  return (
    <div className={clsx('flex items-center w-full px-4 pb-16 mb-4', classes.root)}>
      <div className={clsx('flex items-center flex-1 flex-wrap gap-16', classes.actions)}>
        {/* view mode */}
        <div className="flex items-center mr-32">
          {/* sheet or list view */}
          <ButtonGroup className="mr-12">
            <Tooltip title="Sheet View">
              <Button
                disableElevation={true}
                color={!isListView ? 'primary' : 'inherit'}
                variant="contained"
                className={clsx('px-12 normal-case text-13 font-500', isListView && 'bg-paper')}
                onClick={handleListViewChange(false)}
              >
                <CalendarIcon className="text-20" />
              </Button>
            </Tooltip>

            <Tooltip title="List View">
              <Button
                disableElevation={true}
                color={isListView ? 'primary' : 'inherit'}
                variant="contained"
                className={clsx('px-12 normal-case text-13 font-500', !isListView && 'bg-paper')}
                onClick={handleListViewChange(true)}
              >
                <ListCheckIcon className="text-20" />
              </Button>
            </Tooltip>
          </ButtonGroup>

          {canViewUserCalendar && <div className="w-2 h-24 mr-12 border-gray-300 border-r-1" />}

          {/* personal or global view */}
          {canViewUserCalendar && (
            <div className="flex items-center">
              <ButtonGroup className="flex mr-12">
                <Tooltip title="Personal View">
                  <Button
                    disableElevation={true}
                    color={isMyList ? 'primary' : 'inherit'}
                    variant="contained"
                    className={clsx('px-12 normal-case text-13 font-500', !isMyList && 'bg-paper')}
                    onClick={handleMyListChange}
                  >
                    <UserIcon className="text-20" />
                  </Button>
                </Tooltip>

                <Tooltip title="Global View">
                  <Button
                    disableElevation={true}
                    color={!isMyList ? 'primary' : 'inherit'}
                    variant="contained"
                    className={clsx('px-12 normal-case text-13 font-500', isMyList && 'bg-paper')}
                    onClick={handleMyListChange}
                  >
                    <PeopleAltOutlinedIcon className="text-20" />
                  </Button>
                </Tooltip>
              </ButtonGroup>
              <Typography className="text-13 font-500 w-96">{isMyList ? 'Personal View' : 'Global View'}</Typography>
            </div>
          )}
        </div>

        {/*  and month year, day week view and reload button */}
        <div className="flex items-center justify-between flex-1 space-x-2">
          <div className="flex items-center">
            <div className="flex flex-row items-center mr-12">
              <Typography className="uppercase text-20 font-400">{month}</Typography>
              <Typography className="ml-8 uppercase text-20 font-600">{year}</Typography>
            </div>
            <IconButton onClick={onReload}>
              <ReplayIcon className="rotate-180 text-secondaryMain text-20" />
            </IconButton>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-4">
              {!isListView && SHOW_CALENDAR_WEEK_VIEW && (
                <>
                  <IconButton onClick={onPrevious}>
                    <ArrowBackIosIcon className="text-16 text-secondaryMain" />
                  </IconButton>

                  <Button
                    variant="contained"
                    className={clsx(
                      'h-24 px-12 capitalize text-11 font-500 rounded-4 shadow-0',
                      view !== 'timeGridDay' && 'bg-paper hover:bg-paper text-secondaryLight',
                    )}
                    onClick={onViewDay}
                  >
                    Day
                  </Button>

                  <Button
                    variant="contained"
                    className={clsx(
                      'h-24 px-12 capitalize text-11 font-500 rounded-4 shadow-0',
                      view !== 'timeGridWeek' && 'bg-paper hover:bg-paper text-secondaryLight',
                    )}
                    onClick={onViewWeek}
                  >
                    Week
                  </Button>

                  <IconButton onClick={onNext}>
                    <ArrowForwardIosIcon className="text-secondaryMain text-16" />
                  </IconButton>
                </>
              )}
            </div>

            {!isMyList && (
              <div className="flex items-center">
                <ButtonGroup className="flex mr-12">
                  <Tooltip title="Computer Time">
                    <Button
                      disableElevation={true}
                      color={timezoneSelector === TIMEZONE_SELECTOR_TYPES.COMPUTER ? 'primary' : 'inherit'}
                      variant="contained"
                      className={clsx(
                        'px-12 normal-case text-13 font-500',
                        timezoneSelector !== TIMEZONE_SELECTOR_TYPES.COMPUTER && 'bg-paper',
                      )}
                      onClick={handleChangeTimeType}
                    >
                      <ComputerIcon className="text-20" />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Location Time">
                    <Button
                      disableElevation={true}
                      color={timezoneSelector === TIMEZONE_SELECTOR_TYPES.LOCATION ? 'primary' : 'inherit'}
                      variant="contained"
                      className={clsx(
                        'px-12 normal-case text-13 font-500',
                        timezoneSelector !== TIMEZONE_SELECTOR_TYPES.LOCATION && 'bg-paper',
                      )}
                      onClick={handleChangeTimeType}
                    >
                      <LocationOnOutlinedIcon className="text-20" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
                <Typography className="text-13 font-500 w-128">
                  {timezoneSelector === TIMEZONE_SELECTOR_TYPES.COMPUTER ? 'Computer  Time' : 'Location Time'}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* filter */}
      <div className={clsx('flex items-center gap-16', classes.filter)}>
        {!isMyList && (
          <div className="w-216">
            <LocationSelect onChange={handleSelectLocation} />
          </div>
        )}

        <div className="w-168">
          {isMyList ? (
            <RoleSelect
              roleId={filter.roleId}
              onChange={(data: any) =>
                setFilter((currentFilter: any) => ({ ...currentFilter, roleId: data?.externalId }))
              }
            />
          ) : (
            <AdminRoleSelect
              locationId={filter.locationId}
              onChange={(data: any) =>
                setFilter((currentFilter: any) => ({ ...currentFilter, roleId: data?.externalId, userId: undefined }))
              }
            />
          )}
        </div>

        {!isMyList && canViewUserCalendar && (
          <div className="w-168">
            <EmployeeSelect
              locationId={filter.locationId}
              roleId={filter.roleId}
              onChange={(data: any) => setFilter((currentFilter: any) => ({ ...currentFilter, userId: data?.id }))}
            />
          </div>
        )}

        <div className="w-168">
          <StatusSelect
            status={filter.status}
            onChange={(data: any) => {
              setFilter((currentFilter: any) => ({ ...currentFilter, status: data?.value }));
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Toolbar;

// TODO update responsive
const useStyles = (isListView?: boolean, isMyList?: boolean) =>
  makeStyles(() => {
    let px = '';

    if (isListView) {
      px = isMyList ? '1279px' : '1865px';
    } else {
      px = isMyList ? '1479px' : '1887px';
    }

    const breakpoints = `@media (max-width: ${px})`;

    return {
      root: {
        [breakpoints]: {
          flexWrap: 'wrap',
        },
      },
      actions: {
        [breakpoints]: {
          marginBottom: 12,
        },
      },
      filter: {
        [breakpoints]: {
          width: '100%',
          alignItems: 'flex-start !important',
          gap: '16px',
        },
      },
    };
  });
