import ConfirmDialog from 'app/components/cores/confirm-dialog';
import DragItemWrapper from 'app/components/cores/drag-order/drag-item-wrapper';
import DragOrder from 'app/components/cores/drag-order/drag-order';
import useShowMessage from 'app/hooks/use-show-message';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import _ from 'lodash';
import { TItem, TLocation } from 'models';
import { useCallback, useMemo, useRef, useState } from 'react';
import { DraggableProvidedDragHandleProps, DropResult } from 'react-beautiful-dnd';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clsxm } from 'utils/clsxm';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import ItemDetail from './item-detail';
import ItemSettingDrawer from './item-setting-drawer';
import ItemSetting from './question-setting';

const drawerWidth = 420;

const Main = styled('main', { shouldForwardProp: (prop: any) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }: any) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

type Props = {
  name: string;
  items: TItem[];
  register: any;
  control: any;
  isPublished?: boolean;
  isDisabled?: boolean;
  locations?: TLocation[];
  onOrderChange?: (newOrder: DropResult) => void;
  onRemove?: (index: number) => void;
  onSave?: (options: any, onSuccess?: () => void) => void;
  onDuplicate?: (index: number) => void;
};

function EditorContainer({
  name,
  control,
  items,
  register,
  isPublished,
  isDisabled,
  locations,
  onOrderChange,
  onRemove,
  onSave,
  onDuplicate,
}: Props) {
  const [openLocationSetting, setOpenLocationSetting] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedName, setSelectedName] = useState<undefined | string>(undefined);
  const originalTaskItem = useRef<TItem | null>(null);

  const dispatch = useDispatch();
  const { showError } = useShowMessage();
  const { setValue } = useFormContext();
  const task = useWatch({ control });

  const conditionalItems = useMemo(
    () =>
      _.chain(items)
        .filter((item: TItem) => !!item.conditionalId)
        .map((item: TItem) => ({
          groupId: item.conditionalId,
          itemId: item.id,
          order: items.find((i: any) => i.id === item.conditionalId)?.order || 0,
        }))
        .value(),
    [items],
  );
  const conditionalIds = _.groupBy(conditionalItems, 'itemId');
  const conditionalGroup = _.groupBy(conditionalItems, 'groupId');

  const getTaskItem = useCallback((itemFieldName: string) => (!task ? {} : _.get(task, itemFieldName)), [task]);

  const isTaskItemChanged = useCallback(
    (isCompareAllFields: boolean = true) => {
      if (!selectedName) return false;

      const taskItem = getTaskItem(selectedName);
      if (!taskItem?.id) return !openLocationSetting;
      if (taskItem?.id !== originalTaskItem.current?.id) return false;

      const presentItem = isCompareAllFields ? taskItem : _.omit(taskItem, 'prompt');
      const originalItem = isCompareAllFields ? originalTaskItem.current : _.omit(originalTaskItem.current, 'prompt');
      return !_.isEqual(JSON.stringify(presentItem), JSON.stringify(originalItem)) && !openLocationSetting;
    },
    [selectedName, getTaskItem, openLocationSetting],
  );

  const resetTaskItem = () => {
    if (!selectedName || !originalTaskItem.current?.id) return;
    setValue(selectedName, originalTaskItem.current);
  };

  const handleCloseDrawer = (isResetTaskItem: boolean = true) => {
    if (isTaskItemChanged() && isResetTaskItem) {
      resetTaskItem();
    }

    setOpenDrawer(false);
    setSelectedName(undefined);
  };

  const handleOpenDrawer = (open: boolean, _name: string) => {
    const taskItem = getTaskItem(_name);
    if (taskItem?.id && taskItem?.id !== originalTaskItem.current?.id) {
      originalTaskItem.current = taskItem;
    }

    setOpenDrawer(open);
    setSelectedName(_name);
    setOpenLocationSetting(false);
  };

  const handleOrderChange = (newOrder: DropResult) => {
    handleCloseDrawer(false);
    onOrderChange?.(newOrder);
  };

  const handleRemove = (removeIndex: number) => {
    const removeItem = items?.[removeIndex];
    const isConditionalItem =
      !!removeItem?.id && !!_.find(items, (item: TItem) => !item._destroy && item.conditionalId === removeItem?.id);

    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Delete Confirmation"
            message="Are you sure you want to delete this task?"
            statusVariant="warning"
            confirmButtonLabel="Delete"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));

              if (isConditionalItem) {
                showError('Cannot delete task. This task is used as a conditional in another task');
              } else {
                handleCloseDrawer(false);
                onRemove?.(removeIndex);
              }
            }}
          />
        ),
      }),
    );
  };

  const closeLocationSetting = () => {
    setOpenLocationSetting(false);
    setSelectedName(undefined);
  };

  const handleDuplicate = (duplicateIndex: number) => {
    onDuplicate?.(duplicateIndex);
    closeLocationSetting();
  };

  const handleOpenLocationSetting = (_name: string) => {
    const isReselect = _name === selectedName;

    if (isReselect && openLocationSetting) {
      if (isPublished) {
        closeLocationSetting();
        return;
      }

      onSave?.({ hideSuccessMessage: true }, () => {
        closeLocationSetting();
      });
    } else {
      setOpenLocationSetting(!isReselect);
      setSelectedName(!isReselect ? _name : undefined);
      setOpenDrawer(false);
    }
  };

  const handleCloseLocationSetting = () => {
    if (isPublished) {
      closeLocationSetting();
      return;
    }

    onSave?.({ hideSuccessMessage: true }, () => {
      closeLocationSetting();
    });
  };

  const handleSaveTask = (options: any) => {
    if (isTaskItemChanged()) {
      onSave?.(options, () => {
        originalTaskItem.current = null;
      });
    }
    handleCloseDrawer(false);
  };

  if (items?.filter(x => !x._destroy).length <= 0) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Main
        className="p-0"
        open={openDrawer}
      >
        <div className="pt-8 layout-box">
          <DragOrder
            onDragEnd={handleOrderChange}
            isDropDisabled={isDisabled}
          >
            <>
              {items?.map((item: any, index: number) => {
                if (item._destroy) {
                  return null;
                }
                const { ref, ...itemProps } = register(`${name}.${index}` as const);
                const isFocus = selectedName === itemProps.name;
                const isOpenLocationSetting = selectedName === itemProps.name && openLocationSetting;
                return (
                  <DragItemWrapper
                    key={item.DOMId}
                    id={item.DOMId}
                    index={index}
                    isDragDisabled={isDisabled}
                    renderContent={(dragHandleProps?: DraggableProvidedDragHandleProps | null) => (
                      <ItemDetail
                        {...itemProps}
                        openDrawer={openDrawer}
                        setOpenDrawer={handleOpenDrawer}
                        isFocus={isFocus}
                        isDisabled={isDisabled}
                        isOpenLocationSetting={isOpenLocationSetting}
                        openLocationSetting={openLocationSetting}
                        dragHandleProps={dragHandleProps}
                        locations={locations}
                        onRemove={() => handleRemove(index)}
                        onDuplicate={() => handleDuplicate(index)}
                        onOpenLocationSetting={handleOpenLocationSetting}
                        onCloseLocationSetting={handleCloseLocationSetting}
                        conditionalGroup={conditionalGroup[item.id]?.[0]?.order}
                        conditionalId={conditionalIds[item.id]?.[0]?.order}
                        control={control}
                      />
                    )}
                  />
                );
              })}
            </>
          </DragOrder>
        </div>
        <div className={clsxm(isTaskItemChanged(false) && 'fixed inset-0 bg-secondaryLight bg-opacity-25 z-999')} />
      </Main>

      <ItemSettingDrawer
        open={openDrawer}
        onToggle={setOpenDrawer}
      >
        <ItemSetting
          isDisabled={isDisabled}
          control={control}
          name={selectedName || ''}
          itemsAttributesName={name}
          onClose={() => handleCloseDrawer()}
          onSave={handleSaveTask}
          key={selectedName}
        />
      </ItemSettingDrawer>
    </Box>
  );
}

export default EditorContainer;
