import { NumericInput } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import _isRange from 'lodash/inRange';
import _isNumber from 'lodash/isNumber';
import { TItem, TMeasurementType, TQuestionLayoutView } from 'models';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { toNumber } from 'utils/number';

type Props = {
  data: TItem & { unit: string; measurement: TMeasurementType };
  readOnly?: boolean;
  name: string;
  listId: number;
  view: TQuestionLayoutView;
};

const Numeric = ({ data, readOnly, name, listId, view }: Props) => {
  const { resetField, setError, setValue, clearErrors } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const isRequired = value?.item?.required;
  const answerNumber = toNumber(value?.answer);
  const minNumber = toNumber(value?.item?.minNumber);
  const maxNumber = toNumber(value?.item?.maxNumber);
  const minLabel = value?.item?.minLabel;
  const maxLabel = value?.item?.maxLabel;

  const { mutateAsync: answerTask, isLoading } = useEditHistoryTask({
    taskId: listId,
    responseId: value?.id,
  });
  const { showError, showSuccess } = useShowMessage();

  useEffect(() => {
    if (isRequired) {
      if (
        (_isNumber(answerNumber) && !Number.isNaN(answerNumber)) ||
        _isRange(Number(answerNumber), Number(minNumber), Number(maxNumber))
      ) {
        clearErrors(name);
      }
    }
  }, [answerNumber, minNumber, maxNumber, isRequired, clearErrors, name]);

  const answer = async (payload: any) => {
    try {
      const res = await answerTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  const handleAnswerChange = (nextAnswer?: number) => {
    onChange({ ...value, answer: nextAnswer });
  };

  const handleAnswer = (nextAnswer?: number) => {
    updateFormAndAnswer(nextAnswer);
  };

  const handleEnter = (nextAnswer?: number) => {
    updateFormAndAnswer(nextAnswer);
  };

  const updateFormAndAnswer = (nextAnswer?: any) => {
    onChange({ ...value, answer: nextAnswer });
  };

  const checkAnswerIsNotNumber = () => {
    return !_isNumber(answerNumber) || Number.isNaN(answerNumber);
  };

  const checkAnswerIsLessThanMin = () => {
    return _isNumber(minNumber) && Number(answerNumber) < minNumber;
  };

  const checkAnswerIsGreaterThanMax = () => {
    return _isNumber(maxNumber) && Number(answerNumber) > maxNumber;
  };

  const checkAnswerIsValid = () => {
    if (isRequired) {
      if (checkAnswerIsNotNumber()) {
        setError(name, { type: 'manual', message: 'This field is required' });
        return false;
      }
      if (checkAnswerIsLessThanMin()) {
        setError(name, { type: 'manual', message: minLabel || `The minimum value is ${minNumber}` });
        return false;
      }
      if (checkAnswerIsGreaterThanMax()) {
        setError(name, { type: 'manual', message: maxLabel || `The maximum value is ${maxNumber}` });
        return false;
      }
    }
    return true;
  };

  const handleEdit = () => {
    if (!checkAnswerIsValid()) return;

    const additionalPayload = { comment: value.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);

    answer(answerPayload);
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview={true}
      task={value}
      name={name}
      onSaveEdit={handleEdit}
      isLoading={isLoading}
      view={view}
    >
      <NumericInput
        disabled={readOnly}
        unit={data?.unit}
        measurement={data?.measurement}
        value={value?.answer}
        status={readOnly ? value?.status : 'editing'}
        onChange={handleAnswerChange}
        onAnswer={handleAnswer}
        onEnter={handleEnter}
      />
    </QuestionLayoutMapper>
  );
};
export default Numeric;
