import { SingleSelect } from 'app/components/cores/select';
import { getConditionalItems } from 'domains/item.domain';
import { TItem } from 'models';
import { useMemo } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ConditionDisplaySwitch } from './display';

type Props = {
  isDisabled?: boolean;
  control?: Control;
  name: string;
  itemsAttributesName: string;
  isExpanded?: boolean;
  onPanelChange?: (nextExpandedPanel: 'conditional-task' | 'notify' | 'conditional-list') => void;
};

function ConditionalLogicContainer({
  isDisabled,
  control,
  name,
  itemsAttributesName,
  isExpanded,
  onPanelChange,
}: Props) {
  const classes = useStyles();

  const {
    field: { value, onChange },
  } = useController({ control, name: `${name}` });

  const currentItem = useWatch({ control, name: `${name}` });
  const itemsAttributes = useWatch({ control, name: itemsAttributesName });

  const conditionalItems = useMemo(
    () => getConditionalItems(itemsAttributes, currentItem),
    [itemsAttributes, currentItem],
  );

  const selectedConditionalItem = useMemo(() => {
    return conditionalItems?.find((item: TItem) => !!item.id && item.id === value?.conditionalId) || null;
  }, [conditionalItems, value]);

  const isDisabledConditional = !conditionalItems?.length;

  const handleConditionalItemChange = (item: any) => {
    onChange({
      ...value,
      conditionalId: item.id,
      conditionalType: item.type,
      conditionalComparison: [],
    });
  };

  const handleClearConditional = () => {
    onChange?.({
      ...value,
      conditionalId: null,
      conditionalType: null,
      conditionalComparison: [],
    });
  };

  return (
    <Accordion
      disabled={isDisabledConditional}
      className="bg-white shadow-0"
      classes={{ root: classes.accordionRoot, disabled: classes.accordionRootDisabled }}
      disableGutters={true}
      expanded={isExpanded}
      onChange={() => onPanelChange?.('conditional-task')}
    >
      <AccordionSummary
        classes={{
          root: 'min-h-40 px-24',
          content: 'my-4',
          disabled: classes.accordionSummaryDisabled,
        }}
        expandIcon={
          isExpanded ? (
            <CloseIcon
              color="primary"
              fontSize="small"
            />
          ) : (
            <AddIcon
              color="primary"
              fontSize="small"
            />
          )
        }
      >
        <Typography
          color="primary"
          className="text-12 font-600"
        >
          Add Conditional Logic - Task (optional)
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: 'px-24 relative' }}>
        <Typography className="text-13 font-400 text-secondaryLight">
          Add a conditional task item and specify the criteria that determine when this task item will be presented.
        </Typography>
        <Typography className="mt-4 italic text-10 font-400 text-secondaryLight">
          Only tasks that complete the configuration will be visible in the list.
        </Typography>

        <div className="my-16">
          <SingleSelect
            placeholder="Task Name"
            value={selectedConditionalItem}
            options={conditionalItems}
            getOptionLabel={(option: any) => option.prompt}
            getOptionValue={(option: any) => option.id}
            menuPlacement="top"
            onChange={handleConditionalItemChange}
          />
        </div>

        {!!selectedConditionalItem && (
          <ConditionDisplaySwitch
            control={control}
            name={name}
            selectedConditionalItem={selectedConditionalItem}
            onClearConditional={handleClearConditional}
          />
        )}
        {isDisabled && <div className="absolute inset-0" />}
      </AccordionDetails>
    </Accordion>
  );
}

export default ConditionalLogicContainer;

const useStyles = makeStyles(() => {
  return {
    accordionRoot: {
      boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15) !important',
      borderRadius: '16px 16px 0px 0px !important',
      '&:before': {
        display: 'none',
      },
    },
    accordionRootDisabled: {
      backgroundColor: 'white !important',
      '& *': {
        color: '#C8CBD2',
      },
    },
    accordionSummaryDisabled: {
      opacity: '1 !important',
    },
  };
});
