import { REPORT_TABS } from 'constants/tabs';
import { isAdmin } from 'utils/role';

export const tabGenerator = (roles: string[], permissions: any) => {
  const filteredTabs = [];

  // Employee Submission Tab Permission
  const employeeSubmissionTabNotAvailable = !permissions?.managerReports && !isAdmin(roles);
  if (!employeeSubmissionTabNotAvailable) {
    filteredTabs.push(REPORT_TABS[0]);
  }

  // Report Export Tab Permission
  const exportReportTabNotAvailable = !permissions?.managerGridReports && !isAdmin(roles);
  if (!exportReportTabNotAvailable) {
    filteredTabs.push(REPORT_TABS[1]);
  }

  // Performance Report Tab Permission
  const performanceReportTabNotAvailable = !isAdmin(roles);
  if (!performanceReportTabNotAvailable) {
    filteredTabs.push(REPORT_TABS[2]);
  }

  return filteredTabs.map((tab, index) => ({ ...tab, value: index }));
};
