import { getStatusNameByStatus } from 'domains/task.domain';
import _findLast from 'lodash/findLast';
import _isEmpty from 'lodash/isEmpty';
import { TItem, TResponse } from 'models';
import moment from 'moment';
import { PropsWithChildren } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ErrorMessage } from '@hookform/error-message';
import CachedIcon from '@mui/icons-material/Cached';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import AttachmentViewer from './attachment-viewer';
import TaskComment from './task-comment';

type Props = {
  question: TItem;
  name: string;
  preview: boolean;
  task?: Omit<TResponse, 'item'>;
  canReset?: boolean;
  onSaveEdit?: () => void;
  onCancelEdit?: () => void;
  rightActions?: React.ReactNode;
  isLoading?: boolean;
  onReset?: () => void;
};

const HistoryQuestionLayout = ({
  question,
  children,
  preview,
  task,
  canReset = false,
  name,
  onSaveEdit,
  onCancelEdit,
  rightActions,
  isLoading,
  onReset,
}: PropsWithChildren<Props>) => {
  const { prompt, description } = question;
  const { status, user, comment = '', type, actions, submittedAt } = task || {};

  const statusName = getStatusNameByStatus(status);
  // find last action has action = 'change_answer'
  const lastEditAction = _findLast(actions, { action: 'change_answer' });

  const editingName = `${name}-isEditing`;
  const isCompleted = ['completed', 'overdue'].includes(status ?? '');
  const permissions = useSelector(({ auth }: any) => auth?.user?.permissions);
  const permissionToEdit = permissions.reports && permissions['reports.editSubmitted'];

  const isCode = ['Responses::BarCode', 'Responses::QrCode'].includes(type ?? '');

  const { setValue, resetField, getValues } = useFormContext();
  const isEditingValue = useWatch({ name: editingName });
  const location = getValues('location');

  const attachments = question.attachmentsAttributes || [];

  const handleChangeToEditable = () => {
    setValue(editingName, true);
  };

  const handleChangeToNotEditable = () => {
    resetField(name);
    setValue(editingName, false);
    onCancelEdit?.();
  };

  const handleSaveEdit = () => {
    onSaveEdit?.();
  };

  return (
    <div>
      {/* Start of prompt */}
      <div className="flex flex-row items-center justify-between gap-16 pb-4">
        {prompt && <Typography className="font-bold text-13">{prompt}</Typography>}

        {isCompleted && permissionToEdit ? (
          <div className="flex space-x-8 min-h-40 print:hidden">
            {isEditingValue ? (
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  className="h-32 capitalize shadow-0 text-12 font-500 text-secondaryMain"
                  onClick={handleChangeToNotEditable}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="h-32 capitalize border-0 shadow-0 text-12 font-500"
                  onClick={handleSaveEdit}
                  disabled={isLoading}
                >
                  Save
                </Button>
              </>
            ) : (
              <IconButton
                className="w-40 h-40 bg-paper hover:bg-paper"
                onClick={handleChangeToEditable}
              >
                <EditOutlinedIcon
                  className="font-12"
                  fontSize="small"
                  color="primary"
                />
              </IconButton>
            )}
          </div>
        ) : null}
      </div>
      {/* End of prompt */}

      {/* Start of description */}
      {description && <Typography className="text-11">{description}</Typography>}
      {/* End of description */}

      {/* attachments */}
      {attachments && attachments?.length > 0 && <AttachmentViewer attachments={attachments} />}
      {/* end of attachments */}

      {/* start of answer helper text */}
      <div className="flex items-center justify-between">
        <Typography className="text-secondaryLight text-11 font-400">
          Your Answer/Action {question.required && '*'}
        </Typography>

        <div className="flex items-center justify-between">
          {rightActions}

          {canReset && (
            <IconButton
              size="small"
              onClick={onReset}
            >
              <CachedIcon className="text-20 text-secondaryMain" />
            </IconButton>
          )}
        </div>
      </div>
      {/* end of answer helper text */}

      <div className="my-8">
        <ErrorMessage
          name={name}
          render={({ message }) => <Typography className="text-11 text-errorMain">{message}</Typography>}
        />
      </div>

      {/* Start of answer */}
      <div className="mt-8">{children}</div>
      {/* End of answer */}

      {/* Start of Status */}

      <div>
        <Box
          sx={{ mt: '8px' }}
          className="flex flex-wrap items-center justify-between gap-2"
        >
          <div>
            {status && (
              <Typography
                className="font-bold capitalize text-secondaryLight text-11"
                component="span"
              >
                {statusName}
              </Typography>
            )}
            {!_isEmpty(user) && (
              <>
                <Typography
                  className="font-bold text-secondaryLight text-11"
                  component="span"
                >
                  {' '}
                  | {user?.fullName} :
                </Typography>
                <Typography
                  className="text-secondaryLight text-11"
                  component="span"
                >
                  {!!location && ` ${location?.name},`}{' '}
                  {submittedAt && moment(submittedAt).format('MMM DD, YYYY @ hh:mm:ss.SSSA')}
                </Typography>
              </>
            )}
          </div>

          {!!lastEditAction && (
            <Typography className="font-bold text-secondaryLight text-11">
              Last Edited | {lastEditAction?.user?.fullName} :{' '}
              <Typography
                className="text-secondaryLight text-11"
                component="span"
              >
                {lastEditAction?.actionAt && moment(lastEditAction?.actionAt).format('MMM DD, YYYY @ hh:mmA')}
              </Typography>
            </Typography>
          )}
        </Box>
      </div>

      {/* End of status */}
      {/* Start of Comment */}
      {!isCode && (
        <>
          <div>
            <Divider
              sx={{
                marginTop: '16px !important',
                borderStyle: 'dashed',
              }}
            />
          </div>
          <div>
            <div className="flex justify-between space-x-16">
              <CommentOutlinedIcon sx={{ color: 'secondary.light', mt: '4px' }} />
              <TaskComment
                comment={comment}
                preview={preview}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Comment */}
    </div>
  );
};

export default HistoryQuestionLayout;
