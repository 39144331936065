import clsx from 'clsx';
import { TItem } from 'models';
import { Control } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from '@mui/styles';

import { NotifyDisplaySwitch } from './display';

type Props = {
  isDisabled?: boolean;
  control?: Control;
  name: string;
  isExpanded?: boolean;
  selectedItem: TItem;
  onPanelChange?: (nextExpandedPanel: 'conditional-task' | 'notify' | 'conditional-list') => void;
};

function NotifyLogicContainer({ isDisabled, control, name, isExpanded, selectedItem, onPanelChange }: Props) {
  const classes = useStyles();

  return (
    <Accordion
      className="bg-white shadow-0"
      classes={{ root: clsx(classes.accordionRoot) }}
      disableGutters={true}
      expanded={isExpanded}
      onChange={() => onPanelChange?.('notify')}
    >
      <AccordionSummary
        classes={{
          root: clsx('min-h-40 px-24', isExpanded && classes.notifySummaryRoot),
          content: 'my-4',
        }}
        expandIcon={
          isExpanded ? (
            <CloseIcon
              color="primary"
              fontSize="small"
            />
          ) : (
            <AddIcon
              color="primary"
              fontSize="small"
            />
          )
        }
      >
        <Typography
          color="primary"
          className="text-12 font-600"
        >
          Add Notify Logic (optional)
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: 'px-24 relative' }}>
        <Typography className="mb-16 text-13 font-400 text-secondaryLight">
          You can set up notifications by specifying the notification recipient(s) and selecting the responses that will
          trigger the notifications.
        </Typography>

        <NotifyDisplaySwitch
          control={control}
          name={name}
          selectedConditionalItem={selectedItem}
        />
        {isDisabled && <div className="absolute inset-0" />}
      </AccordionDetails>
    </Accordion>
  );
}

export default NotifyLogicContainer;

const useStyles = makeStyles(() => {
  return {
    accordionRoot: {
      '&:before': {
        display: 'none',
      },
    },
    notifySummaryRoot: {
      boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.1) !important',
      borderRadius: '16px 16px 0px 0px !important',
    },
  };
});
