import { Paths } from 'constants/paths';

export const adminNavigationConfig = [
  {
    id: 'performance',
    title: 'Performance',
    translate: 'Performance',
    type: 'item',
    icon: 'room-outlined',
    url: Paths.performance,
    exact: true,
  },
  {
    id: 'facility_tasks',
    title: 'Facility Tasks',
    translate: 'Facility Tasks',
    type: 'item',
    icon: 'dashboard',
    url: Paths.facilityTask,
    exact: true,
  },
  {
    id: 'reports',
    title: 'Reports',
    translate: 'Reports',
    type: 'item',
    icon: 'poll',
    url: `${Paths.history}/lists`,
    exact: true,
  },
  {
    id: 'location',
    title: 'Locations',
    translate: 'Locations',
    type: 'item',
    icon: 'room-outlined',
    url: Paths.location,
    exact: true,
  },
  {
    id: 'list',
    title: 'Lists',
    translate: 'Lists',
    type: 'item',
    icon: 'dashboard',
    url: Paths.list,
    exact: true,
    permissions: ['task'],
  },
  {
    id: 'tags',
    title: 'Tags',
    translate: 'Tags',
    type: 'item',
    icon: 'local_offer_outlined',
    url: Paths.tag,
    exact: true,
    permissions: ['tag'],
  },
];

export const userNavigationConfig = [
  {
    id: 'my_tasks',
    title: 'My Tasks',
    translate: 'My Tasks',
    type: 'item',
    icon: 'dashboard',
    url: Paths.myTask,
    exact: true,
  },
  {
    id: 'calendar',
    title: 'Calendar',
    translate: 'Calendar',
    type: 'item',
    icon: 'calendar_today',
    url: Paths.calendar,
    exact: true,
  },
];

export const loginNavigationConfig = [
  {
    id: 'login',
    title: 'Login',
    type: 'login',
    children: [
      {
        id: 'profile',
        title: 'Profile',
        translate: 'Profile',
        type: 'item',
        url: '/profile',
      },
      {
        id: 'logout',
        title: 'Logout',
        translate: 'Logout',
        type: 'item',
        url: '/users/sign_out',
      },
    ],
  },
];

export const adminLoginNavigationConfig = [
  {
    id: 'login',
    title: 'Login',
    type: 'login',
    children: [
      {
        id: 'profile',
        title: 'Profile',
        translate: 'Profile',
        type: 'item',
        url: '/profile',
        isExternalUrl: true,
      },
      {
        id: 'logout',
        title: 'Logout',
        translate: 'Logout',
        type: 'item',
        url: '/logout',
      },
    ],
  },
];

export const managerNavigationConfig = [
  {
    id: 'performance',
    title: 'Performance',
    translate: 'Performance',
    type: 'item',
    icon: 'room-outlined',
    url: Paths.performance,
    exact: true,
    permissions: ['managerLocations'],
  },
  {
    id: 'facility_tasks',
    title: 'Facility Tasks',
    translate: 'Facility Tasks',
    type: 'item',
    icon: 'dashboard',
    url: Paths.facilityTask,
    exact: true,
    permissions: ['managerFacilityTask'],
  },
  {
    id: 'reports',
    title: 'Reports',
    translate: 'Reports',
    type: 'item',
    icon: 'poll',
    url: `${Paths.history}/lists`,
    exact: true,
    permissions: ['managerReports', 'managerGridReports'],
  },
];
