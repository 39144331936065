import { SelectBox } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll';
import { useGetEmployees } from 'app/hooks/api/employees';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useSelect from 'app/hooks/use-select';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import _isEmpty from 'lodash/isEmpty';
import { TItem, TQuestionLayoutView } from 'models';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { useTheme } from '@mui/material';

type Props = {
  data: TItem;
  name: string;
  listId: number;
  readOnly?: boolean;
  view: TQuestionLayoutView;
};

const Employee = ({ data, name, listId, readOnly, view }: Props) => {
  const theme = useTheme();
  const { resetField, setValue, setError, clearErrors, getValues } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const { mutateAsync: answerTask, isLoading } = useEditHistoryTask({ taskId: listId, responseId: value?.id });

  const location = getValues('location');

  const { showError, showSuccess } = useShowMessage();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);

  const { data: employeeData, isLoading: isGettingEmployees } = useGetEmployees({
    page: pageIndex,
    per_page: pageSize,
    'q[full_name_cont]': searchText,
    'q[location_id_eq]': location?.externalId,
  });
  const isValidAnswer = Array.isArray(value?.answer) && value?.answer.length > 0;

  const {
    options: employeeOptions,
    handleSearch,
    handleLoadMore,
  } = useSelect({
    data: employeeData?.records,
    // isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const handleChange = (values: any) => {
    const nextAnswer = _isEmpty(values) ? [] : values;
    onChange({ ...value, answer: nextAnswer });
  };

  const handleSave = async () => {
    if (!checkAnswerIsValid()) return;
    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  useEffect(() => {
    if (value?.item?.required && isValidAnswer) clearErrors(name);
  }, [isValidAnswer, value?.item?.required, clearErrors, name]);

  const checkAnswerIsValid = () => {
    if (value?.item?.required && !isValidAnswer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const answer = async (payload: any) => {
    try {
      const res = await answerTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview
      task={value}
      name={name}
      onSaveEdit={handleSave}
      isLoading={isLoading}
      view={view}
    >
      {!readOnly ? (
        <SelectInfiniteScroll
          value={value?.answer || []}
          onChange={handleChange}
          isMulti={true}
          isLoading={isGettingEmployees}
          isClearable={true}
          defaultOptions={true}
          className="w-full"
          name={name}
          placeholder="Select employee"
          styles={{
            indicatorsContainer: (_props: any) => ({
              ..._props,
              marginTop: 7,
            }),
            multiValue: (_props: any) => ({
              ..._props,
              backgroundColor: theme.palette.primary.main,
              padding: 4,
            }),
          }}
          options={employeeOptions ?? []}
          onInputChange={handleSearch}
          getOptionValue={(option: any) => option.id}
          getOptionLabel={(option: any) => option.name}
          onMenuScrollToBottom={handleLoadMore}
        />
      ) : (
        <SelectBox
          modalTitle="Employees"
          placeholder="Select Employees"
          disabled={true}
          options={[]}
          value={value?.answer}
        />
      )}
    </QuestionLayoutMapper>
  );
};

export default Employee;
