import { ControlledTextInput } from 'app/components/cores/inputs/text';
import useQRCode from 'app/hooks/use-qr-code';
import { Control, useController, useWatch } from 'react-hook-form';
import QRCode from 'react-qr-code';

import { Button, Typography } from '@mui/material';

type Props = {
  control?: Control;
  name: string;
};

const QRCodeOption = ({ name, control }: Props) => {
  const code = useWatch({ control, name: `${name}.code` }) || '';

  const { printAreaRef, printCode, generateCode, downloadCode } = useQRCode({
    downloadElement: 'qrcode',
    downloadFileName: code,
  });

  const {
    field: { onChange },
  } = useController({
    control,
    name: `${name}.code`,
  });

  const handleGenerateCode = () => {
    onChange?.(generateCode(20));
  };

  return (
    <div className="flex flex-col pb-16 space-y-8">
      <Typography className="text-11 font-600">QR Code Options</Typography>
      <div className="flex flex-col justify-between mt-8 space-y-8">
        <Typography className="text-11 text-secondaryMain font-500">Generate Code</Typography>
        <div className="flex items-start w-full space-x-8">
          <div className="flex-1">
            <ControlledTextInput
              name={`${name}.code`}
              control={control}
              variant="filled"
              className="h-full shadow"
              InputProps={{
                className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
                disableUnderline: true,
                classes: {
                  input: 'p-8 text-11',
                },
              }}
            />
          </div>
          <Button
            disableElevation={true}
            color="primary"
            variant="contained"
            className="h-24 px-12 mt-4 capitalize text-11 font-500 rounded-4"
            onClick={handleGenerateCode}
          >
            Generate Code
          </Button>
        </div>

        <div className="w-full">
          <Typography className="text-11 text-secondaryMain font-500">Manual Input Code</Typography>
          <ControlledTextInput
            name={`${name}.manualCode`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-11',
              },
            }}
          />
        </div>

        <div ref={printAreaRef}>
          <div
            style={{ height: 'auto', margin: '0 auto', maxWidth: 128, width: '100%' }}
            className="pt-16 pb-8"
          >
            <QRCode
              id="qrcode"
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={code}
              viewBox="0 0 256 256"
            />
          </div>
          <Typography className="text-center text-13 font-500">{code}</Typography>
        </div>

        <div className="flex items-center justify-end pt-16 space-x-8">
          <Button
            disableElevation={true}
            color="primary"
            variant="outlined"
            className="h-24 px-12 capitalize w-88 min-w-88 text-11 font-500 rounded-4"
            onClick={downloadCode}
          >
            Download
          </Button>
          <Button
            disableElevation={true}
            color="primary"
            variant="contained"
            className="h-24 px-12 capitalize w-88 min-w-88 text-11 font-500 rounded-4"
            onClick={printCode}
          >
            Print
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeOption;
