import ErrorMessageText from 'app/components/cores/form/error-message';
import { ControlledAttributesSelectInfiniteScroll } from 'app/components/cores/select-infinite-scroll';
import { useGetRoles } from 'app/hooks/api/roles';
import useSelect from 'app/hooks/use-select';
import { useMemo, useState } from 'react';
import { Control } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  control: {
    minHeight: '48px !important',
  },
  text: {
    fontSize: '1.3rem !important',
    color: theme.palette.secondary.dark,
  },
}));

type Props = {
  name: string;
  control?: Control;
  placeholder?: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onChange?: (value: any) => void;
  disabled?: boolean;
  getOptionValue?: (option: any) => any;
  getOptionLabel?: (option: any) => any;
  enabled?: boolean;
};

function RoleSelection({
  name,
  control,
  placeholder,
  menuPlacement,
  onChange,
  disabled,
  getOptionLabel,
  getOptionValue,
  enabled = true,
}: Props) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);

  const { data, isLoading } = useGetRoles(
    {
      page: pageIndex,
      per_page: pageSize,
      'q[name_cont]': searchText,
    },
    { enabled },
  );

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const formattedOptions = useMemo(
    () => options?.map((option: any) => ({ orgRoleName: option.name, orgRoleId: option.externalId })),
    [options],
  );

  return (
    <div className="p-4">
      <ControlledAttributesSelectInfiniteScroll
        isLoading={isLoading}
        isClearable={true}
        control={control}
        defaultOptions={true}
        className="w-full"
        name={name}
        closeMenuOnSelect={false}
        placeholder={placeholder || 'Select Role'}
        SelectProps={{
          classes: {
            control: classes.control,
            text: classes.text,
            placeholder: classes.text,
          },
        }}
        styles={{
          indicatorsContainer: (_props: any) => ({
            ..._props,
            marginTop: 7,
          }),
          ...(disabled && {
            multiValue: (_props: any) => ({
              ..._props,
            }),
          }),
        }}
        existingOptions={(adding: any, option: any) => adding.orgRoleId === option.orgRoleId}
        options={formattedOptions || []}
        onInputChange={handleSearch}
        getOptionValue={(option: any) => getOptionValue?.(option) ?? option.orgRoleId}
        getOptionLabel={(option: any) => getOptionLabel?.(option) ?? option.orgRoleName}
        onMenuScrollToBottom={handleLoadMore}
        menuPlacement={menuPlacement}
        onChange={onChange}
        isDisabled={disabled}
      />
      <ErrorMessage
        name={name.replace('orgRoles', 'orgRoleIds')}
        render={({ message }) => <ErrorMessageText message={message} />}
      />
    </div>
  );
}

export default RoleSelection;
