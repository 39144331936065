import { TResponsePagination, TTaskBasic } from 'models';

import { useGetList } from '../request';

const taskURL = '/api/admin/tasks';
// const managerTaskURL = '/api/manager/tasks';

export const managerTaskKeys = {
  all: ['manager-tasks'] as const,
  lists: () => [...managerTaskKeys.all, 'list'] as const,
  list: (filters: any) => [...managerTaskKeys.lists(), { filters }] as const,
  details: () => [...managerTaskKeys.all, 'detail'] as const,
  detail: (id: number) => [...managerTaskKeys.details(), id] as const,
};

// TODO use managerTaskURL instead of
export function useGetTasks(filters: any, options?: any) {
  return useGetList<TResponsePagination<TTaskBasic>>([...managerTaskKeys.list(filters)], `${taskURL}`, filters, {
    refetchOnMount: true,
    ...options,
  });
}
