import { ControlledTextInput } from 'app/components/cores/inputs/text';
import FileUploadField from 'app/components/cores/items-editor/file-upload-field';
import ControlledCreatableChipSelect from 'app/components/cores/select/controlled-creatable-chip-select';
import AttributeContainer from 'app/components/lists/attribute-container';
import { useCreateTag, useGetInfiniteTags } from 'app/hooks/api/tags';
import { ReactNode } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import Typography from '@mui/material/Typography';

import QRCodeField from './qr-code';

const filters = {
  per_page: 20,
};

function TaskInfo() {
  const { control, getValues, setValue } = useFormContext<FieldValues>();
  const { tags, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetInfiniteTags(filters);
  const { mutate: createTag, isLoading: isCreatingTag } = useCreateTag();

  const code = getValues('code');

  const handleCreateNewTaskTag = (inputValue: string) => {
    createTag(
      { tag: { name: inputValue } },
      {
        onSuccess(data) {
          setValue('taskTagsAttributes', [...getValues('taskTagsAttributes'), { tagId: data.id, tagName: data.name }]);
        },
      },
    );
  };
  return (
    <div className="p-24 space-y-16 layout-box">
      <div className="flex flex-row justify-between">
        <Typography
          color="primary"
          className="mb-8 uppercase text-15 font-500"
        >
          List settings
        </Typography>
      </div>

      <div className="space-y-16">
        {code && (
          <AttributeContainer label="Code">
            <div className="flex flex-row items-center">
              <ControlledTextInput
                disabled
                name="code"
                control={control}
                variant="filled"
                className="h-full shadow"
                placeholder="Add a code here"
                InputProps={{
                  className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-4 rounded-8',
                  disableUnderline: true,
                  classes: {
                    input: 'p-4 text-13 font-700 text-center',
                  },
                }}
              />
            </div>
          </AttributeContainer>
        )}

        <AttributeContainer label="List Title *">
          <ControlledTextInput
            name="name"
            control={control}
            variant="filled"
            className="h-full shadow"
            placeholder="Add a title here"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-13',
              },
            }}
          />
        </AttributeContainer>

        <AttributeContainer label="List Description">
          <ControlledTextInput
            name="description"
            placeholder="Add description here"
            control={control}
            variant="filled"
            className="h-full shadow"
            multiline={true}
            minRows={3}
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-13',
              },
            }}
          />
        </AttributeContainer>

        <AttributeContainer label="Add Attachment">
          <FileUploadField
            key="answerUpload"
            name="attachmentsAttributes"
            acceptFiles={{
              'application/pdf': ['.pdf'],
              'image/png': ['.png'],
            }}
            isPublic={false}
          />
        </AttributeContainer>
        <AttributeContainer label="QR Code">
          <QRCodeField
            control={control}
            name="scannableCodeAttributes"
          />
        </AttributeContainer>

        <AttributeContainer label="Tags">
          <ControlledCreatableChipSelect
            menuPlacement="top"
            options={tags}
            name="taskTagsAttributes"
            control={control}
            getOptionLabel={(option: any) => option.tagName}
            getOptionValue={(option: any) => option.tagId}
            onMenuScrollToBottom={hasNextPage ? () => fetchNextPage() : undefined}
            isLoading={isFetchingNextPage || isCreatingTag}
            maxMenuHeight={200}
            onCreateOption={handleCreateNewTaskTag}
            // to fix the issue of react-select: dont show create option
            getNewOptionData={(value: string, label: ReactNode) => ({
              tagId: value,
              tagName: label,
              __isNew__: true,
            })}
          />
        </AttributeContainer>
      </div>
    </div>
  );
}

export default TaskInfo;
