import _filter from 'lodash/filter';
import _some from 'lodash/some';
import pluralize from 'pluralize';
import { useFormContext } from 'react-hook-form';

import Typography from '@mui/material/Typography';

import TaskQuestions from './list-tasks/layout';

function ListTask() {
  const methods = useFormContext();

  const list = methods.getValues();

  // in each response will have actions, and each action will have action, filter by change_answer
  const editedItems = _filter(list?.responses, response =>
    _some(response?.actions, action => action?.action === 'change_answer'),
  );

  return (
    <div className="p-24 space-y-16 layout-box print:shadow-0">
      <div className="flex justify-between">
        <Typography className="mb-8 uppercase text-15 font-500">Tasks</Typography>
        {editedItems?.length > 0 && (
          <Typography className="mb-8 text-11 text-secondaryLight font-400">
            This list has edited {pluralize('items', editedItems.length)}
          </Typography>
        )}
      </div>
      <TaskQuestions
        responses={list?.responses}
        location={list?.location?.name}
      />
    </div>
  );
}

export default ListTask;
