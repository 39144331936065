import { NumericInput, QuestionLayout } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import SendIcon from '@mui/icons-material/Send';
import { IconButton, useTheme } from '@mui/material';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  listStatus?: TTaskStatus;
  responseId: number;
  response?: TEmployeeTaskResponse;
};

function NumericResponse({ name, readOnly, listId, responseId, listStatus, response }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();
  const theme = useTheme();
  const [originalAnswer, setOriginalAnswer] = useState<number | undefined>();
  const hasAnswer = !!response?.answer;

  const iconColor = useMemo(() => {
    if (hasAnswer) return 'common.white';
    return listStatus === 'overdue' ? 'overdue.main' : theme.palette.primary.main;
  }, [hasAnswer, listStatus, theme]);

  const isAnswerChanged = useMemo(() => {
    return originalAnswer !== undefined && Number(value?.answer) !== originalAnswer;
  }, [value, originalAnswer]);

  useEffect(() => {
    if (!response) return;
    setOriginalAnswer(Number(response?.answer));
  }, [response]);

  const handleAnswerChange = (nextAnswer?: number) => {
    onChange({ ...value, answer: nextAnswer });
  };

  const handleAnswer = (nextAnswer?: number) => {
    updateFormAndAnswer(nextAnswer);
  };

  const handleEnter = (nextAnswer?: number) => {
    updateFormAndAnswer(nextAnswer);
  };

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value.comment,
    };
    const answerPayload = generateTaskAnswerPayload(value.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value.comment,
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value.answer, additionalPayload);
    answer(answerPayload);
  };

  const updateFormAndAnswer = (nextAnswer?: any) => {
    onChange({ ...value, answer: nextAnswer });
    const additionalPayload = {
      comment: value.comment,
    };
    const answerPayload = generateTaskAnswerPayload(nextAnswer, additionalPayload);
    answer(answerPayload);
  };

  const handleSendAnswer = () => {
    const nextAnswer = value?.answer;
    updateFormAndAnswer(nextAnswer);
  };

  const answer = (payload: any) => {
    answerTask(payload)
      .then(res => {
        onChange({ ...value, ...res });
        setOriginalAnswer(res?.answer);
        showSuccess('Your answer has been submitted successfully');
      })
      .catch(() => {
        showError('Could not submit your answer');
      });
  };

  const getAnswer = (_answer?: string | number | null) => {
    return _answer === null || _answer === undefined ? '' : `${_answer}`;
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly}
      task={value}
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
    >
      {!!value && (
        <div className="relative">
          <NumericInput
            disabled={readOnly}
            value={getAnswer(value?.answer)}
            unit={value?.item?.unit}
            measurement={value?.item?.measurement}
            status={listStatus}
            isSubmitted={!!value?.submittedAt}
            onChange={handleAnswerChange}
            onAnswer={handleAnswer}
            onEnter={handleEnter}
          />
          {!readOnly && isAnswerChanged && (
            <IconButton
              className="absolute bottom-0 right-0 mb-4 mr-4"
              sx={{ color: iconColor }}
              onClick={handleSendAnswer}
            >
              <SendIcon
                className="text-16"
                color="inherit"
              />
            </IconButton>
          )}
        </div>
      )}
    </QuestionLayout>
  );
}

export default NumericResponse;
