import clsx from 'clsx';
import { TDateRange } from 'models';

import { makeStyles } from '@mui/styles';

import LocationOverview from './location-overview';
import LocationPerformanceList from './location-performance-list';

const useStyles = makeStyles((theme: any) => ({
  locationOverview: {
    [theme.breakpoints.up('lg')]: {
      gridColumn: '1 / span 4',
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('lg')]: {
      gridColumn: '1 / span 12',
    },
  },
  locationStatistics: {
    marginTop: '0 !important',
    [theme.breakpoints.up('lg')]: {
      gridColumn: '5 / span 8',
    },
    [theme.breakpoints.down('lg')]: {
      gridColumn: '1 / span 12',
      paddingBottom: 72,
    },
  },
  root: {
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 140px) !important',
      overflow: 'hidden',
    },
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      gridTemplateRows: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateRows: 'min-content',
    },
  },
}));

type Props = {
  locationName: string;
  period: string;
  customPeriod: TDateRange;
  locationId: number;
};

const PerformanceLayout = ({ locationName, period, customPeriod, locationId }: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx('w-full h-full bg-paper', classes.root)}>
      <div
        className={clsx(
          'grid w-full h-full grid-cols-12 p-16 space-y-16 md:space-y-0 bg-paper gap-y-16 gap-x-16',
          classes.container,
        )}
      >
        <div className={clsx('space-y-16', classes.locationOverview)}>
          <LocationOverview
            period={period}
            customPeriod={customPeriod}
            locationId={locationId}
          />
        </div>
        <div className={clsx('h-full', classes.locationStatistics)}>
          <LocationPerformanceList
            locationId={locationId}
            period={period}
            customPeriod={customPeriod}
            locationName={locationName}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceLayout;
