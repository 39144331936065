import Header from 'app/components/Layout/headers/blank-header-v2';
import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { usePagePrompt } from 'app/components/cores/page-prompt';
import TaskForm from 'app/components/lists/form';
import TaskHeaderActions from 'app/components/lists/header-actions';
import { useUnavailableRoles, useUnavailableRolesForVariations } from 'app/hooks/api/locations';
import {
  refetchTask,
  useCreateTaskDraftVersion,
  useDeleteVariation,
  useGetTask,
  usePublishTaskDraftVersion,
  useUpdateAssignment,
  useUpdateTask,
  useUpdateTaskDraftVersion,
} from 'app/hooks/api/tasks';
import useTab from 'app/hooks/ui/use-tab';
import useBack from 'app/hooks/use-back';
import useShowMessage from 'app/hooks/use-show-message';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import clsx from 'clsx';
import { Paths } from 'constants/index';
import { TABS, TASK_TABS } from 'constants/tabs';
import { updateRecurringDTStart } from 'domains/frequency.domain';
import { updateItemsAttributes } from 'domains/item.domain';
import { checkUnavailableRoles, checkUnavailableRolesForVariations, getDataForUnavailable } from 'domains/role.domain';
import {
  checkIfGlobalSettingChange,
  checkIfLocationSettingChange,
  generateDefaultGlobalAssignmentAttributes,
  globalSettingSchema,
  isTaskInfoChanged,
  preprocessingTaskTags,
  taskFullSchema as schema,
  updateListLocation,
  updateNotificationSettings,
} from 'domains/task.domain';
import { Location } from 'history';
import _isEmpty from 'lodash/isEmpty';
import { TAssignment, TTask } from 'models';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Icon from '@mui/material/Icon';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

interface ParamsTypes {
  listId: string;
}
function Edit() {
  // Common
  const dispatch = useDispatch();
  const { showError, showSuccess } = useShowMessage();

  const { goBack } = useBack(Paths.list);

  const routeParams = useParams<ParamsTypes>();
  const { listId: stringListId } = routeParams;
  const listId = Number(stringListId);

  const { tabValue, setTabValue } = useTab(TASK_TABS.INFO.value, TABS);

  // Data
  const { data: task, isLoading: isGettingTask } = useGetTask(listId);
  const draftTaskId = Number(task?.draftVersion?.id);

  const { mutateAsync: updateTask } = useUpdateTask(listId);
  const { mutateAsync: createTaskDraftVersion } = useCreateTaskDraftVersion(listId);
  const { mutateAsync: updateTaskDraftVersion, isLoading: isUpdatingDraftVersion } =
    useUpdateTaskDraftVersion(draftTaskId);
  const { mutateAsync: publishTaskDraftVersion, isLoading: isPublishingDraftVersion } =
    usePublishTaskDraftVersion(draftTaskId);
  const { mutateAsync: updateGlobalAssignment, isLoading: isUpdatingGlobalAssignment } = useUpdateAssignment(listId);
  const { mutateAsync: deleteVariation, isLoading: isDeletingVariation } = useDeleteVariation(Number(listId));

  const [pageLoading, setPageLoading] = useState(false);
  const isLoading =
    pageLoading ||
    isGettingTask ||
    isUpdatingDraftVersion ||
    isPublishingDraftVersion ||
    isUpdatingGlobalAssignment ||
    isDeletingVariation;

  const getValidationSchema = () => (tabValue === TASK_TABS.GLOBAL_SETTING.value ? globalSettingSchema : schema);
  const getValidationMode = () => (tabValue === TASK_TABS.GLOBAL_SETTING.value ? 'onChange' : 'onBlur');

  const form = useForm<TTask>({
    mode: getValidationMode(),
    resolver: yupResolver(getValidationSchema()),
    defaultValues: task,
    values: task,
  });

  const { handleSubmit, reset, getValues, control } = form;

  // Start: Check unavailable roles
  const globalLocations = useWatch({ name: 'locationTasksAttributes', control });
  const globalOrgRoles = useWatch({ name: 'globalAssignmentAttributes.assignmentOrgRolesAttributes', control });
  const assignmentsAttributes = useWatch({ name: 'assignmentsAttributes', control });
  const { global, variations } = getDataForUnavailable({
    globalLocations,
    globalOrgRoles,
    variations: assignmentsAttributes,
  });
  const { data: globalResult } = useUnavailableRoles(global.locationIds, global.orgRoleIds);
  const { data: unavailableRolesForVariations } = useUnavailableRolesForVariations(variations.variationsToCheck);
  const checkUnavailableGlobal = checkUnavailableRoles(globalResult);
  const checkUnavailableVariations = checkUnavailableRolesForVariations(unavailableRolesForVariations as any);
  // End: Check unavailable roles

  const isInfoTab = tabValue === TASK_TABS.INFO.value;
  const isLocationTab = tabValue === TASK_TABS.LOCATION.value;
  const isGlobalSettingTab = tabValue === TASK_TABS.GLOBAL_SETTING.value;
  const isDraft = task?.status === 'draft';

  const draftVersion = getValues('draftVersion');
  const havingDraftVersion = !!draftVersion?.version;

  const { checkPageChange } = usePagePrompt();

  // Effect
  useEffect(() => {
    if (_isEmpty(task)) return;

    reset(task);
  }, [task, reset]);

  // General Functions
  const handleBackClick = () => {
    goBack();
  };

  const handleInvalid = async () => {
    showError('Could not update List');
  };

  const handleTaskError = (errors: any, defaultErrorMessage?: string) => {
    let statusError: string | null = null;
    errors?.forEach((error: any) => {
      const message = `${error?.field} ${error?.message?.[0]}`;
      if (error?.field === 'status') {
        statusError = message;
      }
      form.setError(error?.field, { type: 'custom', message });
    });

    showError(statusError || defaultErrorMessage || 'Could not update List', errors);
  };

  const getTaskPayload = (status: string, value: Partial<TTask>) => {
    const newStatus = status || value?.status;

    const originalTaskTags = task?.taskTagsAttributes || [];
    const newTaskTags = (value as any)?.taskTagsAttributes;
    const taskTags = preprocessingTaskTags(originalTaskTags, newTaskTags);

    const scannableCodeAttributes = value?.scannableCodeAttributes?.code
      ? { ...value?.scannableCodeAttributes, codeType: 'qr_code' }
      : undefined;

    let currentVersionAttributes;
    if (status === 'draft') {
      currentVersionAttributes = {
        ...value?.currentVersion,
        status: newStatus,
        itemsAttributes: updateItemsAttributes(value?.draftVersion?.itemsAttributes),
      };
    } else {
      currentVersionAttributes = {
        ...value?.currentVersion,
        status: newStatus,
        itemsAttributes: updateItemsAttributes(value?.itemsAttributes),
      };
    }

    const payload = {
      task: {
        ...value,
        status: newStatus,
        scannableCodeAttributes,
        currentVersionAttributes,
        taskTagsAttributes: taskTags,
      },
    };

    return payload;
  };

  /**
   * Dialog Functions
   */

  const handleCancelButton = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Cancel New Changes"
            message="Are you sure you want to erase the new change?"
            statusVariant="warning"
            confirmButtonLabel="Confirm"
            cancelButtonLabel="Cancel"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              reset(task);
            }}
          />
        ),
      }),
    );
  };

  const leaveTabConfirmDialog = (newTabValue: number, onConfirm?: (newTabValue?: number) => void) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Unsaved Changes"
            message="You are about to leave this tab without saving. All changes will be lost. Do you want to save before leaving?"
            statusVariant="warning"
            confirmButtonLabel="Save"
            cancelButtonLabel="Leave without saving"
            onHide={() => {
              dispatch(closeDialog({}));
            }}
            onClose={() => {
              dispatch(closeDialog({}));
              setTabValue(newTabValue);
              reset(task);
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              onConfirm?.(newTabValue);
            }}
          />
        ),
      }),
    );
  };

  /**
   * API Call Functions
   */

  const handleUpdateDraftQuestionVersion = (options?: any, onSuccess?: () => void) => (value: Partial<TTask>) => {
    const itemsAttributes = updateItemsAttributes(value?.draftVersion?.itemsAttributes);

    const payload = {
      task: {
        itemsAttributes,
      },
    };

    updateTaskDraftVersion(payload)
      .then(() => {
        if (!options?.hideSuccessMessage) {
          showSuccess('List has been successfully updated');
        }
        onSuccess?.();
      })
      .catch(err => {
        if (!options?.hideErrorMessage) {
          handleTaskError(err?.errors);
        }
      });
  };

  const handleUpdateTask = (status: string, options?: any, onSuccess?: () => void) => async (value: Partial<TTask>) => {
    let payload = getTaskPayload(status, value);

    if (isLocationTab) {
      payload = await handleUpdateLocationTab(payload);
    }

    updateTask(payload)
      .then(() => {
        if (!options?.hideSuccessMessage) {
          showSuccess('List has been successfully updated');
        }
        onSuccess?.();
      })
      .catch(err => {
        if (!options?.hideErrorMessage) {
          handleTaskError(err?.errors);
        }
      });
  };

  const handlePublishDraftQuestionVersion = () => (value: Partial<TTask>) => {
    const itemsAttributes = updateItemsAttributes(value?.draftVersion?.itemsAttributes);
    const payload = {
      task: {
        current: true,
        itemsAttributes,
      },
    };

    publishTaskDraftVersion(payload)
      .then(() => {
        showSuccess('List has been successfully published');
      })
      .catch(err => {
        handleTaskError(err?.errors);
      });
  };

  const handleNewDraftVersion = () => {
    createTaskDraftVersion(undefined)
      .then(() => {
        showSuccess('New list version has been successfully created');
      })
      .catch(err => {
        handleTaskError(err?.errors);
      });
  };

  const handleSaveListInfo = async (newTabValue?: number) => {
    const saveListInfo = (_newTabValue?: number) => async (value: Partial<TTask>) => {
      const { status } = value || {};
      const payload = getTaskPayload(status as string, value);

      return updateTask(payload)
        .then(() => {
          showSuccess('List has been successfully updated');
          if (_newTabValue !== undefined) {
            setTabValue(_newTabValue);
          }
          return Promise.resolve();
        })
        .catch(err => {
          handleTaskError(err?.errors);
          return Promise.reject();
        });
    };

    return handleSubmit(saveListInfo(newTabValue), handleInvalid)();
  };

  const handleUpdateLocationTab = async (payload: any) => {
    return updateListLocation(payload, deleteVariation);
  };

  const handleSaveLocationSetting = async (newTabValue?: number) => {
    const saveLocation = (_newTabValue?: number) => async (value: Partial<TTask>) => {
      const { status } = value || {};
      let payload = getTaskPayload(status as string, value);
      payload = await handleUpdateLocationTab(payload);

      return updateTask(payload)
        .then(() => {
          showSuccess('Location setting has been successfully updated');
          if (_newTabValue !== undefined) {
            setTabValue(_newTabValue);
          }
          return Promise.resolve();
        })
        .catch(err => {
          handleTaskError(err?.errors);
          return Promise.reject();
        });
    };

    return handleSubmit(saveLocation(newTabValue), handleInvalid)();
  };

  const handleSaveGlobalSetting = (newTabValue?: number) => {
    return handleSubmit(async () => {
      let nextGlobalAssignmentAttributes = getValues('globalAssignmentAttributes');
      nextGlobalAssignmentAttributes = updateRecurringDTStart(nextGlobalAssignmentAttributes);
      nextGlobalAssignmentAttributes = updateNotificationSettings(nextGlobalAssignmentAttributes);

      const payload = {
        assignment: nextGlobalAssignmentAttributes as TAssignment,
      };

      return updateGlobalAssignment(payload)
        .then(() => {
          refetchTask();
          showSuccess('Global setting has been successfully updated');
          if (newTabValue !== undefined) {
            setTabValue(newTabValue);
          }
          return Promise.resolve();
        })
        .catch(err => {
          showError('Could not update global setting', err?.errors);

          return Promise.reject();
        });
    })();
  };

  const saveGlobalSettingWithoutErrorHandling = async () => {
    let nextGlobalAssignmentAttributes = generateDefaultGlobalAssignmentAttributes() as TAssignment;
    nextGlobalAssignmentAttributes = updateRecurringDTStart(nextGlobalAssignmentAttributes);

    const payload = {
      assignment: nextGlobalAssignmentAttributes as TAssignment,
    };

    return updateGlobalAssignment(payload)
      .then(() => {
        refetchTask();
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  };

  /**
   * Main UI functions
   */

  const handleSaveTask = async (options?: any, onSuccess?: () => void) => {
    const saveTaskHandler = isDraft
      ? handleSubmit(handleUpdateTask('draft', options, onSuccess), handleInvalid)
      : handleSubmit(handleUpdateDraftQuestionVersion(options, onSuccess), handleInvalid);
    return saveTaskHandler();
  };

  // Handle Change Tab
  // TODO move to domain and update for other tabs
  const handleTabChange = (event: React.SyntheticEvent, newTabValue: number) => {
    if (isInfoTab && newTabValue !== TASK_TABS.INFO.value) {
      const isInfoChanged = isTaskInfoChanged(task, getValues());

      if (isInfoChanged) {
        leaveTabConfirmDialog(newTabValue, handleSaveListInfo);
        return;
      }
    }

    if (isLocationTab && newTabValue !== TASK_TABS.LOCATION.value) {
      const isLocationChanged = checkIfLocationSettingChange(
        task?.locationTasksAttributes,
        getValues('locationTasksAttributes'),
      );

      if (isLocationChanged) {
        leaveTabConfirmDialog(newTabValue, handleSaveLocationSetting);
        return;
      }
    }

    if (isGlobalSettingTab && newTabValue !== TASK_TABS.GLOBAL_SETTING.value) {
      const { isOldData, isGlobalSettingChange } = checkIfGlobalSettingChange(
        task?.globalAssignmentAttributes,
        getValues('globalAssignmentAttributes'),
      );

      if (isOldData) {
        saveGlobalSettingWithoutErrorHandling();
        setTabValue(newTabValue);
        return;
      }
      if (isGlobalSettingChange) {
        leaveTabConfirmDialog(newTabValue, handleSaveGlobalSetting);
        return;
      }
    }

    setTabValue(newTabValue);
  };

  // Handle Leave Page

  const handleLeavePage = async () => {
    if (isInfoTab) {
      return handleSaveListInfo();
    }

    if (isLocationTab) {
      const isLocationChanged = checkIfLocationSettingChange(
        task?.locationTasksAttributes,
        getValues('locationTasksAttributes'),
      );

      if (isLocationChanged) {
        return handleSaveLocationSetting();
      }
    }

    if (isGlobalSettingTab) {
      return handleSaveGlobalSetting();
    }

    return Promise.resolve();
  };

  const checkLeavePageCondition = (nextLocation: Location) => {
    if (isInfoTab) {
      return isTaskInfoChanged(task, getValues());
    }

    if (isLocationTab) {
      return checkIfLocationSettingChange(task?.locationTasksAttributes, getValues('locationTasksAttributes'));
    }

    if (isGlobalSettingTab) {
      const { isOldData, isGlobalSettingChange } = checkIfGlobalSettingChange(
        task?.globalAssignmentAttributes,
        getValues('globalAssignmentAttributes'),
      );

      if (isOldData) {
        return false;
      }

      return isGlobalSettingChange;
    }

    return checkPageChange(nextLocation.pathname);
  };

  const renderWarningIcon = (tab: number) => {
    const showIconForGlobalSetting = tab === TASK_TABS.GLOBAL_SETTING.value && checkUnavailableGlobal;
    const showIconForVariations = tab === TASK_TABS.VARIATION.value && checkUnavailableVariations;

    if (showIconForGlobalSetting || showIconForVariations) {
      return (
        <ErrorOutlineOutlinedIcon
          className="text-16"
          color="error"
        />
      );
    }

    return <></>;
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-header h-header border-b',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24 border-b-1',
      }}
      header={
        <Header
          leftHeader={
            <div
              role="button"
              className="flex text-secondaryMain w-fit"
              tabIndex={0}
              onClick={handleBackClick}
            >
              <Icon
                role="button"
                className="mt-4 text-18"
              >
                arrow_back_ios
              </Icon>
              <div className="flex flex-col">
                <Typography className="ml-16 uppercase text-18 font-600 line-clamp-1">{task?.name}</Typography>
                <Typography className="ml-16 uppercase text-13 font-400 line-clamp-1">{task?.status}</Typography>
              </div>
            </div>
          }
          rightHeader={
            <div className={clsx('flex flex-row', isLoading && 'cursor-not-allowed')}>
              <div className={clsx(isLoading && 'pointer-events-none')}>
                <TaskHeaderActions
                  isDraft={isDraft}
                  havingDraftVersion={havingDraftVersion}
                  tabValue={tabValue}
                  onCancel={handleCancelButton}
                  onDraftTask={handleSubmit(handleUpdateTask('draft'), handleInvalid)}
                  onPublishTask={handleSubmit(handleUpdateTask('published'), handleInvalid)}
                  onUpdateDraftQuestionVersion={handleSubmit(handleUpdateDraftQuestionVersion(), handleInvalid)}
                  onPublishDraftQuestionVersion={handleSubmit(handlePublishDraftQuestionVersion(), handleInvalid)}
                  onCreateDraftVersion={handleNewDraftVersion}
                  onSaveGlobalSetting={handleSaveGlobalSetting}
                />
              </div>
            </div>
          }
        />
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          indicatorColor="secondary"
          textColor="primary"
          scrollButtons="auto"
          classes={{ root: 'w-full relative' }}
          onChange={handleTabChange}
          sx={{ height: '64px' }}
        >
          {TABS.map(tab => (
            <Tab
              key={tab.value}
              label={tab.label}
              className="normal-case font-400"
              sx={{ height: '64px' }}
              icon={renderWarningIcon(tab.value)}
              iconPosition="end"
            />
          ))}
        </Tabs>
      }
      content={
        <div className="relative w-full h-full p-16 sm:p-24 bg-paper">
          <FormProvider {...form}>
            <TaskForm
              tabIndex={tabValue}
              onSetPageLoading={setPageLoading}
              onSave={handleSaveTask}
            />
          </FormProvider>
        </div>
      }
      onLeavePage={handleLeavePage}
      leavePageCondition={checkLeavePageCondition}
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
      isLoading={isLoading}
    />
  );
}

export default Edit;
