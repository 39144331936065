import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { SignaturePad } from 'app/components/cores/tasks/components';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TItem, TQuestionLayoutView } from 'models';
import { ElementRef, useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';

import DrawIcon from '@mui/icons-material/Draw';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

type Props = {
  data: TItem;
  name: string;
  listId: number;
  readOnly?: boolean;
  view: TQuestionLayoutView;
};

const Signature = ({ data, name, listId, readOnly, view }: Props) => {
  const { resetField, setValue, setError, clearErrors } = useFormContext();
  const signatureRef = useRef<ElementRef<typeof SignatureCanvas>>(null);
  const signaturePadRef = useRef<ElementRef<typeof SignatureCanvas> & { open: () => void }>(null);
  const {
    field: { value, onChange },
  } = useController({ name });
  const { mutateAsync: editTask, isLoading } = useEditHistoryTask({ taskId: listId, responseId: value?.id });
  const { showSuccess, showError } = useShowMessage();

  const hasValue = !!value?.answer;
  const isRequired = value?.item?.required;
  const validAnswer = value?.answer;

  const handleChange = (nextAnswer: string) => {
    onChange({ ...value, answer: nextAnswer });
  };

  useEffect(() => {
    if (isRequired && validAnswer) clearErrors(name);
  }, [validAnswer, isRequired, clearErrors, name]);

  const checkAnswerIsValid = () => {
    if (isRequired && !validAnswer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!checkAnswerIsValid()) return;

    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    handleAnswer(answerPayload);
  };

  const handleAnswer = async (payload: any) => {
    try {
      const res = await editTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  const handleUpdate = () => {
    signaturePadRef.current?.open();
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview
      task={value}
      name={name}
      view={view}
      rightActions={
        !readOnly ? (
          <div className="flex items-center">
            <Tooltip title="Update">
              <IconButton onClick={handleUpdate}>
                <EditIcon className="text-20" />
              </IconButton>
            </Tooltip>
          </div>
        ) : null
      }
      onSaveEdit={handleSave}
      isLoading={isLoading}
    >
      {!readOnly ? (
        <SignaturePad
          signaturePadRef={signaturePadRef}
          value={value?.answer}
          onChange={handleChange}
        />
      ) : (
        <Box
          className="flex items-center justify-center w-full bg-gray-200 rounded-16"
          sx={{
            border: '1px dashed #7C808B',
            height: '184px',
          }}
        >
          {hasValue ? (
            <SignaturePad
              disabled
              signaturePadRef={signatureRef}
              value={value?.answer}
            />
          ) : (
            <div className="text-center">
              <DrawIcon className="text-24" />
              <Typography className="uppercase text-13">sign here</Typography>
            </div>
          )}
        </Box>
      )}
    </QuestionLayoutMapper>
  );
};
export default Signature;
