import { DateInput, QuestionLayout, TimeInput } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import {
  formatDateTimeAnswer,
  generateTaskAnswerPayload,
  getDateTimeType,
  getDefaultDateTimeValue,
  toAnswerDate,
  toAnswerDateTime,
  toAnswerTime,
} from 'domains/employee/task.domain';
import { TTaskItemDateTimeType } from 'models';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  responseId: number;
  response?: TEmployeeTaskResponse;
  listStatus?: TTaskStatus;
};

function DateTimeResponse({ name, readOnly, listId, responseId, response, listStatus }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const dateTimeType = response?.item?.dateTimeType as TTaskItemDateTimeType;
  const originalAnswer = response?.answer;
  const { isDateTimeType, isDateType, isTimeType } = useMemo(() => getDateTimeType(dateTimeType), [dateTimeType]);
  const isShowDate = isDateTimeType || isDateType;
  const isShowTime = isDateTimeType || isTimeType;

  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);

  // effect to set selected date and time
  useEffect(() => {
    if (!originalAnswer || selectedDate || selectedTime) {
      return;
    }

    const { date, time } = getDefaultDateTimeValue(dateTimeType, originalAnswer);
    setSelectedDate(date);
    setSelectedTime(time);
  }, [dateTimeType, originalAnswer, selectedDate, selectedTime]);

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(
      formatDateTimeAnswer(dateTimeType, value?.answer),
      additionalPayload,
    );
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value?.comment ?? '',
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(
      formatDateTimeAnswer(dateTimeType, value?.answer),
      additionalPayload,
    );
    answer(answerPayload);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);

    // type datetime
    if (isDateTimeType && selectedTime) {
      const formattedDate = toAnswerDate(date);
      const formattedTime = toAnswerTime(selectedTime);
      const formattedDateTime = `${formattedDate} ${formattedTime}`;
      updateStateAndAnswer(formattedDateTime);
    }

    // type date
    if (!isDateTimeType) {
      const formattedDateTime = toAnswerDateTime(date, true);
      updateStateAndAnswer(formattedDateTime);
    }
  };

  const handleTimeChange = (time: Date | null) => {
    setSelectedTime(time);

    // type datetime
    if (isDateTimeType && selectedDate) {
      const formattedDate = toAnswerDate(selectedDate);
      const formattedTime = toAnswerTime(time);
      const formattedDateTime = `${formattedDate} ${formattedTime}`;
      updateStateAndAnswer(formattedDateTime);
    }

    // type time
    if (!isDateTimeType) {
      const formattedDateTime = toAnswerDateTime(time);
      updateStateAndAnswer(formattedDateTime);
    }
  };

  const updateStateAndAnswer = (nextAnswer: string) => {
    const answerPayload = generateTaskAnswerPayload(formatDateTimeAnswer(dateTimeType, nextAnswer));
    onChange({ ...value, answer: nextAnswer });
    answer(answerPayload);
  };

  const answer = (payload: any) => {
    answerTask(payload)
      .then(res => {
        onChange({ ...value, ...res });
        showSuccess('Your answer has been submitted successfully');
      })
      .catch(() => {
        showError('Could not submit your answer');
      });
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly ?? false}
      task={value}
      isLoading={isLoading}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
      listStatus={listStatus}
    >
      <div className="flex items-center space-x-8">
        {isShowDate && (
          <div className="w-1/2 max-w-232">
            <DateInput
              isLoading={isLoading}
              disabled={readOnly}
              value={selectedDate}
              onChange={handleDateChange}
              listStatus={listStatus}
            />
          </div>
        )}
        {isShowTime && (
          <div className="w-1/2 max-w-232">
            <TimeInput
              isLoading={isLoading}
              disabled={readOnly}
              value={selectedTime}
              onChange={handleTimeChange}
              listStatus={listStatus}
            />
          </div>
        )}
      </div>
    </QuestionLayout>
  );
}

export default DateTimeResponse;
