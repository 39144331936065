import { refetchTask } from 'app/hooks/api/tasks';
import {
  AFTER_END_OF_VALUE,
  BEFORE_END_OF_VALUE,
  GROUP_OPTIONS,
  IN_THE_PAST_VALUE,
  ITEM_TYPES_MAP,
  WITHIN_VALUE,
} from 'constants/index';
import { generateDefaultDisplayTime, getFrequency } from 'domains/display-time.domain';
import { updateRecurringDTStart } from 'domains/frequency.domain';
import _ from 'lodash';
import { TAssignment, TItem, TNotificationSetting, TTask, TTaskLocation, TTaskTag } from 'models';
import moment from 'moment';
import { RRule } from 'rrule';
import * as yup from 'yup';

const CUSTOM_DATE_TIME_VALUES = [WITHIN_VALUE, BEFORE_END_OF_VALUE, AFTER_END_OF_VALUE, IN_THE_PAST_VALUE];

export const taskFullSchema = yup.object({
  name: yup.string().required('List title is required'),
  status: yup.string(),
  draftVersion: yup.object().shape({
    itemsAttributes: yup.array().of(
      yup.object().shape({
        prompt: yup.string().when(['type'], {
          is: (type: any) => type === ITEM_TYPES_MAP.SECTION,
          then: yup.string().required('Section name is required'),
          otherwise: yup.string().required('Task name is required'),
        }),
        type: yup.string().required('Type is required'),
        multipleChoiceOptions: yup
          .array()
          .of(
            yup.object({
              label: yup.string().trim().required('Please input the choice'),
              value: yup.string(),
            }),
          )
          .when(['type', '_destroy'], {
            is: (type: string, _destroy: boolean) => !_destroy && type === ITEM_TYPES_MAP.MULTIPLE_CHOICE,
            then: schema => schema.min(2, 'Multiple choice options have to greater or equal 2'),
            otherwise: schema => schema.nullable(),
          }),
        code: yup.string().when(['type', '_destroy'], {
          is: (type: any, _destroy: boolean) =>
            !_destroy && [ITEM_TYPES_MAP.BAR_CODE, ITEM_TYPES_MAP.QR_CODE].includes(type),
          then: schema => schema.trim().required('Code is required'),
          otherwise: schema => schema.nullable(),
        }),
        conditionalComparison: yup
          .array()
          .of(
            yup.object({
              logic: yup.string(),
              value: yup
                .string()
                .trim()
                .when(['logic'], {
                  is: (logic: string) => !['completed', 'not_completed', 'not_applicable'].includes(logic),
                  then: schema => schema.trim().required('Conditional value is required'),
                  otherwise: schema => schema.nullable(),
                }),
            }),
          )
          .when(['conditionalId'], {
            is: (conditionalId: number) => conditionalId > 0,
            then: schema => schema.min(1, 'Conditional is required'),
            otherwise: schema => schema.nullable(),
          }),
        notificationConditionals: yup.array().of(
          yup.object({
            message: yup.string().nullable(),
            overrideSubject: yup.string().nullable(),
            sendToRoles: yup.array(),
            sendToRolesDetail: yup.array(),
            sendToUsers: yup.array(),
            sendToUsersDetail: yup.array(),
            skipRoleInbox: yup.boolean().default(false),
            skipUserInbox: yup.boolean().default(false),
            comparisons: yup.array().of(
              yup.object({
                value: yup
                  .string()
                  .trim()
                  .when(['logic'], {
                    is: (logic: string) => {
                      if (CUSTOM_DATE_TIME_VALUES.includes(logic)) return false;

                      return !['completed', 'not_completed', 'not_applicable'].includes(logic);
                    },
                    then: schema => schema.trim().required('Conditional value is required'),
                    otherwise: schema => schema.nullable(),
                  }),
              }),
            ),
          }),
        ),
        formType: yup.string().when(['type'], {
          is: (type: any) => type === ITEM_TYPES_MAP.SALT_OPS_FORM,
          then: yup.string().required('Form type is required'),
          otherwise: schema => schema.nullable(),
        }),
        conditionalTasks: yup
          .array()
          .of(
            yup.object({
              comparisons: yup
                .array()
                .of(
                  yup.object({
                    logic: yup.string(),
                    value: yup
                      .string()
                      .trim()
                      .when(['logic'], {
                        is: (logic: string) => {
                          if (CUSTOM_DATE_TIME_VALUES.includes(logic)) {
                            return false;
                          }

                          return !['completed', 'not_completed', 'not_applicable', 'custom'].includes(logic);
                        },
                        then: schema => schema.trim().required('Conditional value is required'),
                        otherwise: schema => schema.nullable(),
                      }),
                  }),
                )
                .when(['conditionalId'], {
                  is: (conditionalId: number) => conditionalId > 0,
                  then: schema => schema.min(1, 'Conditional is required'),
                  otherwise: schema => schema.nullable(),
                }),
              tasks: yup
                .array()
                .of(
                  yup.object({
                    id: yup
                      .number()
                      .test('required', 'List is required', (value, ctx: any) => {
                        const outer = ctx.from?.[1]?.value;
                        const comparisons = outer?.comparisons || [];
                        const logic = comparisons?.[0]?.logic;

                        if (logic && !value) return false;
                        return true;
                      })
                      .typeError('List is required'),

                    orgRoleIds: yup.array().when(['default', 'id'], {
                      is: (defaultValue: boolean, id: number) => !defaultValue && id,
                      then: yup.array().min(1, 'Assign Role(s) is required'),
                      otherwise: yup.array().nullable(),
                    }),
                  }),
                )
                .nullable(),
            }),
          )
          .nullable(),
      }),
    ),
  }),
});

export const assignmentSchema = yup.object({
  frequencies: yup.array().of(
    yup.object({
      recurringRule: yup.string(),
      displayTimes: yup.array().of(
        yup.object({
          displayUntil: yup.bool().nullable(),
          dueUntil: yup.lazy((v, ctx: any) => {
            if (!ctx?.parent?.displayUntil) return yup.string().nullable();
            return yup.object({
              target: yup
                .string()
                .nullable()
                .test('required', 'Date is required', (value, context: any) => {
                  const freq = getFrequency(context.from?.[2]?.value?.recurringRule);
                  return !freq || freq === RRule.DAILY ? true : !!value;
                }),
              time: yup.mixed().when(['target'], {
                is: (target: string) => !['end', 'end_time', 'next_start_time'].includes(target),
                then: schema => schema.required('Time is required'),
                otherwise: schema => schema.nullable(),
              }),
              after: yup.number().nullable(),
            });
          }),
          expireUntil: yup.lazy((v, ctx: any) => {
            if (!ctx?.parent?.displayUntil) return yup.string().nullable();
            return yup.object({
              target: yup
                .string()
                .nullable()
                .test('required', 'Date is required', (value, context: any) => {
                  const freq = getFrequency(context.from?.[2]?.value?.recurringRule);
                  return !freq || freq === RRule.DAILY ? true : !!value;
                }),
              time: yup.mixed().when(['target'], {
                is: (target: string) => !['end', 'end_time', 'next_start_time'].includes(target),
                then: schema => schema.required('Time is required'),
                otherwise: schema => schema.nullable(),
              }),
              after: yup.number().nullable(),
            });
          }),
        }),
      ),
    }),
  ),
});

export const globalSettingSchema = yup.object({
  globalAssignmentAttributes: assignmentSchema,
});

export const generateFieldName = (name: string, prefix?: string) => (!prefix ? name : `${prefix}.${name}`);

export const generateTaskCode = () => `TASK-${Math.floor(Date.now() / 1000)}`;

export const generateDefaultGlobalAssignmentAttributes = () => {
  return {
    startDate: moment().format('YYYY-MM-DD'),
    displayTimes: [generateDefaultDisplayTime()],
    individual: true,
  } as Partial<TAssignment>;
};

export const preprocessingTaskTags = (originalTaskTags: TTaskTag[] = [], newTaskTags: TTaskTag[] = []) => {
  let taskTags: TTaskTag[] = [];
  const newTaskTagIds = newTaskTags.map(taskTag => taskTag.tagId);
  const originalTagIds = originalTaskTags.map(taskTag => taskTag.tagId);
  const unSelectTaskTags = originalTaskTags.filter(originalTaskTag => !newTaskTagIds.includes(originalTaskTag.tagId));
  taskTags = [...newTaskTags, ...unSelectTaskTags.map(taskTag => ({ ...taskTag, _destroy: true }))];
  return taskTags.map(taskTag => {
    if (originalTagIds.includes(taskTag.tagId) && !taskTag.id) {
      return {
        ...taskTag,
        id: originalTaskTags.find(originalTaskTag => originalTaskTag.tagId === taskTag.tagId)?.id,
      };
    }
    return taskTag;
  });
};

export const isTaskInfoChanged = (task?: TTask, nextTask?: TTask) => {
  const modifiedFields = ['name', 'description', 'attachmentsAttributes', 'taskTagsAttributes'];

  const infoData = _.pick(task, modifiedFields);
  const taskQRCode = task?.scannableCodeAttributes?.code || null;

  const nextInfoData = _.pick(nextTask, modifiedFields);
  const nextTaskQRCode = nextTask?.scannableCodeAttributes?.code || null;

  const isChanged = !_.isEqual(infoData, nextInfoData) || taskQRCode !== nextTaskQRCode;
  return isChanged;
};

export const isGlobalAssignmentChange = (assignment: Partial<TAssignment>) => {
  let isChange = false;

  _.forIn(assignment, (value: any) => {
    if (value !== undefined) {
      isChange = true;
    }
  });

  return isChange;
};

export async function updateListLocation(payload: any, deleteVariation: (params: { id: number }) => Promise<any>) {
  const nextTaskPayload = _.cloneDeep(payload);

  // find removed locations
  const removedLocations = _.filter(nextTaskPayload?.task?.locationTasksAttributes || [], { _destroy: true });

  if (removedLocations?.length > 0) {
    // find removed variations
    const removedVariations = _.remove(nextTaskPayload?.task?.assignmentsAttributes || [], (variation: TAssignment) =>
      _.find(removedLocations, { locationId: variation.location?.id }),
    );

    // loop to remove variations
    if (removedVariations?.length > 0) {
      const removeVariationHandler: any = [];

      _.forEach(removedVariations, variation => {
        removeVariationHandler.push(deleteVariation({ id: variation.id }));
      });

      await Promise.all(removeVariationHandler);
      refetchTask();
    }
  }

  return nextTaskPayload;
}

export const checkIfGlobalSettingChange = (currentAttributes?: TAssignment, nextAttributes?: TAssignment) => {
  nextAttributes = updateRecurringDTStart(nextAttributes);

  // when globalAssignmentAttributes is undefined
  const { startDate, ...otherAttributes } = nextAttributes;
  const isSameDay = moment(startDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY');

  if (!currentAttributes && !isGlobalAssignmentChange(otherAttributes) && isSameDay) {
    return { isOldData: true, isGlobalSettingChange: false };
  }

  return { isGlobalSettingChange: !_.isEqual(currentAttributes, nextAttributes), isOldData: false };
};

export const checkIfLocationSettingChange = (currentAttributes?: TTaskLocation[], nextAttributes?: TTaskLocation[]) => {
  return !_.isEqual(currentAttributes, nextAttributes);
};

export const updateNotificationSettings = (assignmentAttributes?: TAssignment) => {
  const nextAssignmentAttributes = _.cloneDeep(assignmentAttributes) || ({} as TAssignment);

  if (nextAssignmentAttributes?.notificationSettings) {
    nextAssignmentAttributes.notificationSettings = _.filter(
      nextAssignmentAttributes?.notificationSettings,
      (item: TNotificationSetting) => {
        return !!item.sendToUsers?.length || !!item?.sendToRoles?.length;
      },
    );
  }

  return nextAssignmentAttributes;
};

export const generateListTypeLabel = (listType: string) => {
  return _.find(GROUP_OPTIONS, { value: listType })?.label || '';
};

export const filterTasksByLocation = (taskItems: TItem[], location: TTaskLocation) => {
  return _.filter(
    taskItems,
    (item: TItem) => _.isEmpty(item.locations) || !!_.find(item.locations, { externalId: location?.locationId }),
  );
};

export const getStatusNameByStatus = (status?: string) => {
  if (!status) return '';

  const statusMap: Record<string, string> = {
    not_applicable: 'Not Applicable',
    in_progress: 'In Progress',
    completed: 'Completed',
    overdue: 'Late',
    incomplete: 'Incomplete',
  };

  return statusMap[status] || '';
};

export const isDateTimeCondition = (logic: string) => {
  return [WITHIN_VALUE, BEFORE_END_OF_VALUE, AFTER_END_OF_VALUE, IN_THE_PAST_VALUE].includes(logic);
};
