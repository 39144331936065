import EmployeeTaskSnackbar from 'app/components/cores/app-snackbar/employee-task-snackbar';
import { TaskContainer, TaskContent, TaskHeader, TaskSidebar } from 'app/components/cores/tasks';
import { refetchTask, refetchTasks, useGetEmployeeTasks, useGetTask } from 'app/hooks/api/employee/tasks';
import useTaskFilter from 'app/hooks/use-task-filter';
import useTaskUrl from 'app/hooks/use-task-url';
import { TASK_REFETCH_TIME } from 'constants/employee/task';
import { generateFormValidations } from 'domains/employee/task.domain';
import { TUserLocation, TUserRole } from 'models';
import { TEmployeeTaskItem, TTaskFilter } from 'models/employee/task.model';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';

function Index() {
  const [filter, setFilter] = useState<TTaskFilter>({} as TTaskFilter);
  const [selectedList, setSelectedList] = useState<TEmployeeTaskItem | null>(null);
  const locations = useSelector(({ auth }: any) => auth.user.locations);
  const [roles, setRoles] = useState<TUserRole[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const {
    data: lists,
    refetch: refetchLists,
    isFetching: isFetchingLists,
  } = useGetEmployeeTasks(filter, {
    enabled: !!filter[`q[location_id_eq]`],
    refetchInterval: TASK_REFETCH_TIME,
    onSuccess() {
      isFetchedLists.current = true;
    },
  });

  const {
    data: list,
    refetch: refetchList,
    isFetching: isFetchingList,
  } = useGetTask(selectedList?.id, {
    onSuccess() {
      isFetchedListDetail.current = true;
    },
  });

  const { replaceEmployeeListId, scrollToActiveListItem, clearEmployeeListId } = useTaskUrl();
  const { location, setTaskFilterLocation, getTaskFilterRoles } = useTaskFilter();

  const responses = useMemo(() => {
    const nextResponses = (list as any)?.responses || [];
    return nextResponses?.filter((item: any) => !item._isHide) || [];
  }, [list]);

  const validations = useMemo(
    () => generateFormValidations(responses, list),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [responses],
  );

  const form = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...validations,
      }),
    ),
  });
  const isFetchedListDetail = useRef(false);
  const isFetchedLists = useRef(false);
  const isGettingList = isFetchingList && !isFetchedListDetail.current;
  const isGettingLists = isFetchingLists && !isFetchedLists.current;

  // update filter when location updated
  useEffect(() => {
    if (!location?.externalId) return;
    isFetchedLists.current = false;
    isFetchedListDetail.current = false;
    setSelectedList(null);
    setFilter((prevFilter: TTaskFilter) => ({
      ...prevFilter,
      'q[location_id_eq]': location?.externalId,
    }));
  }, [location]);

  // filter roles
  useEffect(() => {
    const roleIds = roles?.filter(role => !!role.externalId).map(role => role.externalId);
    if (!roles?.length) return;

    isFetchedLists.current = false;
    isFetchedListDetail.current = false;
    setSelectedList(null);
    setFilter((prevFilter: TTaskFilter) => ({
      ...prevFilter,
      'q[org_role_id_in]': roleIds,
    }));
  }, [roles]);

  // search
  useEffect(() => {
    isFetchedLists.current = false;
    isFetchedListDetail.current = false;
    setSelectedList(null);
    setFilter((prevFilter: TTaskFilter) => ({
      ...prevFilter,
      'q[task_root_name_cont]': searchValue,
    }));
  }, [searchValue]);

  const handleSelectList = useCallback(
    (nextList: TEmployeeTaskItem, isRefetchLists?: boolean) => {
      setSelectedList(nextList);

      if (isRefetchLists) {
        isFetchedListDetail.current = false;
        refetchLists();
        replaceEmployeeListId(nextList?.id);
      }

      scrollToActiveListItem(nextList?.id);
    },
    [refetchLists, replaceEmployeeListId, scrollToActiveListItem],
  );

  const handleRefresh = () => {
    isFetchedListDetail.current = false;
    isFetchedLists.current = false;
    refetchLists();
    refetchList();
  };

  const handleSelectLocation = (nextLocation: TUserLocation) => {
    setTaskFilterLocation(nextLocation);
    setSelectedList(null);
  };

  const handleSelectRoles = (selectedRoles: TUserRole[]) => {
    const nextRoles = getTaskFilterRoles(selectedRoles);
    setRoles(nextRoles as TUserRole[]);
  };

  const handleSubmitSuccess = (resetState?: boolean) => {
    if (resetState) {
      isFetchedListDetail.current = false;
      setSelectedList(null);
      refetchTasks();
      clearEmployeeListId();
    } else {
      refetchTasks();
      refetchTask();
    }
  };

  const { errors } = form.formState;

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstIdxErr = ((errors?.responses || []) as any[])?.findIndex(item => item?.answer);

      if (firstIdxErr >= 0) {
        const firstErrName = `responses.${firstIdxErr}.answer`;
        const firstErrInput = document.getElementsByName(firstErrName)?.[0];
        firstErrInput?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errors]);

  return (
    <BluefitPage
      classes={{
        wrapper: 'bg-paper min-h-0',
        contentWrapper: 'overflow-hidden',
        content: 'h-full overflow-hidden',
      }}
      content={
        <>
          <EmployeeTaskSnackbar
            location={location}
            selectedList={selectedList}
            isDisabled={locations.length <= 1}
          />
          <FormProvider {...form}>
            <TaskHeader
              title="My Tasks"
              location={location}
              onSelectLocation={handleSelectLocation}
            />
            <TaskContainer>
              <TaskSidebar
                lists={lists}
                selectedList={selectedList}
                isLoading={isGettingLists}
                roles={roles}
                searchValue={searchValue}
                onSelectList={handleSelectList}
                onSearch={setSearchValue}
                onSelectRoles={handleSelectRoles}
              />
              <TaskContent
                list={list}
                selectedList={selectedList}
                isLoading={isGettingList}
                initialLoading={isGettingLists && !selectedList}
                readOnly={false}
                onRefresh={handleRefresh}
                onSubmitSuccess={handleSubmitSuccess}
                setSelectedList={setSelectedList}
              />
            </TaskContainer>
          </FormProvider>
        </>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default Index;
