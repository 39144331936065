import HistoryInfo from './info';
import ListTask from './tasks';

type Props = {
  printRef: React.Ref<any>;
};

function HistoryForm({ printRef }: Props) {
  return (
    <div
      className="space-y-24"
      ref={printRef as any}
    >
      <HistoryInfo />

      <div className="grid grid-cols-8 ">
        <div className="col-span-6 export-histories print:max-w-full print:col-span-8">
          <ListTask />
        </div>
      </div>
    </div>
  );
}

export default HistoryForm;
