import { logoutUser } from 'app/auth/store/userSlice';
import bluefitSSOService from 'app/services/bluefitSSOService';
import store from 'app/store';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import clsx from 'clsx';
import { Paths } from 'constants/paths';
import { getBadgeNumberStyle } from 'domains/navbar.domain';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isDevelopment } from 'utils/env';

import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseUtils from '@fuse/utils';
import Icon from '@mui/material/Icon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

import FuseNavBadge from '../FuseNavBadge';
import FuseNavVerticalIcon from './FuseNavVerticalIcon';

const useStyles = makeStyles(theme => ({
  item: props => ({
    height: 40,
    marginTop: 4,
    marginBottom: 4,
    width: 'calc(100% - 8px)',
    borderRadius: '0 24px 24px 0',
    paddingRight: 16,
    paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    '&.active': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
      '& .list-item-text-primary': {
        color: 'white',
      },
      '& .list-item-icon': {
        color: 'white',
      },
    },
    '& .list-item-icon': {
      marginRight: 16,
    },
    '& .list-item-text': {},
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textDecoration: 'none!important',
  }),
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: 13,
    fontWeight: 400,
  },
  badge: {
    position: 'absolute',
    right: '75%',
  },
  itemActive: {
    borderWidth: '1px 1px 1px 0',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.light,
  },
}));

function FuseNavVerticalItem(props) {
  const userRole = useSelector(({ auth }) => auth.user.role);
  const dispatch = useDispatch();

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { item, nestedLevel, loginLevel = 0 } = props;
  const classes = useStyles({
    itemPadding: nestedLevel > 0 ? 52 + loginLevel * 6 : 24,
  });
  const { t } = useTranslation('navigation');

  const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);
  const iconProps = useMemo(
    () => ({
      color: 'action',
      className: 'text-white list-item-icon text-20 flex-shrink-0',
    }),
    [],
  );

  if (!hasPermission) {
    return null;
  }

  const autoActive = !!item.autoActive;
  const activeClassNameProps = item?.isExternalUrl ? {} : { activeClassName: autoActive ? '' : 'active' };
  const activeItemProps = item?.isExternalUrl
    ? {}
    : { isActive: (match, location) => !!match || location?.pathname?.startsWith(item?.url) };

  if (props.item?.id === 'logout') {
    return (
      <ListItemButton
        button="true"
        className={clsx(classes.item, 'my-8')}
        onClick={ev => {
          if (isDevelopment) {
            store.dispatch(logoutUser());
          } else {
            bluefitSSOService.logout();
            window.location.href = Paths.logout;
          }
        }}
      >
        <ListItemText
          className="text-white list-item-text"
          primary={item.translate ? t(item.translate) : item.title}
          classes={{ primary: 'text-13 list-item-text-primary' }}
        />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton
      button="true"
      component={item?.isExternalUrl ? undefined : NavLinkAdapter}
      to={item?.isExternalUrl ? undefined : item.url}
      {...activeClassNameProps}
      {...activeItemProps}
      className={clsx(classes.item, 'my-8', autoActive && classes.itemActive)}
      onClick={() => {
        /* eslint-disable no-unused-expressions */
        item?.isExternalUrl && window.open(item.url, '_self');
        mdDown && dispatch(navbarCloseMobile());
      }}
      exact={item?.isExternalUrl ? undefined : item.exact}
    >
      {!!item.badge && (
        <FuseNavBadge
          className={clsx('justify-center items-center p-0 mr-12', classes.badge, getBadgeNumberStyle(item.badge))}
          badge={{ title: item.badge, bg: '#E0115F', fg: '#FFFFFF' }}
        />
      )}

      {item.icon && !item.isExternalIcon ? (
        <Icon {...iconProps}>{item.icon}</Icon>
      ) : (
        <FuseNavVerticalIcon
          {...iconProps}
          name={item.icon}
        />
      )}

      <ListItemText
        className="list-item-text"
        primary={item.translate ? t(item.translate) : item.title}
        classes={{ primary: clsx('text-13 text-white list-item-text-primary', item.isService && 'font-600') }}
      />
    </ListItemButton>
  );
}

FuseNavVerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

FuseNavVerticalItem.defaultProps = {};

const NavVerticalItem = withRouter(React.memo(FuseNavVerticalItem));

export default NavVerticalItem;
