import { authRoles } from 'app/auth';
import { Paths } from 'constants/index';

import Edit from './edit';
import Index from './index';
import ListReport from './list-report';
import ListReportDetail from './list-report-detail';
import Performance from './performance';

const historyPagesConfig: any = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.manager,
  routes: [
    {
      path: `${Paths.history}/lists`,
      exact: true,
      component: Index,
    },
    {
      path: `${Paths.history}/lists/:employeeTaskId`,
      exact: true,
      component: Edit,
    },
    {
      path: `${Paths.history}/report`,
      exact: true,
      component: ListReport,
    },
    {
      path: `${Paths.history}/report/:reportId`,
      exact: true,
      component: ListReportDetail,
    },
    {
      path: `${Paths.history}/performances`,
      exact: true,
      component: Performance,
    },
  ],
};

export default historyPagesConfig;
