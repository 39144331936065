import useTaskUrl from 'app/hooks/use-task-url';
import clsx from 'clsx';
import _ from 'lodash';
import { TUserRole } from 'models';
import { TEmployeeTaskFormatted, TEmployeeTaskItem } from 'models/employee/task.model';
import { useMemo, useRef, useState } from 'react';
import { delay } from 'utils/date';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GridViewIcon from '@mui/icons-material/GridView';
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TaskSidebarFilter from './filter';
import TaskSidebarSection from './section';
import { DuplicateTaskSidebarItemSkeleton } from './skeleton';

type Props = {
  lists?: any;
  selectedList?: TEmployeeTaskItem | null;
  isLoading?: boolean;
  roles?: TUserRole[];
  searchValue?: string;
  isFacility?: boolean;
  isRenderSection?: (task: TEmployeeTaskFormatted) => boolean;
  locationRoles?: TUserRole[];
  onSelectList?: (task: TEmployeeTaskItem, isRefetchLists?: boolean) => void;
  onSearch?: (search: string) => void;
  onSelectRoles?: (roles: TUserRole[]) => void;
};

function TaskSidebarMobile({
  lists,
  selectedList,
  isLoading,
  roles,
  searchValue,
  isFacility,
  isRenderSection,
  locationRoles,
  onSelectList,
  onSearch,
  onSelectRoles,
}: Props) {
  const classes = useStyles();
  const { listId } = useTaskUrl();

  const [isExpanded, setIsExpanded] = useState(!listId);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filterType, setFilterType] = useState<'search' | 'role' | null>(null);
  const searchRef = useRef<any>(null);

  const roleTitle = useMemo(() => {
    const preventRoles = _.filter(roles, role => !!role.externalId);
    if (!preventRoles?.length) return 'All Roles';
    if (preventRoles?.length === 1) return preventRoles?.[0]?.name;
    return 'Multiple Roles';
  }, [roles]);

  const handleShowSidebar = () => {
    setIsExpanded(true);
  };

  const handleHideSidebar = () => {
    setIsExpanded(false);
    setIsShowFilter(false);
    setFilterType(null);
  };

  const handleShowSearchBox = async () => {
    if (!isExpanded) {
      setFilterType('search');
      setIsExpanded(true);
    }

    await delay(0);
    if (filterType === 'search') {
      setFilterType(null);
      setIsShowFilter(false);
      return;
    }

    setFilterType('search');
    setIsShowFilter(true);
    await delay(0);
    searchRef.current?.focusInput();
  };

  const handleOpenFilterRole = async () => {
    if (filterType === 'role') {
      setFilterType(null);
      setIsShowFilter(false);
      return;
    }

    setFilterType('role');
    setIsShowFilter(true);
    await delay(0);
    searchRef.current?.focusSelect();
  };

  const handleSelectList = (task: TEmployeeTaskItem, isRefetchLists?: boolean) => {
    if (Number(listId) !== task?.id) {
      handleHideSidebar();
      onSelectList?.(task, isRefetchLists);
      return;
    }

    onSelectList?.(task, isRefetchLists);
  };

  return (
    <div className={clsx('h-full pt-8 overflow-hidden bg-white', isExpanded ? 'w-320' : 'w-64')}>
      {/* expanded */}
      {isExpanded && (
        <>
          <div className="pb-8">
            <div className="flex items-center justify-between px-8">
              <div className="flex items-center">
                <IconButton onClick={handleHideSidebar}>
                  <GridViewIcon className="text-secondaryMain" />
                </IconButton>
                <IconButton onClick={handleShowSearchBox}>
                  <SearchIcon className="text-secondaryMain" />
                </IconButton>
              </div>
              <Button
                disableElevation={true}
                disableFocusRipple={true}
                disableRipple={true}
                disableTouchRipple={true}
                variant="text"
                color="secondary"
                endIcon={<ArrowDropDownIcon />}
                onClick={handleOpenFilterRole}
              >
                <Typography className="text-11 font-600 text-secondaryMain">{roleTitle}</Typography>
              </Button>
            </div>

            {isShowFilter && (
              <div className="px-8">
                <TaskSidebarFilter
                  searchRef={searchRef}
                  roles={roles}
                  searchValue={searchValue}
                  isFacility={isFacility}
                  locationRoles={locationRoles}
                  onSearch={onSearch}
                  onSelectRoles={onSelectRoles}
                />
              </div>
            )}
          </div>
        </>
      )}

      {/* not expanded */}
      {!isExpanded && (
        <div className="text-center">
          <IconButton onClick={handleShowSidebar}>
            <GridViewSharpIcon color="primary" />
          </IconButton>
          <IconButton onClick={handleShowSearchBox}>
            <SearchIcon className="text-secondaryMain" />
          </IconButton>
        </div>
      )}

      {/* lists */}
      {isLoading && isExpanded ? (
        <div className="px-8 -mt-16">
          <DuplicateTaskSidebarItemSkeleton />
        </div>
      ) : (
        <div
          className={clsx(
            'overflow-auto px-8',
            isShowFilter ? classes.sidebarWithFilter : classes.sidebar,
            !isExpanded && 'hidden',
          )}
        >
          {lists?.map(
            (task: TEmployeeTaskFormatted) =>
              isRenderSection?.(task) && (
                <TaskSidebarSection
                  key={task.taskGroup}
                  item={task}
                  selectedList={selectedList}
                  isFacility={isFacility}
                  onSelectList={handleSelectList}
                />
              ),
          )}
        </div>
      )}
    </div>
  );
}

export default TaskSidebarMobile;

const useStyles = makeStyles(() => {
  return {
    sidebar: {
      height: 'calc(100% - 50px)',
    },
    sidebarWithFilter: {
      height: 'calc(100% - 150px)',
    },
  };
});
