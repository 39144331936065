import { QuestionLayout, SaltFormOption } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import { useGetSaltOpsForm } from 'app/hooks/api/external-service/salt-ops';
import useShowMessage from 'app/hooks/use-show-message';
import useTaskFilter from 'app/hooks/use-task-filter';
import useTaskUrl from 'app/hooks/use-task-url';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { renderFormLabel } from 'domains/salt-ops.domain';
import _ from 'lodash';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useCallback, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { delay } from 'utils/date';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  listStatus?: TTaskStatus;
  responseId: number;
  response?: TEmployeeTaskResponse;
};

function SaltFormResponse({ name, readOnly, listId, responseId, listStatus, response }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();
  const {
    responseId: returnResponseId,
    saltFormId: returnSaltFormId,
    scrollToActiveResponseItem,
    clearEmployeeSaltFormId,
  } = useTaskUrl();
  const { location } = useTaskFilter();

  const formType = value?.item?.formType;

  const {
    data: saltOpsFormData,
    refetch: handleRefetchSaltOpsForm,
    isLoading: isFetchingSaltOpsForm,
  } = useGetSaltOpsForm(
    formType,
    {
      'q[location_salt_id_eq]': location?.saltId,
    },
    { enabled: !!formType },
  );

  const getSaltOpsForm = useCallback(
    (saltFormId: number) => {
      const option = _.find(saltOpsFormData?.records, { id: saltFormId });
      return !option
        ? null
        : {
            id: option?.id,
            name: renderFormLabel(formType, option),
          };
    },
    [formType, saltOpsFormData],
  );

  const answer = useCallback(
    (payload: any) => {
      answerTask(payload)
        .then(res => {
          onChange({ ...value, ...res });
          showSuccess('Your answer has been submitted successfully');
        })
        .catch(() => {
          showError('Could not submit your answer');
        });
    },
    [value, answerTask, showError, showSuccess, onChange],
  );

  const handleAnswer = useCallback(
    (selectedForm: any) => {
      const nextAnswer = !selectedForm
        ? {}
        : {
            id: selectedForm?.id,
            name: selectedForm?.name,
          };
      onChange({ ...value, answer: nextAnswer });

      const additionalPayload = {
        comment: value.comment,
      };
      const answerPayload = generateTaskAnswerPayload(nextAnswer, additionalPayload);
      answer(answerPayload);
    },
    [value, answer, onChange],
  );

  const handleAnswerFromSalt = useCallback(
    (saltFormId: number) => {
      const nextAnswer = getSaltOpsForm(saltFormId);

      if (!nextAnswer) {
        showError("The new Operation's form was created in a different location");
        clearEmployeeSaltFormId();
        return;
      }

      handleAnswer(nextAnswer);
      clearEmployeeSaltFormId();
    },
    [clearEmployeeSaltFormId, handleAnswer, getSaltOpsForm, showError],
  );

  // scroll to exact task
  // save salt form
  useEffect(() => {
    if (!returnResponseId || Number(responseId) !== Number(returnResponseId) || !saltOpsFormData) {
      return;
    }

    (async () => {
      await delay(0);
      scrollToActiveResponseItem(returnResponseId);
      if (!returnSaltFormId) return;
      handleAnswerFromSalt(Number(returnSaltFormId));
    })();
  }, [
    location,
    responseId,
    returnResponseId,
    returnSaltFormId,
    scrollToActiveResponseItem,
    saltOpsFormData,
    handleAnswerFromSalt,
    clearEmployeeSaltFormId,
  ]);

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value.comment,
    };
    const answerPayload = generateTaskAnswerPayload(value.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value.comment,
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value.answer, additionalPayload);
    answer(answerPayload);
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly}
      task={value}
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
      responseId={responseId}
    >
      {!!value && (
        <SaltFormOption
          value={value?.answer}
          formType={formType}
          disabled={readOnly}
          responseId={responseId}
          listId={listId}
          listStatus={listStatus}
          isFetchingSaltOpsForm={isFetchingSaltOpsForm}
          saltOpsFormData={saltOpsFormData}
          onChange={handleAnswer}
          onRefetchSaltOpsForm={handleRefetchSaltOpsForm}
        />
      )}
    </QuestionLayout>
  );
}

export default SaltFormResponse;
