import { TaskContainer, TaskContent, TaskHeader, TaskSidebar } from 'app/components/cores/tasks';
import { useGetFacilityTask, useGetFacilityTasks } from 'app/hooks/api/facility/tasks';
import useTaskFilter from 'app/hooks/use-task-filter';
import { TUserLocation, TUserRole } from 'models';
import { TEmployeeTaskItem, TTaskFilter } from 'models/employee/task.model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import BluefitPage from '@fuse/core/BluefitPage';

function Index() {
  const [filter, setFilter] = useState<TTaskFilter>({} as TTaskFilter);
  const [selectedList, setSelectedList] = useState<TEmployeeTaskItem | null>(null);
  const [roles, setRoles] = useState<TUserRole[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const {
    data: lists,
    refetch: refetchLists,
    isLoading: isGettingLists,
  } = useGetFacilityTasks(filter, {
    enabled: !!filter[`q[location_id_eq]`],
  });

  const {
    data: list,
    refetch: refetchList,
    isFetching: isFetchingList,
  } = useGetFacilityTask(selectedList?.id, {
    onSuccess() {
      isFetchedListDetail.current = true;
    },
  });
  const { location, setTaskFilterLocation, getTaskFilterRoles } = useTaskFilter();
  const form = useForm();
  const isFetchedListDetail = useRef(false);

  // update filter when location updated
  useEffect(() => {
    if (!location?.externalId) return;
    isFetchedListDetail.current = false;
    setSelectedList(null);
    setFilter((prevFilter: TTaskFilter) => ({
      ...prevFilter,
      'q[location_id_eq]': location?.externalId,
    }));
  }, [location]);

  // filter roles
  useEffect(() => {
    const roleIds = roles?.filter((role: TUserRole) => !!role.externalId).map((role: TUserRole) => role.externalId);
    isFetchedListDetail.current = false;
    setSelectedList(null);
    setFilter((prevFilter: TTaskFilter) => ({
      ...prevFilter,
      'q[org_role_id_in]': roleIds,
    }));
  }, [roles]);

  // search
  useEffect(() => {
    isFetchedListDetail.current = false;
    setSelectedList(null);
    setFilter((prevFilter: TTaskFilter) => ({
      ...prevFilter,
      'q[task_root_name_cont]': searchValue,
    }));
  }, [searchValue]);

  const handleSelectList = useCallback((nextList: TEmployeeTaskItem) => {
    setSelectedList(nextList);
  }, []);

  const handleRefresh = () => {
    isFetchedListDetail.current = false;
    refetchLists();
    refetchList();
  };

  const handleSelectLocation = (nextLocation: TUserLocation) => {
    setTaskFilterLocation(nextLocation);
    setSelectedList(null);
    handleSelectRoles([]);
  };

  const handleSelectRoles = (selectedRoles: TUserRole[]) => {
    const nextRoles = getTaskFilterRoles(selectedRoles);
    setRoles(nextRoles as TUserRole[]);
  };

  return (
    <BluefitPage
      classes={{
        wrapper: 'bg-paper min-h-0',
        contentWrapper: 'overflow-hidden',
        content: 'h-full overflow-hidden',
      }}
      content={
        <FormProvider {...form}>
          <TaskHeader
            title="Facility Tasks"
            location={location}
            canAddOnDemand={false}
            onSelectLocation={handleSelectLocation}
          />
          <TaskContainer>
            <TaskSidebar
              lists={lists}
              selectedList={selectedList}
              isLoading={isGettingLists}
              roles={roles}
              searchValue={searchValue}
              onSelectList={handleSelectList}
              onSearch={setSearchValue}
              onSelectRoles={handleSelectRoles}
              isFacility={true}
            />
            <TaskContent
              list={list}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              isLoading={isFetchingList}
              initialLoading={isGettingLists && !selectedList}
              readOnly={true}
              onRefresh={handleRefresh}
              isFacility={true}
            />
          </TaskContainer>
        </FormProvider>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default Index;
