import { QuestionLayout, ScanCode } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import _ from 'lodash';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  listStatus?: TTaskStatus;
  responseId: number;
  response?: TEmployeeTaskResponse;
};

function QRCodeResponse({ name, readOnly, listId, listStatus, responseId, response }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();

  const scanStatus = useMemo(() => {
    if (!value?.answer?.code) {
      return '';
    }
    return value?.answer?.matched ? 'success' : 'fail';
  }, [value]);

  const codeTypeTitle = useMemo(() => {
    const type = value?.type;

    if (type === 'Responses::BarCode') return 'Scan Bar Code';
    if (type === 'Responses::QrCode') return 'Scan QR Code';
    return 'Scan Code';
  }, [value]);

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const nextAnswer = {
      ...value.answer,
      comment: value.comment,
    };
    const answerPayload = generateTaskAnswerPayload(nextAnswer);
    answer(answerPayload, {
      onSuccess() {
        showSuccess('Your answer has been submitted successfully');
      },
    });
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value.answer, additionalPayload);
    answer(answerPayload, {
      onSuccess() {
        showSuccess('Your answer has been submitted successfully');
      },
    });
  };

  const updateFormAndAnswer = (nextAnswer: any, options?: any) => {
    onChange({ ...value, answer: nextAnswer });
    const answerPayload = generateTaskAnswerPayload(nextAnswer);
    answer(answerPayload, options);
  };

  const handleAnswer = (code: string, comment: string, options?: any) => {
    updateFormAndAnswer({ code, comment }, options);
  };

  const answer = (payload: any, options?: any) => {
    answerTask(payload)
      .then(res => {
        onChange(_.merge(value, res));
        options?.onSuccess?.(res);
      })
      .catch(() => {
        showError('Could not submit your answer');
      });
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly}
      task={value}
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      actionLabel="Your Answer/Action or relevant system text content"
      name={`${name}.answer`}
      isShowComment={false}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
    >
      <ScanCode
        disabled={readOnly}
        value={value?.answer}
        title={codeTypeTitle}
        listStatus={listStatus}
        scanStatus={scanStatus}
        isLoading={isLoading}
        onComplete={handleAnswer}
      />
    </QuestionLayout>
  );
}

export default QRCodeResponse;
