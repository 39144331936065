import PerformanceContext, { TPerformanceContext } from 'app/components/performances/context';
import { useGetOverview, useGetStatistics } from 'app/hooks/api/location-performance';
import clsx from 'clsx';
import { STATISTICS_TYPES } from 'constants/performance';
import { getFormattedDateRange } from 'domains/locations/performance';
import { TDateRange } from 'models';
import moment from 'moment';
import { forwardRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { formatDate } from 'utils/date';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import StatisticTable from '../statistic-table-elements/table';
import OverviewContent from './exporting-content';

const useStyles = makeStyles(() => ({
  pagination: {
    display: 'none !important',
  },
  distribution: {
    paddingRight: '12px !important',
  },
}));

type Props = {
  locationName: string;
  period: string;
  customPeriod: TDateRange;
  locationId: number;
};

const PerformanceExportingModal = forwardRef(
  ({ period, customPeriod, locationId, locationName }: Props, printRef: any) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const { statsType } = useContext(PerformanceContext) as TPerformanceContext;
    const { from, to } = getFormattedDateRange(period, customPeriod);

    const generatePeriodString = (fromString: string, toString: string) => {
      const fromDate = formatDate(moment(fromString, 'DD/MM/YYYY').toDate(), 'DD MMM YYYY');
      if (fromString === toString) {
        return fromDate;
      }
      const toDate = formatDate(moment(toString, 'DD/MM/YYYY').toDate(), 'DD MMM YYYY');
      return `${fromDate} - ${toDate}`;
    };

    const periodString = generatePeriodString(from || '', to || '');

    const { data: overview, isLoading } = useGetOverview(Number(locationId), {
      'q[from_date]': from,
      'q[to_date]': to,
    });

    const { data: statisticsData } = useGetStatistics(
      locationId,
      statsType,
      {
        page: 1,
        per_page: 9999,
        'q[from_date]': from,
        'q[to_date]': to,
      },
      {
        enabled: !!statsType,
      },
    );
    const statistics = statisticsData?.records || [];
    const statisticType = STATISTICS_TYPES.find(type => type.value === statsType)?.label;
    const exportingTitle = `${statisticType} Statistics`;

    return (
      <div className="invisible h-0 overflow-hidden print:visible print:h-auto print:overflow-auto">
        <div
          ref={printRef as any}
          className="border-0 rounded-none custom-chart shadow-0"
        >
          <OverviewContent
            isLoading={isLoading}
            locationName={locationName}
            overview={overview}
            periodString={periodString}
          />

          <div className="flex justify-between px-24 mb-24">
            <Typography className={clsx('text-18 font-500 text-secondaryMain capitalize')}>{exportingTitle}</Typography>
          </div>

          <div className="px-12">
            <StatisticTable
              filterTask={null}
              statsType={statsType}
              data={statistics as any}
              location={location}
              history={history}
              searchText=""
              filters={[]}
              setFilters={() => null}
              onFetchData={() => null}
              classes={{
                pagination: classes.pagination,
                distribution: classes.distribution,
              }}
              tableConfig={{
                total: {
                  width: '10%',
                },
                completionRate: {
                  width: '15%',
                  hideHelpText: true,
                },
                distribution: {
                  circularProgressSize: 40,
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default PerformanceExportingModal;
